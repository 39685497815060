import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
/**
 * @param {PropTypes.InferProps<ConfirmationBox.propTypes>} props
 */
const ConfirmationBox = ({
  open,
  title,
  message,
  handleSubmit,
  handleClose,
}) => {
  const { t } = useTranslation();
  const memoizedHandleSubmit = useMemo(() => handleSubmit, [handleSubmit]);
  const memoizedHandleClose = useMemo(() => handleClose, [handleClose]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { py: 5, px: 8 } }}
    >
      {title && (
        <DialogTitle
          sx={{
            textAlign: 'center',
            fontWeight: '600',
            fontSize: 'x-large',
            pb: 8,
          }}
        >
          {title}
        </DialogTitle>
      )}
      <DialogContent sx={{ textAlign: 'center', py: 8 }}>
        {message}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
        }}
      >
        <Button
          variant="contained"
          sx={{ px: 12 }}
          onClick={memoizedHandleSubmit}
        >
          {t('yes')}
        </Button>
        <Button
          variant="contained"
          color="inherit"
          sx={{ px: 12 }}
          onClick={memoizedHandleClose}
        >
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
ConfirmationBox.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.node.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default ConfirmationBox;