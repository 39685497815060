import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Loader from '../vendor/logoOli.svg';

function LogoLoader({ loading }) {
  return (
    <>
      {loading && (
        <Backdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <img src={Loader} alt="loader" className="loader" />
        </Backdrop>
      )}
    </>
  );
}

export default LogoLoader;
