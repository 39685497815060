import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import Stack from '@mui/material/Stack';
import { useAuth } from '../../services/contexts/AuthContext';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  ThemeProvider,
  Typography,
  TableCell,
} from '@mui/material';
import { Add as AddIcon, ArrowForward } from '@mui/icons-material';
import { spacing } from '@mui/system';
import FirstCustomerCreate from './components/CreateCustomer';

import { createTheme } from '@mui/material/styles';
import shadows from '@mui/material/styles/shadows';
import DataTable from '../../components/DataTable';
import Feedback from '../../components/DataTable/Feedback';
import LogoLoader from '../../components/LogoLoader';
import { PAGE_SIZE } from '../../services/utils/units-constants';
import api from '../../Middleware/api/api';

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const dataTableTheme = (appTheme) =>
  createTheme({
    components: {
      MuiPaper: {
        variants: [
          {
            props: {
              variant: 'elevation',
            },
            style: {
              boxShadow: shadows[3],
            },
          },
        ],
      },
      MUIDataTableFilter: {
        styleOverrides: {
          root: {
            width: 300,
            boxShadow: shadows[3],
            backgroundColor: appTheme.palette.background.paper,
          },
          title: {
            lineHeight: 3,
          },
          header: {
            marginBottom: 0,
          },
          gridListTile: {
            styleOverrides: {
              paddingLeft: '25px',
              marginLeft: '25px',
            },
            sx: {
              pl: 28,
            },
          },

          reset: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            paddingRight: 4,
          },
          resetLink: {
            button: {
              variants: [
                {
                  props: {
                    variant: 'outlined',
                  },
                },
              ],
            },
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            paddingLeft: '8px',
            '&[data-colindex="5"], &[data-colindex="8"]': {
              '& > *': {
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
              },
              '& .MuiButton-root': {
                margin: 'auto',
              },
              '&[data-colindex="4"], &[data-colindex="1"], &[data-colindex="2"], &[data-colindex="3"], &[data-colindex="4", &[data-colindex="6"]':
                {
                  '& > *': {
                    display: 'flex',
                    justifyContent: 'left',
                    textAlign: 'left',
                  },
                  '& .MuiButton-root': {
                    margin: 'auto',
                    paddingLeft: '8px',
                  },
                },
              '&[data-colindex="7"]': {
                '& > *': {
                  display: 'flex',
                  justifyContent: 'right',
                  textAlign: 'right',
                },
                '& .MuiButton-root': {
                  margin: 'auto',
                },
              },
            },
          },
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: 'filled',
          margin: 'dense',
          size: 'medium',
          fullWidth: false,
          sx: {
            ml: 2,
            width: '230px',
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          margin: 'dense',
        },
      },
    },
  });
function EnhancedTable(currentDate) {
  const { t, i18n } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE[0]);
  const [fetchingData, setFetchingData] = React.useState(true);
  const [operatorContract, setOperatorContract] = React.useState([]);
  const { token } = useAuth();
  const getDashboardUsers = async () => {
    setFetchingData(true);
    api
      .getDashboardUsers(token, currentPage, pageSize)
      .then((response) => {
        if (response.data.data == null) {
          setOperatorContract([]);
          setFetchingData(false);
        } else {
          setTotalRecords(response?.data?.data?.totalNumberOfRecords);
          const rowsWithDetail = response?.data?.data?.response?.map((row) => ({
            ...row,
            details: row,
            name: row?.firstName + ' ' + row.lastName,
          }));
          setOperatorContract(rowsWithDetail);
          setFetchingData(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setOperatorContract([]);
        setFetchingData(false);
      });
  };
  useEffect(() => {
    getDashboardUsers();
  }, [currentPage, pageSize, currentDate]);

  const [showFeedback, setShowFeedback] = useState(false);
  const [showErrorFeedback, setShowErrorFeedback] = useState(false);
  const [showNoEntriesFeedback, setShowNoEntriesFeedback] = useState(false);

  const columns = useMemo(() => [
    {
      name: 'customerId',
      options: {
        display: false,
        filter: false,
        customBodyRender: (value) => {
          return value || <>-</>;
        },
      },
    },
    {
      name: 'customerId',
      label: t('LBLCustomerNum'),
      options: {
        filter: false,
        customBodyRender: (value) => {
          return <Typography align="left">{value}</Typography>;
        },
        customHeadRender: () => (
          <TableCell
            align="left"
            variant="head"
            sx={{
              textAlign: 'left',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            {t('LBLCustomerNum')}
          </TableCell>
        ),
      },
    },

    {
      name: 'companyName',
      label: 'Name',
      options: {
        filter: false,
        customBodyRender: (value) => {
          return <Typography align="left">{value}</Typography>;
        },

        setCellHeaderProps: () => {
          return { align: 'start' };
        },
        setCellProps: () => {
          return { align: 'left' };
        },
      },
    },
    {
      name: 'customerId',
      label: ' ',
      options: {
        sort: false,
        filter: false,
        customBodyRender(value) {
          return (
            <>
              <Button
                component={NavLink}
                to={`/customers/${value}/`}
                variant="text"
              >
                Details
              </Button>
            </>
          );
        },
      },
    },
  ]);

  return (
    <>
      <LogoLoader loading={fetchingData} />
      <ThemeProvider
        theme={(appTheme) => ({
          ...appTheme,
          components: {
            ...appTheme.components,
            ...dataTableTheme(appTheme).components,
          },
        })}
      >
        <Stack
          alignItems="flex-end"
          spacing={8}
          position="relative"
          width={{
            sm: '100%',
            md: 'calc(100vw - 260px - 40px)',
            lg: 'calc(100vw - 260px - 64px)',
          }}
        >
          <Paper
            variant="outlined"
            sx={{
              width: '100%',
              '& .MuiTableCell-head:not(:nth-of-type(2)) button': {
                mx: 0,
              },
            }}
          >
            <DataTable
              data={operatorContract}
              columns={columns}
              options={{
                currentPage: currentPage,
                totalRecords: totalRecords,
                textLabels: {
                  body: {
                    noMatch: fetchingData ? t('LBLLOading') : t('LBLnoUsers'),
                  },
                },
                onRowSelectionChange: (_props, allRowsSelected) => {
                  setSelectedRows(
                    // eslint-disable-next-line no-unused-vars
                    allRowsSelected.map(({ index, dataIndex }) => dataIndex)
                  );
                },
                rowsSelected: selectedRows,
                filter: false,
                search: false,
                selectableRows: 'none',
                serverSide: true,
                count: totalRecords,
                rowsPerPage: pageSize,
                rowsPerPageOptions: PAGE_SIZE,
                onTableChange: (action, tableState) => {
                  if (action === 'changePage') {
                    setCurrentPage(tableState.page);
                  } else if (action === 'changeRowsPerPage') {
                    setPageSize(tableState.rowsPerPage);
                  } else {
                    // console.log('action not handled.');
                  }
                },
              }}
              getData={(seletedPageSize, currentPageVal) => {
                setCurrentPage(currentPageVal);
                setPageSize(seletedPageSize);
              }}
            />
          </Paper>
        </Stack>
      </ThemeProvider>
      <Feedback
        open={showFeedback}
        title={t('LBLPStatusChanged')}
        message={
          <Typography variant="subtitle1" sx={{ width: 400, mx: 10 }}>
            {t('paymentUpdated')}
          </Typography>
        }
        severity="warning"
        handleClose={() => {
          setShowFeedback(false);
        }}
      />
      <Feedback
        open={showErrorFeedback}
        message={
          <Typography
            variant="subtitle1"
            fontWeight={600}
            sx={{ width: 400, mx: 10 }}
          >
            {t('LBLServerAgain')}
          </Typography>
        }
        severity="warning"
        handleClose={() => {
          setShowErrorFeedback(false);
        }}
      />
      <Feedback
        open={showNoEntriesFeedback}
        message={
          <Typography
            variant="subtitle1"
            fontWeight={400}
            sx={{ width: 400, mx: 10 }}
          >
            {t('LBLSelectOneUser')}
          </Typography>
        }
        severity="warning"
        handleClose={() => {
          setShowNoEntriesFeedback(false);
        }}
      />
    </>
  );
}

function Users() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
    setCurrentDate(new Date());
  };

  return (
    <React.Fragment>
      <Helmet title={t('custMgmt')} />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t('custMgmt')}
          </Typography>
        </Grid>
        <Grid item mb={6}>
          <div>
            <Button variant="contained" color="primary" onClick={openModal}>
              <AddIcon />
              {t('custCreate')}
            </Button>
            <FirstCustomerCreate
              paddingLeft={10}
              style={{ backgroundColor: 'red' }}
              open={open}
              handleClose={closeModal}
              setOpen={setOpen}
            />
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable currentDate={currentDate} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Users;
