import 'dayjs/locale/de';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import shadows from '@mui/material/styles/shadows';
import * as Yup from 'yup';
import React, { useMemo, useState, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { format } from 'date-fns';

/**
 * @param {PropTypes.InferProps<UserCreationFormContractEdit.propTypes>} props
 */
const UserCreationFormContractEdit = ({
  payload,
  setPayload,
  confirmPayload,
  submit,
  setSubmit,
  setCanNext,
}) => {
  const { t } = useTranslation();

  const [minEndDate, setMinEndDate] = useState('');
  useEffect(() => {
    setEndDate();
  }, []);

  useEffect(() => {
    if (submit > 0) document.getElementById('subTariff').click();
    setSubmit(0);
  }, [submit]);
  const initialValues = {
    fixTariff: payload?.contractInfo?.fixTariff,
    varTariff: payload?.contractInfo?.varTariff,
    fixTariffPerChargingRequest:
      payload?.contractInfo?.fixTariffPerChargingRequest,
    onboardingFee: payload?.contractInfo?.onboardingFee,
    newTariffDate: '',
    newTariffCancellationDate: '',
  };

  const setEndDate = () => {
    const date = new Date();
    const endDate = new Date(date.setDate(date.getDate() + 1));
    const yyyy = endDate.getFullYear();
    setMinEndDate(
      yyyy +
        endDate.toLocaleString('en-US', { month: '2-digit' }) +
        endDate.toLocaleString('en-US', { day: '2-digit' })
    );
  };

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        fixTariffPerChargingRequest: Yup.number().label(
          t('fixTariffPerChargingRequest')
        ),
        onboardingFee: Yup.number().label(t('onboardingFee')),
        fixTariff: Yup.number().required(t('usagereq')).label(t('fixTariff')),
        varTariff: Yup.number()
          .required(t('electtarrifreq'))
          .label(t('varTariff')),
        newTariffDate: Yup.date().required(t('newTariffDate')),
        newTariffCancellationDate: Yup.date()
          .required(Yup.ref('newTariffDate'), t('LBLENdgStart'))
          .label(t('LBLEndDate'))
          .required(t('LBLENdgStart')),
      }),
    []
  );
  /**
   * @method
   * @param {Props['values']} values
   * @param {Props['handleChange']} handleChange
   * @returns {(event: React.ChangeEvent<HTMLInputElement>) => void}
   */
  /** @type {import("formik").FormikConfig<typeof initialValues>['onSubmit']} */
  const handleSubmit = (values, actions) => {
    const payload = {
      fixTariff: values.fixTariff,
      varTariff: values.varTariff,
      fixTariffPerChargingRequest: values.fixTariffPerChargingRequest,
      onboardingFee: values.onboardingFee,
      newTariffDate: values.newTariffDate,
      newTariffCancellationDate: values.newTariffCancellationDate,
    };
    setPayload(payload);
    confirmPayload();
    actions.setSubmitting(false);
    actions.setTouched({}, false);
    actions.setErrors({});
    actions.setFieldError({});
    actions.setFieldTouched({}, false, false);
  };
  function disableWeekends(date) {
    let dateValue = new Date(date);
    return dateValue.getDate() != 1;
  }
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, values, setFieldValue, isValid }) => (
        <Form noValidate>
          <>
            {setCanNext(touched && isValid)}

            <Stack mb={5} direction="row" spacing={8}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="de"
              >
                <DatePicker
                  disablePast
                  label={t('newTariffDate')}
                  shouldDisableDate={disableWeekends}
                  PaperProps={{ sx: { boxShadow: shadows[3] } }}
                  value={values.newTariffDate}
                  id={'newTariffDate'}
                  onChange={(newValue) => {
                    setFieldValue(
                      'newTariffDate',
                      newValue ? format(newValue.toDate(), 'yyyy-MM-dd') : ''
                    );
                  }}
                  renderInput={(params) => (
                    <Field
                      component={TextField}
                      {...params}
                      required={true}
                      name="newTariffDate"
                      fullWidth
                      margin="none"
                      autoComplete="off"
                      helperText={touched.newTariffDate && errors.newTariffDate}
                      error={errors.newTariffDate && touched.newTariffDate}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'TT.MM.JJJJ',
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="de"
              >
                <DatePicker
                  disablePast
                  minDate={dayjs(minEndDate)}
                  PaperProps={{ sx: { boxShadow: shadows[3] } }}
                  label={t('newTariffCancellationDate')}
                  value={values.newTariffCancellationDate}
                  onChange={(newValue) => {
                    setFieldValue(
                      'newTariffCancellationDate',
                      newValue ? format(newValue.toDate(), 'yyyy-MM-dd') : null
                    );
                  }}
                  renderInput={(params) => (
                    <Field
                      component={TextField}
                      {...params}
                      name="newTariffCancellationDate"
                      margin="none"
                      fullWidth
                      autoComplete="off"
                      helperText={
                        touched.newTariffCancellationDate &&
                        errors.newTariffCancellationDate
                      }
                      error={
                        errors.newTariffCancellationDate &&
                        touched.newTariffCancellationDate
                      }
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'TT.MM.JJJJ',
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>

            <Stack mb={5} direction="row" spacing={8}>
              <Field
                required={true}
                fullWidth
                component={TextField}
                id="nutzungstarif"
                name="fixTariff"
                margin="none"
                type="number"
                label={t('fixTariff')}
                inputProps={{ min: 0, step: 0.01 }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€/Monat</InputAdornment>
                  ),
                }}
              />
              <Field
                required={true}
                fullWidth
                component={TextField}
                id="ladestromtarif"
                name="varTariff"
                type="number"
                label={t('varTariff')}
                inputProps={{ min: 0, step: 0.01 }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€/kWh</InputAdornment>
                  ),
                }}
              />
            </Stack>

            <Stack mb={5} direction="row" spacing={8}>
              <Field
                fullWidth
                component={TextField}
                disabled={payload?.contractStatus === 'ACTIVE'}
                id="onboardingFee"
                name="onboardingFee"
                margin="none"
                type="number"
                label={t('onboardingFee')}
                inputProps={{ min: 0, step: 0.01 }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
              <Field
                fullWidth
                component={TextField}
                id="fixTariffPerChargingRequest"
                name="fixTariffPerChargingRequest"
                type="number"
                label={t('fixTariffPerChargingRequest')}
                inputProps={{ min: 0, step: 0.01 }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {t('charginrequestUnit')}
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <Button
              sx={{ display: 'none' }}
              id="subTariff"
              type="submit"
            ></Button>
          </>
        </Form>
      )}
    </Formik>
  );
};

UserCreationFormContractEdit.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any),
  setPayload: PropTypes.func.isRequired,
  confirmPayload: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UserCreationFormContractEdit;