import {
  List as MuiList,
  ListItem as MuiListItem,
  Paper,
  Button,
  Skeleton,
  Stack,
  styled,
  Typography,
  Typography as MuiTypography,
  Grid,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../services/contexts/AuthContext';
import Feedback from '../../components/DataTable/Feedback';
import DetailsWrapper from '../../layouts/DetailsWrapper';
import api from '../../Middleware/api/api';
import EditOperatorInformation from './EditOperatorInformation';
import { useTranslation } from 'react-i18next';
import { ErpValuesListView } from './operatorERP/ErpValuesListView';
// eslint-disable-next-line no-unused-vars
const List = styled(MuiList)(({ theme }) => ({
  paddingLeft: 0,
}));

// eslint-disable-next-line no-unused-vars
const ListItem = styled(MuiListItem)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  paddingLeft: 0,
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const OperatorDetails = () => {
  const { t } = useTranslation();
  const [isPreview, setIsPreview] = useState(true);
  const { token } = useAuth();
  const { details } = useParams();
  const [operatorInfo, setOperatorInfo] = useState(null);
  const [showError, setShowError] = useState(false);
  const [refreshTheData, setRefreshTheData] = useState(true);

  useEffect(() => {
    if (token && refreshTheData) {
      try {
        api.fetchOperatorDetails(token, details).then(
          (response) => {
            if (response.data.data == null) {
              setShowError(true);
            } else {
              setOperatorInfo(response.data.data);
            }
            setRefreshTheData(false);
          },
          () => {
            setShowError(true);
            setRefreshTheData(false);
          }
        );
      } catch (exception) {
        setOperatorInfo(null);
        setRefreshTheData(false);
      }
    }
  }, [token, refreshTheData]);

  const loadingOperator = refreshTheData;
  function closeErrorFeedback() {
    setShowError(false);
  }

  const enablePreveiw = () => {
    setIsPreview(false);
  };
  const disablePreveiw = () => {
    setIsPreview(true);
  };
  return (
    <>
      <DetailsWrapper
        loading={loadingOperator}
        backButtonConfig={{
          label: t('backToOpV'),
          url: '/betreiber',
        }}
        title={t('OpMgMT')}
        helmet="Betreiberverwaltung"
        contract={
          <EditOperatorInformation
            operatorInfo={operatorInfo}
            setRefreshTheData={setRefreshTheData}
          />
        }
      >
        <Stack direction="row">
          <Paper sx={{ px: 2, py: 4, mb: 12, width: '100%' }}>
            <Stack direction="row" sx={{ width: '100%' }}>
              <Box flexGrow={1}>
                <MuiTypography variant="h3" component="h2" gutterBottom>
                  {t('LBLOpInfo')}
                </MuiTypography>
                <List>
                  <ListItem>
                    <Typography>{t('LBLOpId')}</Typography>
                    <Typography>
                      {loadingOperator ? (
                        <Skeleton width={35} />
                      ) : (
                        operatorInfo?.cpoId
                      )}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Name </Typography>
                    <Typography>
                      {loadingOperator ? (
                        <Skeleton width={150} />
                      ) : (
                        operatorInfo?.cpoName
                      )}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>{t('LBLAddress')}</Typography>
                    <Typography align="right">
                      {loadingOperator ? (
                        <>
                          <Skeleton width={130} />
                          <Skeleton width={130} />
                        </>
                      ) : (
                        <>
                          <Typography sx={{ textAlign: 'right' }}>
                            {operatorInfo?.cpoAddress?.careOf
                              ? 'c/o ' + operatorInfo?.cpoAddress?.careOf
                              : ''}
                            {operatorInfo?.cpoAddress?.careOf ? <br /> : ''}
                            {operatorInfo?.cpoAddress?.street} <br />
                            {operatorInfo?.cpoAddress?.zipCode}{' '}
                            {operatorInfo?.cpoAddress?.city}
                          </Typography>
                        </>
                      )}
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </Stack>
          </Paper>
        </Stack>
        <div style={{ paddingBottom: '15px' }}>
          <Button
            variant="contained"
            color={isPreview ? 'primary' : 'inherit'}
            onClick={disablePreveiw}
          >
            {t('invoiceTemp')}
          </Button>
          <Button
            variant="contained"
            color={isPreview ? 'inherit' : 'primary'}
            onClick={enablePreveiw}
          >
            {t('erpImportData')}
          </Button>
        </div>
        {isPreview ? (
          <Stack direction="row">
            <Paper sx={{ px: 2, py: 4, mb: 12, width: '100%' }}>
              <Stack direction="row" sx={{ width: '100%', textAlign: 'right' }}>
                <Box flexGrow={1}>
                  <List>
                    {operatorInfo?.cpoPaymentDetails && (
                      <>
                        <ListItem>
                          <Typography>{t('Headers')}</Typography>
                          <Typography>
                            {loadingOperator ? (
                              <Skeleton width={35} />
                            ) : (
                              <div style={{ textAlign: 'right' }}>
                                {operatorInfo?.billingTemplateHeader?.lineOne !=
                                  '' &&
                                operatorInfo?.billingTemplateHeader?.lineOne !=
                                  null
                                  ? '1. ' +
                                    operatorInfo?.billingTemplateHeader?.lineOne
                                  : ''}
                                {operatorInfo?.billingTemplateHeader?.lineOne !=
                                  '' &&
                                operatorInfo?.billingTemplateHeader?.lineOne !=
                                  null ? (
                                  <br />
                                ) : (
                                  ''
                                )}
                                {operatorInfo?.billingTemplateHeader?.lineTwo !=
                                  '' &&
                                operatorInfo?.billingTemplateHeader?.lineTwo !=
                                  null
                                  ? '2. ' +
                                    operatorInfo?.billingTemplateHeader?.lineTwo
                                  : ''}
                                {operatorInfo?.billingTemplateHeader?.lineTwo !=
                                  '' &&
                                operatorInfo?.billingTemplateHeader?.lineTwo !=
                                  null ? (
                                  <br />
                                ) : (
                                  ''
                                )}
                                {operatorInfo?.billingTemplateHeader
                                  ?.lineThree != '' &&
                                operatorInfo?.billingTemplateHeader
                                  ?.lineThree != null
                                  ? '3. ' +
                                    operatorInfo?.billingTemplateHeader
                                      ?.lineThree
                                  : ''}
                                {operatorInfo?.billingTemplateHeader
                                  ?.lineThree != '' &&
                                operatorInfo?.billingTemplateHeader
                                  ?.lineThree != null ? (
                                  <br />
                                ) : (
                                  ''
                                )}
                                {operatorInfo?.billingTemplateHeader
                                  ?.lineFour != '' &&
                                operatorInfo?.billingTemplateHeader?.lineFour !=
                                  null
                                  ? '4. ' +
                                    operatorInfo?.billingTemplateHeader
                                      ?.lineFour
                                  : ''}
                                {operatorInfo?.billingTemplateHeader
                                  ?.lineFour != '' &&
                                operatorInfo?.billingTemplateHeader?.lineFour !=
                                  null ? (
                                  <br />
                                ) : (
                                  ''
                                )}
                              </div>
                            )}
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography>{t('footers')}</Typography>
                          <Typography>
                            {loadingOperator ? (
                              <Skeleton width={35} />
                            ) : (
                              <div style={{ textAlign: 'right' }}>
                                {operatorInfo?.billingTemplateFooter?.lineOne !=
                                  '' &&
                                operatorInfo?.billingTemplateFooter?.lineOne !=
                                  null
                                  ? '1. ' +
                                    operatorInfo?.billingTemplateFooter?.lineOne
                                  : ''}
                                {operatorInfo?.billingTemplateFooter?.lineOne !=
                                  '' &&
                                operatorInfo?.billingTemplateFooter?.lineOne !=
                                  null ? (
                                  <br />
                                ) : (
                                  ''
                                )}
                                {operatorInfo?.billingTemplateFooter?.lineTwo !=
                                  '' &&
                                operatorInfo?.billingTemplateFooter?.lineTwo !=
                                  null
                                  ? '2. ' +
                                    operatorInfo?.billingTemplateFooter?.lineTwo
                                  : ''}
                                {operatorInfo?.billingTemplateFooter?.lineTwo !=
                                  '' &&
                                operatorInfo?.billingTemplateFooter?.lineTwo !=
                                  null ? (
                                  <br />
                                ) : (
                                  ''
                                )}
                                {operatorInfo?.billingTemplateFooter
                                  ?.lineThree != '' &&
                                operatorInfo?.billingTemplateFooter
                                  ?.lineThree != null
                                  ? '3. ' +
                                    operatorInfo?.billingTemplateFooter
                                      ?.lineThree
                                  : ''}
                                {operatorInfo?.billingTemplateFooter
                                  ?.lineThree != '' &&
                                operatorInfo?.billingTemplateFooter
                                  ?.lineThree != null ? (
                                  <br />
                                ) : (
                                  ''
                                )}
                                {operatorInfo?.billingTemplateFooter
                                  ?.lineFour != '' &&
                                operatorInfo?.billingTemplateFooter?.lineFour !=
                                  null
                                  ? '4. ' +
                                    operatorInfo?.billingTemplateFooter
                                      ?.lineFour
                                  : ''}
                                {operatorInfo?.billingTemplateFooter
                                  ?.lineFour != '' &&
                                operatorInfo?.billingTemplateFooter?.lineFour !=
                                  null ? (
                                  <br />
                                ) : (
                                  ''
                                )}
                              </div>
                            )}
                          </Typography>
                        </ListItem>
                      </>
                    )}

                    <ListItem>
                      <Typography>{t('cpinfoP')}</Typography>
                      <Typography>
                        {loadingOperator ? (
                          <Skeleton width={35} />
                        ) : (
                          <div style={{ textAlign: 'right' }}>
                            {operatorInfo?.contactPersonDetails
                              ?.contactPersonName != '' ? (
                              <div>
                                {' '}
                                <b>Name: </b>
                                {
                                  operatorInfo?.contactPersonDetails
                                    ?.contactPersonName
                                }
                              </div>
                            ) : (
                              ''
                            )}
                            {operatorInfo?.contactPersonDetails
                              ?.contactPersonName != '' ? (
                              <div>
                                {' '}
                                <b>{t('job')}: </b>
                                {
                                  operatorInfo?.contactPersonDetails
                                    ?.contactPersonPosition
                                }
                              </div>
                            ) : (
                              ''
                            )}
                            {operatorInfo?.contactPersonDetails
                              ?.contactPersonEmail != '' ? (
                              <div>
                                {' '}
                                <b>E-mail: </b>
                                {
                                  operatorInfo?.contactPersonDetails
                                    ?.contactPersonEmail
                                }
                              </div>
                            ) : (
                              ''
                            )}
                            {operatorInfo?.contactPersonDetails
                              ?.contactPersonPhone != '' ? (
                              <div>
                                {' '}
                                <b>{t('PhoneNum')}: </b>
                                {
                                  operatorInfo?.contactPersonDetails
                                    ?.contactPersonPhone
                                }
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        )}
                      </Typography>
                    </ListItem>
                    {operatorInfo?.cpoPaymentDetails && (
                      <>
                        <ListItem>
                          <Typography>{t('paymentReceInfo')}</Typography>
                          <Typography>
                            {loadingOperator ? (
                              <Skeleton width={35} />
                            ) : (
                              <div style={{ textAlign: 'right' }}>
                                {operatorInfo?.cpoPaymentDetails
                                  ?.cpoPaymentPreference != '' ? (
                                  <div>
                                    {' '}
                                    <b>{t('zahlungseingangmethode')}: </b>
                                    {operatorInfo?.cpoPaymentDetails?.cpoPaymentPreference.includes(
                                      'SEPA'
                                    ) ? (
                                      <div> {t('sepamode')},</div>
                                    ) : null}{' '}
                                    {operatorInfo?.cpoPaymentDetails?.cpoPaymentPreference.includes(
                                      'BANK_TRANSFER'
                                    ) ? (
                                      <div> {t('LBLRechnungen')},</div>
                                    ) : null}{' '}
                                    {operatorInfo?.cpoPaymentDetails?.cpoPaymentPreference.includes(
                                      'STRIPE_SEPA'
                                    ) ? (
                                      <div> {t('sepaStripemode')},</div>
                                    ) : null}
                                  </div>
                                ) : (
                                  ''
                                )}
                                {operatorInfo?.cpoPaymentDetails?.creditorId !=
                                  '' &&
                                operatorInfo?.cpoPaymentDetails?.cpoPaymentPreference.includes(
                                  'SEPA'
                                ) ? (
                                  <div>
                                    {' '}
                                    <b>{t('creditorId')}: </b>
                                    {
                                      operatorInfo?.cpoPaymentDetails
                                        ?.creditorId
                                    }
                                  </div>
                                ) : (
                                  ''
                                )}
                                {operatorInfo?.cpoPaymentDetails
                                  ?.moneyCollectionGapInDays != '' ? (
                                  <div>
                                    {' '}
                                    <b>{t('noOfDaysUntillPayment')} : </b>
                                    {
                                      operatorInfo?.cpoPaymentDetails
                                        ?.moneyCollectionGapInDays
                                    }{' '}
                                    {t('tages')}
                                  </div>
                                ) : (
                                  ''
                                )}
                                {operatorInfo?.cpoPaymentDetails?.cpoIban !=
                                  '' &&
                                operatorInfo?.cpoPaymentDetails?.cpoPaymentPreference.includes(
                                  'BANK_TRANSFER'
                                ) ? (
                                  <div>
                                    {' '}
                                    <b>{t('iban')}: </b>
                                    {
                                      operatorInfo?.cpoPaymentDetails?.cpoIban
                                    }{' '}
                                  </div>
                                ) : (
                                  ''
                                )}
                                {operatorInfo?.cpoPaymentDetails?.cpoBic !=
                                  '' &&
                                operatorInfo?.cpoPaymentDetails?.cpoPaymentPreference.includes(
                                  'BANK_TRANSFER'
                                ) ? (
                                  <div>
                                    {' '}
                                    <b>{t('bic')}: </b>
                                    {
                                      operatorInfo?.cpoPaymentDetails?.cpoBic
                                    }{' '}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            )}
                          </Typography>
                        </ListItem>

                        <ListItem>
                          <Typography>{t('proofIndiv')}</Typography>
                          <Typography>
                            {loadingOperator ? (
                              <Skeleton width={35} />
                            ) : (
                              <div style={{ textAlign: 'right' }}>
                                {operatorInfo?.isChargingHistoryIncluded !=
                                undefined ? (
                                  <div>
                                    {operatorInfo?.isChargingHistoryIncluded ==
                                    true
                                      ? t('yes')
                                      : t('no')}
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            )}
                          </Typography>
                        </ListItem>
                      </>
                    )}
                  </List>
                </Box>
              </Stack>
            </Paper>
          </Stack>
        ) : (
          <Grid container spacing={6}>
            <Grid item xs={12}>
              {operatorInfo?.erpValues?.length > 0 ? (
                <ErpValuesListView
                  operatorInfo={operatorInfo}
                  setRefreshTheData={setRefreshTheData}
                />
              ) : (
                <Paper sx={{ px: 2, py: 4, mb: 12, width: '100%' }}>
                  <Stack
                    direction="row"
                    sx={{ width: '100%', textAlign: 'center' }}
                  >
                    <Box flexGrow={1}>
                      <Typography>{t('LBLNoData')} </Typography>
                    </Box>
                  </Stack>
                </Paper>
              )}
            </Grid>
          </Grid>
        )}

        <Suspense fallback={<div />}>
          <Feedback
            open={showError}
            message={
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: 400, mx: 10 }}
              >
                {t('LBLServerAgain')}
              </Typography>
            }
            severity="warning"
            handleClose={closeErrorFeedback}
          />
        </Suspense>
      </DetailsWrapper>
    </>
  );
};

export default OperatorDetails;