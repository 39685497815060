import React from 'react';
import { Item, Spacer, View } from './styles';
import { CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
  formatCurrency,
  formatDecimal,
} from '../../services/utils/format-numbers';
export default function CurrentStatusComponent({ chargingStation }) {
  const { t } = useTranslation();

  return (
    <View>
      <CardContent>
        <Typography fontWeight="bold" variant="h4" gutterBottom>
          {t('LBLCurrentStatus')}
        </Typography>
        <Spacer mb={4} />
        <Typography variant="body2" component="div" gutterBottom>
          <Item key={0}>
            <Typography fontWeight="fontWeightMedium">
              {t('LBLCHarginStationStatus')}
            </Typography>
            <Typography fontWeight="fontWeightRegular" align="right">
              <Typography
                align="center"
                color={chargingStation?.connectorStatusMapping?.colourCode}
              >
                {t(chargingStation?.connectorStatusMapping?.enumName)}
              </Typography>
            </Typography>
          </Item>
          <Item key={1}>
            <Typography fontWeight="fontWeightMedium">
              {t('LBLLastheartbeat')}
            </Typography>
            <Typography fontWeight="fontWeightRegular" align="right">
              {chargingStation?.lastHeartBeatInterval
                ? format(
                    new Date(chargingStation?.lastHeartBeatInterval),
                    'dd.MM.yyyy, HH:mm'
                  ) + ' Uhr'
                : ''}
            </Typography>
          </Item>
        </Typography>
      </CardContent>
      <CardContent>
        <Typography fontWeight="bold" variant="h4" gutterBottom>
          KPIs
        </Typography>
        <Spacer mb={4} />
        <Typography variant="body2" component="div" gutterBottom>
          <Item key={0}>
            <Typography fontWeight="fontWeightMedium">
              {t('LBLTotalAmountOfEnergyCharged')}
            </Typography>
            <Typography fontWeight="fontWeightRegular" align="right">
              {formatDecimal(chargingStation?.totalEnergy) + ' kWh'}
            </Typography>
          </Item>
          <Item key={1}>
            <Typography fontWeight="fontWeightMedium">
              {t('LBLTotalSales')}
            </Typography>
            <Typography fontWeight="fontWeightRegular" align="right">
              {formatCurrency(chargingStation?.totalRevenue || 0)}
            </Typography>
          </Item>
        </Typography>
      </CardContent>
    </View>
  );
}