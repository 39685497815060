import React from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
const ConfigureStripeSepaSuccessUrl = () => {
  const { t } = useTranslation();
  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={t('ConfigureStripeSepaSuccessUrlTitleTemplate')}
        defaultTitle={t('ConfigureStripeSepaSuccessUrlDefaultTitle')}
      />
      <h1>{t('ConfigureStripeSepaSuccessUrlMessage')}</h1>
    </HelmetProvider>
  );
};
export default ConfigureStripeSepaSuccessUrl;
