import React from 'react';
import { forwardRef } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

/**
 * @param {import("@mui/lab").LoadingButtonProps} props
 * @param {HTMLButtonElement} ref
 */
function ButtonRef(
  { disabled, loading, loadingPosition, endIcon, startIcon, ...props },
  ref
) {
  return (
    <Button
      ref={ref}
      {...props}
      disabled={disabled}
      disableElevation={loading}
      endIcon={
        loading && loadingPosition === 'end' ? (
          <CircularProgress size={20} sx={{ color: 'action.disabled' }} />
        ) : (
          endIcon
        )
      }
      startIcon={
        loading && loadingPosition === 'start' ? (
          <CircularProgress size={20} sx={{ color: 'action.disabled' }} />
        ) : (
          startIcon
        )
      }
    >
      {loading && !loadingPosition && (
        <CircularProgress
          size={24}
          sx={{
            top: '50%',
            left: '50%',
            position: 'absolute',
            color: 'action.disabled',
            margin: '-12px 0 0 -12px',
          }}
        />
      )}
      <span
        style={{
          visibility: loading && !loadingPosition ? 'hidden' : 'visible',
        }}
      >
        {props.children}
      </span>
    </Button>
  );
}

const LoadingButton = forwardRef(ButtonRef);

export default LoadingButton;
