import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Typography,
  Stack,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import api from '../../../Middleware/api/api';
import { useAuth } from '../../../services/contexts';

import LogoLoader from '../../../components/LogoLoader';
import { styled } from '@mui/material/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
/**
 * @param {PropTypes.InferProps<CleanChargingRequestDialog.propTypes>} props
 */
const CleanChargingRequestDialog = ({ open, title, message, handleClose }) => {
  const [minutesThreshold, setMinutesThreshold] = useState(0);
  const [loading, setLoading] = useState(false);
  const { typography } = useTheme();
  const { t } = useTranslation();
  const { token } = useAuth();
  const [res, setRes] = useState(false);
  const [apiFeedbackTitle, setApiFeedbackTitle] = useState('');
  const [apiFeedbackDes, setApiFeedbackDes] = useState('');

  const handleSave = async () => {
    setLoading(true);
    setRes(true);

    try {
      const response = await api.cleanChargingRequests(token, minutesThreshold);

      if (response.status == 200) {
        setApiFeedbackTitle(t('LBLApiFeedbackSuccessful'));
      } else {
        setApiFeedbackTitle(t('LBLSomethingwentWrong'));
        setApiFeedbackDes(t('LBLApiFeedbackError'));
      }
    } catch (err) {
      setApiFeedbackTitle(t('LBLSomethingwentWrong'));
      setApiFeedbackDes(t('LBLApiFeedbackError'));
    }

    setLoading(false);
  };

  const handleCleanChargingDialogClose = () => {
    setApiFeedbackTitle('')
    setApiFeedbackDes('')
    setMinutesThreshold(0)
    setRes(false)
    handleClose()
  }

  return (
    <BootstrapDialog open={open} onClose={handleCleanChargingDialogClose}>
      <LogoLoader loading={loading} />
      <DialogTitle
        align="center"
        fontWeight={700}
        fontSize={typography.h5.fontSize}
        mt={8}
      >
        {res ? apiFeedbackTitle : title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent align="center" sx={{ maxwidth: 400, mx: 3, mt: 3 }}>
        {res ? apiFeedbackDes : message}
        {!res && (
          <Stack
            my={5}
            mt={5}
            direction="row"
            alignItems="center"
            spacing={8}
            justifyContent="center"
          >
            <Typography>{t('LBLMinsThreshold')}</Typography>
            <TextField
              margin="dense"
              id="minutesThreshold"
              label="minutesThreshold"
              variant="outlined"
              type="number"
              required
              name={'minutesThreshold'}
              my={2}
              value={minutesThreshold}
              onChange={(eve) => {
                setMinutesThreshold(eve.target.value);
              }}
              inputProps={{ min: 0 }}
            />
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ mb: 8, justifyContent: 'center' }}>
        <DialogActions>
          {res ? (
            <Button
            variant="contained"
            color="inherit"
              sx={{ px: 12 }}
              onClick={handleCleanChargingDialogClose}
            >
              {t('close')}
            </Button>
          ) : (
            <Button
              variant="contained"
              disabled={!(minutesThreshold > 0)}
              sx={{ px: 12 }}
              onClick={handleSave}
            >
              {loading ? `${t('BTNCleanCRS')}...` : t('BTNCleanCRS')}
            </Button>
          )}
        </DialogActions>
      </DialogActions>
    </BootstrapDialog>
  );
};

CleanChargingRequestDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default CleanChargingRequestDialog;
