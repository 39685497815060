import React, { lazy, Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import InnerUserDetails from './InnerUserDetails';
import shadows from '@mui/material/styles/shadows';
import InnerUserConfirm from './InnerUserConfirm';
import StepperPopup from '../../../../../../components/StepperPopup';
import { ArrowForward, Add as AddIcon } from '@mui/icons-material';
import LogoLoader from '../../../../../../components/LogoLoader';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

const Feedback = lazy(() => import('../../../../../../components/Feedback'));
const FailFeedback = lazy(
  () => import('../../../../../../components/DataTable/Feedback')
);
/**
 * @param {PropTypes.InferProps<SecondCustomerCreate.propTypes>} props
 */
const SecondCustomerCreate = ({ customerId, contract, handleClose }) => {
  const { t } = useTranslation();
  const [payload, setPayload] = useState(null);
  const [payloadOne, setPayloadOne] = useState(null);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [submit, setSubmit] = useState(0);
  const [canNext, setCanNext] = useState(false);
  const [confirmed, setConfirmed] = useState(0);

  const [error, setError] = useState(false);
  const [custError, setecustError] = useState(false);

  const handleNext = () => setStep(step + 1);
  const handlePrev = () => setStep(step - 1);
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const openFeedback = () => {
    setPayload(null);
    setCompleted(true);
  };
  const closeFeedback = () => {
    setPayload(null);
    setCompleted(false);
    handleClose();
  };
  /** @type {import("@mui/material").DialogProps['onClose']} */
  const onClose = (_event, reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
      setPayload(null);
      setStep(0);
    }
  };

  /** @type {import("@mui/material").DialogProps['onCloseOne']} */
  const onCloseOne = (_event, reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
      setPayloadOne(null);
      setStep(1);
    }
  };

  const handleSetPayload = (input) => {
    setPayload(input);
  };

  useEffect(() => {
    setLoading(false);
  }, [completed, payload, payloadOne, error]);
  return (
    <>
      <LogoLoader loading={loading} />

      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={openModal}
        variant="contained"
        sx={{
          position: 'absolute',
          boxShadow: shadows[3],
          top: ({ spacing }) => spacing(10),
          right: ({ spacing }) => spacing(10),
          zIndex: ({ zIndex }) => zIndex.appBar,
          display: 'flex',
          justifyContent: 'center',
          // width: "146.8px"
        }}
      >
        <AddIcon />
        {t('addexUser')}
      </Button>
      <StepperPopup
        widthInput="1000"
        title={t('addexUser')}
        isOpen={open}
        setIsOpen={setOpen}
        steps={[
          {
            label: t('userInfo'),
            component: (
              <InnerUserDetails
                setPayload={handleSetPayload}
                handleClose={onClose}
                confirmPayload={handleNext}
                payload={payload}
                setSubmit={setSubmit}
                setCanNext={setCanNext}
                submit={submit}
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmit((prevState) => prevState + 1);
              },
              canNext: canNext,
            },
          },

          {
            label: t('confirmInfo'),
            component: (
              <InnerUserConfirm
                payload={{
                  firstName: payload?.firstName,
                  lastName: payload?.lastName,
                  email: payload?.email,
                  customerId: customerId,
                  role: payload?.role,
                  customerType: contract?.customer_type[0],
                }}
                handleClose={onClose}
                openFeedback={openFeedback}
                backToForm={handlePrev}
                setError={setError}
                setecustError={setecustError}
                submit={confirmed}
                setSubmit={setConfirmed}
                editFlag={false}
                contract={null}
              />
            ),
            nextAction: {
              label: t('LBLSave'),
              action: () => {
                setConfirmed((prevState) => prevState + 1);
                setOpen(false);
                setLoading(true);
              },
            },
          },
        ]}
      />
      <Suspense fallback={<div />}>
        <Feedback
          open={completed}
          title={t('newccreated')}
          message={
            <>
              <Typography align="center">{t('thankforInput')}</Typography>

              <Typography align="center">
                <>{t('succMsgExistingCust')}</>
              </Typography>
            </>
          }
          handleClose={closeFeedback}
        />
        <FailFeedback
          open={error}
          title={t('mistake')}
          severity="warning"
          message={
            <>
              <Typography align="center">
                {t('LBLSomethingwentWrong')}
              </Typography>
            </>
          }
          handleClose={() => {
            setError(false);
            setecustError(false);
            setPayload(null);
            setPayloadOne(null);
          }}
        />
        <FailFeedback
          open={custError}
          title={t('mistake')}
          severity="warning"
          message={
            <>
              <Typography align="center">{t('custInuse')}</Typography>
            </>
          }
          handleClose={() => {
            setError(false);
            setecustError(false);
            setPayload(null);
            setPayloadOne(null);
          }}
        />
      </Suspense>
    </>
  );
};
SecondCustomerCreate.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SecondCustomerCreate;
