import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import api from '../../Middleware/api/api';
import { useAuth } from '../../services/contexts';
import jwtDecode from 'jwt-decode';
import {
  WarningRounded,
  Add as AddIcon,
  ArrowForward,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
  Divider as MuiDivider,
  Grid,
  Button,
  TableCell,
  Typography,
} from '@mui/material';
import { spacing } from '@mui/system';

import OperatorForm from './components/OperatorForm';
import OperatorErstellung from './components/OperatorErstellung';

import StepperPopup from '../../components/StepperPopup';
import OperatorConfirmation from './components/OperatorConfirmation';
import Feedback from '../../components/Feedback';
import LocationForm from './components/LocationForm';
import LocationFormMultiple from './components/LocationFormMultiple';
import LocationConfirmation from './components/LocationConfirmation';
import LogoLoader from '../../components/LogoLoader';
import {
  formatCurrency,
  formatDecimal,
} from '../../services/utils/format-numbers';
import DataTable from '../../components/DataTable';
import { PAGE_SIZE, MIN_PAGE_SIZE } from '../../services/utils/units-constants';

const Divider = styled(MuiDivider)(spacing);

let rows = [];
let locations = [];
function EnhancedTable({ apiCallCount, handleReset, token }) {
  const { t, i18n } = useTranslation();
  const [locationData, setLocationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE[0]);
  const [totalRecords, setTotalRecords] = useState(0);
  const decodedToken = token ? jwtDecode(token) : null;
  const [isAdminRole, setIsAdminRole] = useState(
    decodedToken?.resource_access?.admin_oli_move?.roles.some(
      (role) => role === 'oli_move_admin'
    )
  );
  useEffect(() => {
    if (apiCallCount > 0) {
      getLocationData(pageSize, currentPage, apiCallCount);
    }
  }, [apiCallCount]);

  const getLocationData = (seletedPageSize, currentPageVal, apiCallCount) => {
    try {
      api.fetchLocations(token, currentPageVal, seletedPageSize).then(
        (response) => {
          if (response.data.data == null) {
            rows = [];
            setLocationData([]);
          } else {
            handleReset(0);
            setTotalRecords(response?.data?.data?.totalNumberOfRecords);
            rows = response?.data?.data?.response;
            setLocationData(response.data?.data?.response);
          }
        },
        () => {
          rows = [];
          setLocationData(rows);
        }
      );
    } catch (exception) {
      rows = [];
      setLocationData(rows);
    }
  };

  locations = locationData.map((location) => ({
    ...location,
    details: location.locationId,
    totalEnergy: location?.locationMetricsDTO?.totalEnergy,
    totalRevenue: location?.locationMetricsDTO?.totalRevenue,
  }));
  const columns = useMemo(() => [
    {
      name: 'locationId',
      label: 'Standort-ID',
      options: {
        filter: false,
        customBodyRender: (value) => {
          return value;
        },
        customHeadRender: () => (
          <TableCell
            align="center"
            variant="head"
            sx={{
              textAlign: 'left',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            {t('LBLLocationId')}
          </TableCell>
        ),
      },
    },
    {
      name: 'locationName',
      label: 'Standort',
      options: {
        filter: true,
        display: 'true',
        filterOptions: {
          fullWidth: true,
        },
        customBodyRender: (value) => {
          return value;
        },
        customHeadRender: () => (
          <TableCell
            align="center"
            variant="head"
            sx={{
              textAlign: 'left',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            {t('LBLLocation')}
          </TableCell>
        ),
      },
    },
    {
      name: 'locationAddress',
      label: 'Adresse',
      options: {
        filter: false,
        customBodyRender: (address) => {
          if (address?.careOf) {
            return (
              <Typography paragraph>
                c/o {address?.careOf} <br />
                {address?.street} <br />
                {address?.zipCode} {address?.city}
              </Typography>
            );
          }
          return (
            <Typography paragraph>
              {address?.street} <br />
              {address?.zipCode} {address?.city}
            </Typography>
          );
        },
        customHeadRender: () => (
          <TableCell
            align="center"
            variant="head"
            sx={{
              textAlign: 'left',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            {t('LBLAddress')}
          </TableCell>
        ),
      },
    },
    {
      name: 'numOfChargingStations',
      label: 'Zahl der Ladestationen',
      options: {
        filter: false,
        display: 'true',
        filterOptions: {
          fullWidth: true,
        },
        customHeadRender: () => (
          <TableCell
            align="right"
            variant="head"
            sx={{
              textAlign: 'right',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            {t('LBLNumberOfCharginStations')}
          </TableCell>
        ),
        customBodyRender: (value) => {
          if (!value) return null;
          if (isAdminRole)
            return (
              <Typography align="right">
                {value.totalNrChargingStation}
              </Typography>
            );
          return (
            <Typography align="right">
              {value.totalNrChargingStation - value.removedNrChargingStation}
            </Typography>
          );
        },
      },
    },
    {
      name: 'numEndCustomers',
      label: 'Zahl der Nutzer',
      options: {
        filter: false,
        customBodyRender: (value) => {
          return <Typography align="right">{value} </Typography>;
        },
        customHeadRender: () => (
          <TableCell
            key={4}
            align="right"
            color="black"
            variant="head"
            sx={{
              textAlign: 'right',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            {t('LBLNumUsers')}
          </TableCell>
        ),
      },
    },
    {
      name: 'totalEnergy',
      label: 'Geladene Energiemenge',
      options: {
        filter: false,
        headerAlign: 'right',
        customBodyRender: (value) => {
          let valueData = formatDecimal(value);
          if (i18n.language === 'en') {
            valueData = valueData.replace(/,/g, '.');
          }
          return (
            <Typography align="right" paddingRight={2}>
              {value !== null ? valueData + ' kWh' : ''}
            </Typography>
          );
        },
        customHeadRender: () => (
          <TableCell
            key={5}
            align="right"
            variant="head"
            sx={{
              textAlign: 'right',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            {t('LBLAmountCharged')}
          </TableCell>
        ),
      },
    },
    {
      name: 'totalRevenue',
      label: 'Umsatz',
      options: {
        filter: false,
        customBodyRender: (value) => {
          let valueData = formatCurrency(value);
          if (i18n.language === 'en') {
            valueData = valueData.replace(/,/g, '.');
          }
          return (
            <Typography align="right">
              {' '}
              {value !== null ? valueData : ''}{' '}
            </Typography>
          );
        },
        customHeadRender: () => (
          <TableCell
            key={6}
            align="right"
            variant="head"
            sx={{
              textAlign: 'right',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            {t('LBLSales')}
          </TableCell>
        ),
      },
    },
    {
      name: 'details',
      label: '',
      options: {
        sort: false,
        filter: false,
        customHeadRender: () => (
          <TableCell
            key={7}
            align="right"
            variant="head"
            sx={{
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
              zIndex: 1,
            }}
          >
            <span></span>
          </TableCell>
        ),
        customBodyRender: (value) => {
          return (
            <Button
              component={NavLink}
              to={`/standorte/${value}/`}
              variant="text"
            >
              Details
            </Button>
          );
        },
      },
    },
  ]);
  return (
    <>
      <DataTable
        columns={columns}
        data={locations}
        options={{
          filter: false,
          textLabels: {
            body: {
              noMatch: t('LBLNoLocations'),
            },
          },
          currentPage: currentPage,
          totalRecords: totalRecords,
        }}
        getData={(seletedPageSize, currentPageVal) => {
          handleReset(1);
          setCurrentPage(currentPageVal);
          setPageSize(seletedPageSize);
        }}
      ></DataTable>
    </>
  );
}
function Locations() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitOperator, setSubmitOperator] = useState(0);
  const [submitOperatorErst, setSubmitOperatorErst] = useState(0);
  const [confirmOperator, setConfirmOperator] = useState(false);
  const [operatorResponse, setOperatorResponse] = useState(null);
  const [operatorRequestError, setOperatorRequestError] = useState(null);
  const [feedBackProps, setFeedBackProps] = useState({});
  // declaring states for the betreiber anlegen form
  const [operatorPayload, setOperatorPayload] = useState({});
  const [operatorPayloadErst, setOperatorPayloadErst] = useState({});
  const [canNextOperator, setCanNextOperator] = useState(false);
  const [canNextOperatorErst, setCanNextOperatorErst] = useState(false);
  const [locationPayload, setLocationPayload] = useState({});
  const [locationPayloadMultiple, setLocationPayloadMultiple] = useState({});
  //states for the response
  const [locationError, setLocationError] = useState(null);
  const [confirmLocation, setConfirmLocation] = useState(false);
  const [locationResponse, setLocationResponse] = useState(null);
  // manage betreiber anlegen button
  const [showBetreiberButton, setShowBetreiberButton] = useState(true);
  //keycloak configs
  const { token } = useAuth();
  const decodedToken = token ? jwtDecode(token) : null;
  const [isAdminRole, setIsAdminRole] = useState(
    decodedToken?.resource_access?.admin_oli_move?.roles.some(
      (role) => role === 'oli_move_admin'
    )
  );
  useEffect(() => {
    let inputToken = localStorage.getItem('accessToken');
    const payload = jwtDecode(inputToken);
    if (payload?.cpo_id?.length > 0 && payload?.customer_type === 'Operator') {
      setShowBetreiberButton(false);
    }
  }, [showBetreiberButton]);
  //standort states
  const [openLocationForm, setOpenLocationForm] = useState(false);
  const [submitLocation, setSubmitLocation] = useState(false);
  const [submitLocationMultiple, setSubmitLocationMultiple] = useState(false);
  const [canNextLocation, setCanNextLocation] = useState(false);
  const [canNextLocationMultiple, setCanNextLocationMultiple] = useState(false);

  const [successfulLocationSubmissions, setSuccessfulLocationSubmissions] =
    useState(0);
  const [apiCallCount, setApiCallCount] = useState(1);
  useEffect(() => {
    if (token) {
      setApiCallCount(1);
    }
  }, [token, successfulLocationSubmissions]);
  useEffect(() => {
    setLoading(false);
    if (operatorResponse) {
      setFeedBackProps({
        title: <b>{t('newOpCreated')} </b>,
        message: (
          <Typography>
            {t('thankforInput')} <br />
            {t('LBOPID')} {operatorResponse?.data?.data} {t('Created')} <br />
            {t('teamwillbeintouch')}
          </Typography>
        ),
        open: true,
        handleClose: () => {
          setFeedBackProps({ ...feedBackProps, open: false });
          setOperatorResponse(null);
          setConfirmOperator(false);
        },
      });
    }
    if (operatorRequestError) {
      setFeedBackProps({
        title: <b>{t('mistake')}</b>,
        message: (
          <>
            <Grid container direction="row" alignItems="center">
              <Grid container direction="row" alignItems="center">
                {
                  <WarningRounded
                    color="warning"
                    sx={{
                      display: 'flex',
                      mx: 'auto',
                      fontSize: 'xxx-large',
                    }}
                  />
                }
              </Grid>
            </Grid>

            <Typography>{t('LBLSomethingwentWrong')}</Typography>
          </>
        ),
        open: true,
        handleClose: () => {
          setFeedBackProps({ ...feedBackProps, open: false });
          setOperatorRequestError(null);
          setConfirmOperator(false);
        },
      });
    }
  }, [operatorResponse, operatorRequestError]);
  useEffect(() => {
    setLoading(false);
    if (locationResponse) {
      setFeedBackProps({
        title: <b>{t('NewLocationCreated')}</b>,
        message: (
          <Grid
            sx={{
              fontSize: '15px',
              alignContent: 'center',
              textAlign: 'center',
              marginTop: 2,
              marginRight: 20,
              marginLeft: 20,
            }}
          >
            <Grid>
              {t('thankforInput')}
              <br></br>
            </Grid>
            <Grid>
              {t('NewLocanamed')} <b>{locationResponse?.data?.data}</b>{' '}
              {t('Created')}
            </Grid>
            <Grid>
              {t('teamwillbeintouch')}
              <br></br>
              <br></br>
            </Grid>
          </Grid>
        ),
        open: true,
        handleClose: () => {
          setFeedBackProps({ ...feedBackProps, open: false });
          setLocationResponse(null);
          setConfirmLocation(false);
          setSuccessfulLocationSubmissions((prevState) => prevState + 1);
        },
      });
    }
    if (locationError) {
      if (locationError.errorCodeResponse === 'OPERATOR_NOT_FOUND') {
        setFeedBackProps({
          title: <b>{t('mistake')}</b>,
          message: (
            <>
              <Grid container direction="row" alignItems="center">
                <Grid container direction="row" alignItems="center">
                  <WarningRounded
                    color="warning"
                    sx={{
                      display: 'flex',
                      mx: 'auto',
                      fontSize: 'xxx-large',
                    }}
                  />
                </Grid>
              </Grid>
              <Typography>
                {t('LBL_OperatorNotFound', {
                  cpoId: locationError.errorDetail,
                })}
              </Typography>
            </>
          ),
          open: true,
          handleClose: () => {
            setFeedBackProps({ ...feedBackProps, open: false });
            setLocationError(null);
            setConfirmLocation(false);
          },
        });
      } else {
        setFeedBackProps({
          title: <b>{t('mistake')} </b>,
          message: (
            <>
              <Grid container direction="row" alignItems="center">
                <Grid container direction="row" alignItems="center">
                  {
                    <WarningRounded
                      color="warning"
                      sx={{
                        display: 'flex',
                        mx: 'auto',
                        fontSize: 'xxx-large',
                      }}
                    />
                  }
                </Grid>
              </Grid>
              <Typography>{t('LBLSomethingwentWrong')}</Typography>
            </>
          ),
          open: true,
          handleClose: () => {
            setFeedBackProps({ ...feedBackProps, open: false });
            setLocationError(null);
            setConfirmLocation(false);
          },
        });
      }
    }
  }, [locationResponse, locationError]);
  const handleSuccess = (input) => {
    const payload = jwtDecode(input);
    if (payload?.cpo_id?.length > 0 && payload?.customer_type === 'Operator') {
      setShowBetreiberButton(false);
    }
    setOperatorResponse(input);
    setApiCallCount(1);
  };

  const handleSetOperatorPayloadErst = (input) => {
    setOperatorPayloadErst(input);
  };
  const handleResetcount = (input) => {
    setApiCallCount(input);
  };
  return (
    <React.Fragment>
      <Helmet title="Invoices" />
      <LogoLoader loading={loading} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t('LBLLocationMgmt')}
          </Typography>
        </Grid>
        <Grid item mb={6}>
          <div>
            <Button
              spacing={1}
              container
              item
              xs={12}
              sx={{ marginLeft: 5 }}
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenLocationForm(true);
              }}
            >
              <AddIcon />
              {t('BTNAddLocation')}
            </Button>
            <StepperPopup
              isOpen={open}
              title={t('LBLCreateOp')}
              setIsOpen={setOpen}
              steps={[
                {
                  label: t('enterOpinfo'),
                  component: (
                    <OperatorForm
                      submit={submitOperator}
                      setPayload={setOperatorPayload}
                      setCanNext={setCanNextOperator}
                      setSubmit={setSubmitOperator}
                    />
                  ),
                  nextAction: {
                    label: t('LBLFurther'),
                    icon: <ArrowForward />,
                    action: () => {
                      setSubmitOperator((prev) => prev + 1);
                    },
                    canNext: canNextOperator,
                  },
                },
                {
                  label: t('infoOnInvoiceTemp'),
                  component: (
                    <OperatorErstellung
                      submit={submitOperatorErst}
                      setPayload={handleSetOperatorPayloadErst}
                      setCanNext={setCanNextOperatorErst}
                      setSubmit={setSubmitOperatorErst}
                    />
                  ),
                  nextAction: {
                    label: t('LBLFurther'),
                    icon: <ArrowForward />,
                    action: () => {
                      setSubmitOperatorErst((prev) => prev + 1);
                    },
                    canNext: canNextOperatorErst,
                  },
                },

                {
                  label: t('confirmInfo'),
                  component: (
                    <OperatorConfirmation
                      payload={{
                        customerId: operatorPayload?.customerId,
                        customerType: operatorPayload?.customerType,
                        cpoName: operatorPayload?.cpoName,
                        cpoAddress: operatorPayload?.cpoAddress,
                        billingType: operatorPayloadErst?.billingType,
                        isChargingHistoryIncluded:
                          operatorPayloadErst?.isChargingHistoryIncluded,
                        contactPersonDetails:
                          operatorPayloadErst?.contactPersonDetails,
                        cpoPaymentDetails:
                          operatorPayloadErst?.cpoPaymentDetails,
                        billingTemplateHeader:
                          operatorPayloadErst?.billingTemplateHeader,
                        billingTemplateFooter:
                          operatorPayloadErst?.billingTemplateFooter,
                      }}
                      submit={confirmOperator}
                      setSuccess={handleSuccess}
                      setError={setOperatorRequestError}
                    />
                  ),
                  nextAction: {
                    label: t('LBLSave'),
                    action: () => {
                      setConfirmOperator(true);
                      setOpen(false);
                      setLoading(true);
                    },
                  },
                },
              ]}
            ></StepperPopup>
            <Feedback
              open={feedBackProps.open}
              message={feedBackProps.message}
              title={feedBackProps.title}
              handleClose={feedBackProps.handleClose}
            ></Feedback>
            <StepperPopup
              isOpen={openLocationForm}
              setIsOpen={setOpenLocationForm}
              title={t('BTNAddLocation')}
              steps={[
                {
                  label: t('EnterLocation'),
                  component: (
                    <LocationForm
                      setPayload={setLocationPayload}
                      setSubmit={setSubmitLocation}
                      submit={submitLocation}
                      setCanNext={setCanNextLocation}
                    />
                  ),

                  nextAction: {
                    label: t('LBLFurther'),
                    icon: <ArrowForward />,
                    action: () => {
                      setSubmitLocation((prev) => prev + 1);
                    },
                    canNext: canNextLocation,
                  },
                },

                {
                  label: t('enterChargininfo'),
                  component: (
                    <LocationFormMultiple
                      setPayload={setLocationPayloadMultiple}
                      setSubmit={setSubmitLocationMultiple}
                      submit={submitLocationMultiple}
                      setCanNext={setCanNextLocationMultiple}
                    />
                  ),

                  nextAction: {
                    label: t('LBLFurther'),
                    icon: <ArrowForward />,
                    action: () => {
                      setSubmitLocationMultiple((prev) => prev + 1);
                    },
                    canNext: canNextLocationMultiple,
                  },
                },
                {
                  label: t('confirmInfo'),
                  component: (
                    <LocationConfirmation
                      payload={{
                        ...locationPayload,
                        ...locationPayloadMultiple,
                      }}
                      submit={confirmLocation}
                      setError={setLocationError}
                      setSuccess={setLocationResponse}
                    />
                  ),
                  nextAction: {
                    label: t('LBLSave'),
                    action: () => {
                      setConfirmLocation(true);
                      setOpenLocationForm(false);
                      setLoading(true);
                    },
                  },
                },
              ]}
            />
          </div>
        </Grid>
      </Grid>
      <Divider my={6} />
      <EnhancedTable
        apiCallCount={apiCallCount}
        handleReset={handleResetcount}
        token={token}
      />
    </React.Fragment>
  );
}

export default Locations;