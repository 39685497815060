import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import React from 'react';

import { useTranslation } from 'react-i18next';

const BillStatusLabel = ({ title }) => {
  let initialInputTitle = '';
  if (title == 'PAID' || title == 'paid') {
    initialInputTitle = 'paid';
  } else if (title == 'UNPAID' || title == 'unpaid') {
    initialInputTitle = 'unpaid';
  } else if (title == 'GENERATED' || title == 'generated') {
    initialInputTitle = 'generated';
  } else if (title == 'SENT' || title == 'sent') {
    initialInputTitle = 'sent';
  } else if (title == 'NOT_APPLICABLE' || title == 'not_applicable') {
    initialInputTitle = 'not_applicable';
  } else if (title == 'DISPUTE_CREATED' || title == 'dispute_created') {
    initialInputTitle = 'dispute_created';
  } else if (title == 'DISPUTED' || title == 'disputed') {
    initialInputTitle = 'disputed';
  } else {
    initialInputTitle = title;
  }

  const { t } = useTranslation();

  const BillStatusData = {
    paid: { color: '#4caf50', name: 'paid' },
    unpaid: { color: '#eb0f0f', name: 'unpaid' },
    not_applicable: { color: '#eb0f0f', name: 'not_applicable' },
    dispute_created: { color: '#eb0f0f', name: 'dispute_created' },
    disputed: { color: '#eb0f0f', name: 'disputed' },
    generated: { color: '#0c5ead', name: 'generated' },
    sent: { color: '#eb880f', name: 'sent' },
  };

  function geti18status() {
    let outputText = initialInputTitle;
    switch (BillStatusData[initialInputTitle]?.name) {
      case 'paid':
        outputText = t('LBLBillPaid');
        break;
      case 'unpaid':
        outputText = t('LBLBillUnpaid');
        break;
      case 'generated':
        outputText = t('LBLBillGenerated');
        break;
      case 'sent':
        outputText = t('LBLBillSent');
        break;
      case 'not_applicable':
        outputText = t('LBLBillNotApplicable');
        break;
      case 'dispute_created':
        outputText = t('LBLBillDisputeCreated');
        break;
      case 'disputed':
        outputText = t('LBLBillDisputed');
        break;
      default:
        outputText = initialInputTitle;
    }
    return outputText;
  }

  return (
    <Typography align="center" color={BillStatusData[initialInputTitle]?.color}>
      {geti18status()}
    </Typography>
  );
};

BillStatusLabel.propTypes = {
  title: PropTypes.string,
};
export default BillStatusLabel;
