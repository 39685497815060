import React, { useCallback, Suspense, lazy, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../services/contexts';
import CustumerComponent from './CustumerComponent';
import { UsersTable } from './UsersTable';
import { useParams } from 'react-router-dom';
import DetailsWrapper from '../../../../layouts/DetailsWrapper';
import * as Yup from 'yup';
import api from '../../../../Middleware/api/api';
import CreateCustomerUser from './components/CreateCustomerUser';
export default function CustumorDetail() {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [hitGetDetails, setHitGetDetails] = useState(false);
  const openModal = () => {
    setHitGetDetails(false);
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
    setHitGetDetails(true);
  };
  const [error, setError] = useState(false);
  const [errorLabel, setErrorLabel] = useState(false);
  const [successLabel, setSuccessLabel] = useState(false);
  // added two labels as new state variables to handle success and error on api call
  const { customerId } = useParams();
  const { token } = useAuth();
  const [contract, setContract] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refreshTheData, setRefreshTheData] = useState(0);
  const [error204, setError204] = useState(false);
  const Feedback = lazy(() => import('../../../../components/Feedback'));
  const FailFeedback = lazy(
    () => import('../../../../components/DataTable/Feedback')
  );
  const closeFeedback = () => {
    setErrorLabel(false);
    setSuccessLabel(false);
  };
  const getContractByCustomerId = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.fetchDashboardUsersByCustomerId(
        token,
        customerId
      );
      if (response?.data?.data) {
        setContract(response.data.data);
      } else {
        setContract({});
      }
    } catch (exception) {
      setLoading(false);
      setError(true);
    }
    setLoading(false);
  }, [token, customerId]);

  useEffect(() => {
    getContractByCustomerId();
  }, [errorLabel, refreshTheData]);

  return (
    <>
      <DetailsWrapper
        title={t('custMgmt')}
        loading={loading}
        helmet="invoices"
        backButtonConfig={{ label: t('backToCustList'), url: '/customers' }}
        contract={
          <CreateCustomerUser
            customerId={customerId}
            contract={contract}
            open={open}
            handleClose={closeModal}
            setOpen={openModal}
          />
        }
      >
        <Grid container direction="row" columnSpacing={1}>
          <Grid justifyContent="space-between" container spacing={10}>
            <Grid item>
              <Typography
                variant="h3"
                gutterBottom
                display="inline"
              ></Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CustumerComponent
              contract={contract}
              setErrorLabel={setErrorLabel}
              setSuccessLabel={setSuccessLabel}
            />
          </Grid>
        </Grid>
        <br></br>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <UsersTable customerId={customerId} hitGetDetails={hitGetDetails} />
          </Grid>
        </Grid>
      </DetailsWrapper>
      <Suspense fallback={<div />}>
        <Feedback
          open={successLabel}
          title={t('invitemailSent')}
          message={
            <>
              <Typography align="center">{t('lblMailsent')}</Typography>
            </>
          }
          handleClose={closeFeedback}
        />
        <FailFeedback
          open={errorLabel}
          title={'Fehler'}
          severity="warning"
          message={
            <>
              <Typography align="center">
                {t('LBLSomethingwentWrong')}
              </Typography>
            </>
          }
          handleClose={() => {
            closeFeedback();
          }}
        />

        <FailFeedback
          open={error}
          title={'Fehler'}
          severity="warning"
          message={
            <>
              <Typography align="center">
                {t('LBLSomethingwentWrong')}
              </Typography>
            </>
          }
          handleClose={() => {
            setError(false);
            setError204(false);
          }}
        />
        <FailFeedback
          open={error204}
          title={t('noinfoFound')}
          severity="warning"
          message={
            <>
              <Typography align="center">{t('LBLNoDataDC')}</Typography>
            </>
          }
          handleClose={() => {
            setError(false);
            setError204(false);
          }}
        />
      </Suspense>
    </>
  );
}
