import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../services/contexts/AuthContext';
import { useTokenUtils } from '../../../services/utils/tokenUtils';
import api from '../../../Middleware/api/api';
import LogoLoader from '../../../components/LogoLoader';

/*

CONNECTOR_UNLOCK, -> key:null, value:null [Text for frontend: Connector Unlock]
GET_DIAGNOSTICS, -> key:null, value:null [Text for frontend: Get...]
HARD_RESET, -> key:null, value:null
SOFT_RESET, -> key:null, value:null
GET_CONFIGURATION, -> key:STRING, value:null
TRIGGER_STATUS_NOTIFICATION, -> key:null, value:null
TRIGGER_METER_VALUE, -> key:null, value:null
TRIGGER_BOOT_NOTIFICATION, -> key:null, value:null
CHANGE_CONFIGURATION, -> key:STRING, value:STRING
TRIGGER_CLEAR_CHARGING_PROFILE -> key:null, value:null
*/

/**
 * @param {PropTypes.InferProps<ConfirmationBox.propTypes>} props
 */
const ConfirmationBox = ({
  open,
  title,
  message,
  handleSubmit,
  handleClose,
  chargingStation,
}) => {
  const { t } = useTranslation();
  const [action, setAction] = useState('');
  const [configurationKey, setConfigurationKey] = useState('');
  const [configurationValue, setConfigurationValue] = useState('');

  const closeModal = () => {
    setAction('');
    setConfigurationKey('');
    setConfigurationValue('');
    handleClose();
  };
  const memoizedHandleSubmit = useMemo(() => handleSubmit, [handleSubmit]);
  const memoizedHandleClose = useMemo(() => closeModal, [closeModal]);
  const [loading, setLoading] = useState(false);
  const { token } = useAuth();
  const { isAdminRole } = useTokenUtils();

  const CONFIGURATION_OPTIONS = [
    { key: t('ChangeConfiguration'), value: 'CHANGE_CONFIGURATION' },
    { key: t('ConnectorUnlock'), value: 'CONNECTOR_UNLOCK' },
    {
      key: t('GetDiagnostics'),
      value: 'GET_DIAGNOSTICS',
      isAdminRole: isAdminRole,
    },
    { key: t('HardRestartChargingStation'), value: 'HARD_RESET' },
    { key: t('SoftRestartChargingStation'), value: 'SOFT_RESET' },
    { key: t('GetConfiguration'), value: 'GET_CONFIGURATION' },
    {
      key: t('TriggerStatusNotification'),
      value: 'TRIGGER_STATUS_NOTIFICATION',
    },
    { key: t('TriggerMeterValue'), value: 'TRIGGER_METER_VALUE' },
    { key: t('TriggerBootNotification'), value: 'TRIGGER_BOOT_NOTIFICATION' },
    {
      key: t('TriggerClearChargingProfile'),
      value: 'TRIGGER_CLEAR_CHARGING_PROFILE',
    },
  ];

  const handleActionChange = (event) => {
    setAction(event.target.value);
    setConfigurationKey('');
    setConfigurationValue('');
  };

  const changeConfiguration = async () => {
    setLoading(true);
    const reqBody = {
      key: configurationKey,
      value: configurationValue,
      connectorOperationType: action,
      cpId: chargingStation.cpId,
      connectorId: chargingStation?.connectorId,
    };
    try {
      const response = await api.changeConfiguration(token, reqBody);
      if (response.data.error === null) {
        memoizedHandleSubmit(response);
        setAction('');
        setConfigurationKey('');
        setConfigurationValue('');
      }
    } catch (exception) {
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      PaperProps={{ sx: { py: 5, px: 8 } }}
    >
      <LogoLoader loading={loading} />
      {title && (
        <DialogTitle
          sx={{
            textAlign: 'center',
            fontWeight: '600',
            fontSize: 'x-large',
            pb: 8,
          }}
        >
          {title}
        </DialogTitle>
      )}
      <DialogContent sx={{ textAlign: 'center', py: 8 }}>
        {message}
        <Box
          sx={{
            maxWidth: 300,
            mt: 2,
            mx: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FormControl fullWidth size="small">
            <InputLabel id="action-select-label">
              {t('SelectAction')}
            </InputLabel>
            <Select
              labelId="action-select-label"
              value={action}
              onChange={handleActionChange}
              label={t('SelectAction')}
            >
              {CONFIGURATION_OPTIONS.map((item) => {
                return <MenuItem value={item?.value}>{item?.key}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Box>
        {action === 'CHANGE_CONFIGURATION' || action === 'GET_CONFIGURATION' ? (
          <Box
            sx={{
              mt: 2,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <TextField
              margin="dense"
              id="configurationKeyID"
              value={configurationKey}
              onChange={(event) => setConfigurationKey(event.target.value)}
              label={t('LBLChangeKey')}
              variant="outlined"
              my={2}
              name="configurationKey"
            />
            {action === 'CHANGE_CONFIGURATION' ? (
              <TextField
                margin="dense"
                id="configurationValueID"
                value={configurationValue}
                onChange={(event) => setConfigurationValue(event.target.value)}
                label={t('LBLChangeValue')}
                variant="outlined"
                my={2}
                sx={{ marginLeft: 5 }}
                name="configurationValue"
              />
            ) : null}
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
        }}
      >
        <Button
          variant="contained"
          color="inherit"
          sx={{ px: 12 }}
          onClick={memoizedHandleClose}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          sx={{ px: 12 }}
          onClick={() => {
            changeConfiguration();
          }}
          disabled={
            action === '' ||
            (action === 'CHANGE_CONFIGURATION' &&
              (configurationKey === '' || configurationValue === ''))
          }
        >
          {t('BTNChangeConfiguration')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
ConfirmationBox.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.node.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  chargingStation: PropTypes.object.isRequired,
};
export default ConfirmationBox;
