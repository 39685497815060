import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import api from '../../../../Middleware/api/api';
import { useAuth } from '../../../../services/contexts';
import { useTranslation } from 'react-i18next';
function StationConfirmation({ payload, submit, setResponse, setError }) {
  const { t } = useTranslation();
  const { token } = useAuth();
  useEffect(() => {
    if (submit)
      api
        .createCP(token, {
          locationName: payload?.location?.split(': ')[1],
          locationId: payload?.location?.split(': ')[0],
          numOfChargingStations: payload?.numOfChargingStations,
          chargingStationModel: payload?.chargingStationModel,
        })
        .then(
          (response) => {
            setResponse(response);
          },
          (error) => {
            setError(error);
          }
        );
  }, [submit]);

  return (
    <>
      <Grid
        sx={{
          fontSize: '16px',
          marginTop: 2,
        }}
      >
        <Grid container spacing={6} sx={{ marginY: 5 }}>
          <Grid item md={6}>
            {t('LBLLocation')}
          </Grid>
          <Grid item md={6}>
            {payload?.location}
          </Grid>
        </Grid>
        <Grid container spacing={6} sx={{ marginY: 5 }}>
          <Grid item md={6}>
            {t('numcs')}
          </Grid>
          <Grid item md={6}>
            {payload?.numOfChargingStations}
          </Grid>
        </Grid>
        <Grid container spacing={6} sx={{ marginY: 5 }}>
          <Grid item md={6}>
            {t('csModel')}
          </Grid>
          <Grid item md={6}>
            {payload?.chargingStationModel}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default StationConfirmation;
