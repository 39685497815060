import React, { lazy, Suspense, useEffect, useState } from 'react';

import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  CardContent,
  Grid as MuiGrid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Stack,
  FormControl,
  Backdrop,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { lighten } from '@mui/material/styles';
import { Box, spacing } from '@mui/system';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Form, Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';
import { userService, backendForFrontend } from '../../services/utils/axios';
import { useUser } from '../../services/contexts';

import { useAuth } from '../../services/contexts/AuthContext';

const Feedback = lazy(() => import('../../components/auth/Feedback'));

import api from '../../Middleware/api/api';
import { setSession } from '../../services/utils/jwt';

import { Link, useNavigate } from 'react-router-dom';

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const Spacer = styled.div(spacing);

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  border: `1px solid ${lighten(theme.palette.divider, 0.5)}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor: lighten(theme.palette.background.default, 0.4),
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(-90deg)',

      '&.Mui-expanded': {
        transform: 'rotate(0deg)',
      },
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  backgroundColor: '#fff',
  borderTop: '1px solid rgba(0, 0, 0, 0.0625)',
}));

const initialValues = {
  repeatPassword: '',
  newPassword: '',
  feedback: {
    title: '',
    detail: '',
  },
};
const initialValuesEmail = {
  repeatEmail: '',
  newEmail: '',
};

function EmptyCard() {
  const { authenticate } = useAuth();
  const navigate = useNavigate();
  const [loggedIn] = useState(false);

  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    repeatPassword: Yup.string()
      .min(10, t('pwdLength'))
      .required(t('fillthis'))
      .label(t('repassword')),
    newPassword: Yup.string()
      .min(10, t('newPwdLength'))
      .required(t('fillthis'))
      .label(t('newPwd')),
  });
  const EmailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
  const validationSchemaEmail = Yup.object().shape({
    repeatEmail: Yup.string()
      .email(t('emailNotValid'))
      .matches(EmailRegex, t('emailNotValid'))
      .required(t('fillthis'))
      .oneOf([Yup.ref('newEmail'), null], t('emailnotmatch'))
      .label(t('confEmail')),
    newEmail: Yup.string()
      .email(t('emailNotValid'))
      .matches(EmailRegex, t('emailNotValid'))
      .required(t('fillthis'))

      .label(t('LBLNewEmail')),
  });

  const { user } = useUser();
  const { token, refreshToken, origin } = useAuth();
  const USER_NAME = user?.name;
  const USER_EMAIL = user?.email;
  const USER_ROLE = user?.role_company;
  const CUSTOMER_ID = user?.customer_id;
  const CUSTOMER_TYPE = user?.customer_type;
  const COMPANY_NAME = user?.company_name;
  const C_O = user?.c_o;
  const STREET_NAME = user?.street_name;
  const CITY = user?.city;
  const COUNTRY = user?.country;
  const POSTCODE = user?.postcode;
  const HOUSE_NUMBER = user?.house_number;

  const [showPassword, setShow] = useState(false);
  const [showPasswordRepeate, setShowRepeate] = useState(false);

  const handleClickShowPassword = () => {
    setShow(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPasswordRepeate = () => {
    setShowRepeate(!showPasswordRepeate);
  };
  const handleMouseDownPasswordRepeate = (event) => {
    event.preventDefault();
  };

  /**
   *
   * @param {typeof initialValues} values
   * @param {import("formik").FormikHelpers<typeof initialValues>} actions
   */
  const handleSubmit = async (
    values,
    { setStatus, setSubmitting, setFieldValue, resetForm }
  ) => {
    if (
      !new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/).test(
        values.newPassword
      )
    ) {
      setStatus({ completed: true, success: false });
      setFieldValue('feedback', {
        title: t('pwdNotSure'),
        detail: t('pwdrule'),
      });
      setSubmitting(false);

      return;
    }

    if (values.newPassword !== values.repeatPassword) {
      setStatus({ completed: true, success: false });
      setFieldValue('feedback', {
        title: t('mistake'),
        detail: t('pwdnotmatch'),
      });
      setSubmitting(false);

      return;
    }

    try {
      const res = await userService.put(
        '/change-password',
        {
          username: user?.preferred_username,
          newPassword: values.newPassword,
          origin: 'dashboard',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const success = res.data.data;

      resetForm();
      setFieldValue('feedback', {
        title: t('pwdchangesaved'),
        detail: t('newPdchanged'),
      });

      setStatus({ completed: true, success });
    } catch (error) {
      setFieldValue('feedback', {
        title: t('mistake'),
        detail: t('LBLSomethingwentWrong'),
      });

      setStatus({ completed: true, success: false });
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (loggedIn) {
      navigate('/cockpit');
    }
  }, [loggedIn, navigate]);

  const handleSubmitEmail = async (
    values,
    { setStatus, setSubmitting, setFieldValue, resetForm }
  ) => {
    if (
      values.newEmail.toLocaleLowerCase() === USER_EMAIL.toLocaleLowerCase()
    ) {
      setStatus({ completed: true, success: false });
      setFieldValue('feedback', {
        title: t('mistake'),
        detail: t('newemailsameasold'),
      });
      setSubmitting(false);

      return;
    }

    try {
      const res = await backendForFrontend.post(
        '/cpos/dashboard-update-user-email',
        {
          oldEmail: user?.email,
          newEmail: values.newEmail,
          origin: 'dashboard',
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const success = res.data.data;

      resetForm();
      setFieldValue('feedback', {
        title: t('emailchangesaved'),
        detail: t('newemailchanged'),
      });

      api
        .refreshTokenRequest(token, {
          currentAccessToken: token,
          currentRefreshToken: refreshToken,
          origin: origin,
        })
        .then((tokenResponse) => {
          authenticate(
            tokenResponse?.access_token,
            tokenResponse?.refresh_token
          );
          setSession(
            tokenResponse.data.data.access_token,
            tokenResponse.data.data.refresh_token
          );
        });
      setStatus({ completed: true, success });
    } catch (error) {
      if (error.error.errorCodeResponse === 'DUPLICATED_EMAIL') {
        setFieldValue('feedback', {
          title: t('dublicatedEmailTitle'),
          detail: t('dublicatedEmailDetail'),
        });
      } else {
        setFieldValue('feedback', {
          title: t('mistake'),
          detail: t('LBLSomethingwentWrong'),
        });
      }

      setStatus({ completed: true, success: false });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Card mb={6}>
      <CardContent
        sx={{
          p: 0,

          '&:last-child': {
            pb: 0,
          },

          '& .MuiAccordion-root': {},

          '& .MuiAccordionDetails-root': {
            bgcolor: '#fff',
          },
        }}
      >
        <div>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('LBLNutzerangaben')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Spacer mb={2} />
              <Grid container direction="row" alignItems="center" mb={2}>
                <Grid item>Name: {USER_NAME}</Grid>
              </Grid>
            </AccordionDetails>
            <AccordionDetails>
              <Spacer mb={2} />
              <Grid container direction="row" alignItems="center" mb={2}>
                <Grid item>E-Mail: {USER_EMAIL}</Grid>
              </Grid>
            </AccordionDetails>
            <AccordionDetails>
              <Spacer mb={2} />
              <Grid container direction="row" alignItems="center" mb={2}>
                <Grid item>
                  {t('LBLRolle')}: {USER_ROLE}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('LBLUnternehmensangaben')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Spacer mb={2} />
              <Grid container direction="row" alignItems="center" mb={2}>
                <Grid item>
                  {t('LBLCustId')}: {CUSTOMER_ID}
                </Grid>
              </Grid>
            </AccordionDetails>
            <AccordionDetails>
              <Spacer mb={2} />
              <Grid container direction="row" alignItems="center" mb={2}>
                <Grid item>
                  {t('LBLCustType')}: {CUSTOMER_TYPE}
                </Grid>
              </Grid>
            </AccordionDetails>
            <AccordionDetails>
              <Spacer mb={2} />
              <Grid container direction="row" alignItems="center" mb={2}>
                <Grid item>Name: {COMPANY_NAME}</Grid>
              </Grid>
            </AccordionDetails>
            <AccordionDetails>
              <Spacer mb={2} />
              <Grid container direction="row" alignItems="center" mb={2}>
                <Grid item>
                  {t('LBLAddress')}: {C_O ? C_O + ', ' : ''} {STREET_NAME}{' '}
                  {HOUSE_NUMBER}, {POSTCODE} {CITY} {COUNTRY}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('LBLCHangePWD')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Spacer mb={2} />
              <Typography color="text.secondary">
                {t('LBLCHangePWDDisc')}
              </Typography>
              <Stack alignItems="center" mt={4}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    errors,
                    touched,
                    values,
                    status,
                    setStatus,
                    isSubmitting,
                  }) => (
                    <Form noValidate>
                      <Suspense
                        fallback={
                          <Backdrop open>
                            <CircularProgress />
                          </Backdrop>
                        }
                      >
                        <Feedback
                          open={Boolean(status?.completed)}
                          title={values.feedback?.title || ''}
                          severity={status?.success ? 'success' : 'warning'}
                          message={values.feedback?.detail || ''}
                          handleClose={() =>
                            setStatus({ ...status, completed: false })
                          }
                        />
                      </Suspense>
                      <Stack spacing={6}>
                        <FormControl
                          margin="none"
                          error={Boolean(
                            errors.newPassword && touched.newPassword
                          )}
                        >
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="flex-end"
                            width={412}
                            columnGap={7}
                          >
                            <Grid
                              item
                              container
                              justifyContent="flex-end"
                              xs={5}
                            >
                              <label htmlFor="neues-passwort">
                                {t('newPWD')} <span>*</span>
                              </label>
                            </Grid>
                            <Grid
                              item
                              container
                              justifyContent="flex-end"
                              xs={6}
                            >
                              <Field
                                type={showPassword ? 'text' : 'password'}
                                name="newPassword"
                                label={t('LBLPassword')}
                                margin="normal"
                                component={TextField}
                                sx={{ mb: 4 }}
                                fullWidth
                                required
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {showPassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          </Grid>
                        </FormControl>
                        <FormControl
                          margin="none"
                          error={Boolean(
                            errors.repeatPassword && touched.repeatPassword
                          )}
                        >
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="flex-end"
                            width={412}
                            columnGap={7}
                          >
                            <Grid
                              item
                              container
                              justifyContent="flex-end"
                              xs={5}
                            >
                              <label htmlFor="repeat-password">
                                {t('confPWD')} <span>*</span>
                              </label>
                            </Grid>
                            <Grid
                              item
                              container
                              justifyContent="flex-end"
                              xs={6}
                            >
                              <Field
                                type={showPasswordRepeate ? 'text' : 'password'}
                                name="repeatPassword"
                                label={t('confPWD')}
                                margin="normal"
                                component={TextField}
                                sx={{ mb: 4 }}
                                fullWidth
                                required
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={handleClickShowPasswordRepeate}
                                        onMouseDown={
                                          handleMouseDownPasswordRepeate
                                        }
                                      >
                                        {showPasswordRepeate ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          </Grid>
                        </FormControl>
                        <div>
                          <LoadingButton
                            type="submit"
                            disabled={
                              !values.repeatPassword || !values.newPassword
                            }
                            loading={isSubmitting}
                            variant="contained"
                            sx={{
                              px: 12,
                              my: 4,
                              mx: 'auto',
                              display: 'inherit',
                            }}
                          >
                            {t('LBLSave')}
                          </LoadingButton>
                        </div>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </Stack>
            </AccordionDetails>
          </Accordion>

          {/* email update starts  */}
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('LBLChangeEmail')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Spacer mb={2} />
              <Typography color="text.secondary">
                {t('LBLChangeEmailDisc')}
              </Typography>
              <Stack alignItems="center" mt={4}>
                <Formik
                  initialValues={initialValuesEmail}
                  validationSchema={validationSchemaEmail}
                  onSubmit={handleSubmitEmail}
                >
                  {({
                    errors,
                    touched,
                    values,
                    status,
                    setStatus,
                    isSubmitting,
                  }) => (
                    <Form noValidate>
                      <Suspense
                        fallback={
                          <Backdrop open>
                            <CircularProgress />
                          </Backdrop>
                        }
                      >
                        <Feedback
                          open={Boolean(status?.completed)}
                          title={values.feedback?.title || ''}
                          severity={status?.success ? 'success' : 'warning'}
                          message={values.feedback?.detail || ''}
                          handleClose={() => {
                            setStatus({ ...status, completed: false });
                            if (status?.success) window.location.reload();
                          }}
                        />
                      </Suspense>
                      <Stack spacing={6}>
                        <FormControl
                          margin="none"
                          error={Boolean(errors.newEmail && touched.newEmail)}
                        >
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="flex-end"
                            width={412}
                            columnGap={7}
                          >
                            <Grid
                              item
                              container
                              justifyContent="flex-end"
                              xs={5}
                            >
                              <label htmlFor="neues-passwort">
                                {t('newEmail')} <span>*</span>
                              </label>
                            </Grid>
                            <Grid
                              item
                              container
                              justifyContent="flex-end"
                              xs={6}
                            >
                              <Field
                                type="text"
                                name="newEmail"
                                label={t('LBLEmail')}
                                margin="normal"
                                component={TextField}
                                sx={{ mb: 4 }}
                                fullWidth
                                required
                              />
                            </Grid>
                          </Grid>
                        </FormControl>
                        <FormControl
                          margin="none"
                          error={Boolean(
                            errors.repeatEmail && touched.repeatEmail
                          )}
                        >
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="flex-end"
                            width={412}
                            columnGap={7}
                          >
                            <Grid
                              item
                              container
                              justifyContent="flex-end"
                              xs={5}
                            >
                              <label htmlFor="repeat-password">
                                {t('confEmail')} <span>*</span>
                              </label>
                            </Grid>
                            <Grid
                              item
                              container
                              justifyContent="flex-end"
                              xs={6}
                            >
                              <Field
                                type="text"
                                name="repeatEmail"
                                label={t('confEmail')}
                                margin="normal"
                                component={TextField}
                                sx={{ mb: 4 }}
                                fullWidth
                                required
                              />
                            </Grid>
                          </Grid>
                        </FormControl>
                        <div>
                          <LoadingButton
                            type="submit"
                            disabled={
                              !values.repeatEmail ||
                              !values.newEmail ||
                              values.repeatEmail != values.newEmail
                            }
                            loading={isSubmitting}
                            variant="contained"
                            sx={{
                              px: 12,
                              my: 4,
                              mx: 'auto',
                              display: 'inherit',
                            }}
                          >
                            {t('LBLSave')}
                          </LoadingButton>
                        </div>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </Stack>
            </AccordionDetails>
          </Accordion>
          {/* email update ends */}
        </div>{' '}
      </CardContent>
    </Card>
  );
}

function Settings() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        {t('MenuIdeas')}
      </Typography>

      <Divider my={6} />

      <Grid
        container
        spacing={6}
        sx={{
          '@media (orientation: landscape)': {
            minHeight: '52vh',
          },
        }}
      >
        <Grid item xs={12}>
          <EmptyCard />
        </Grid>
      </Grid>
      <Box
        width="100%"
        sx={{
          position: 'absolute',
          left: 0,
          bottom: '5%',

          '@media (orientation: landscape)': {
            position: 'inherit',
            left: 'unset',
            bottom: 'unset',
          },
        }}
      >
        <Typography variant="subtitle1" align="center">
          Version 3.0.0
        </Typography>
        <Typography variant="subtitle1" align="center">
          {t('madefromGermany')}
        </Typography>
      </Box>
    </React.Fragment>
  );
}

export default Settings;