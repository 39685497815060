import React from 'react';
import { useAuth } from '../../services/contexts';
import CircularProgress from '@mui/material/CircularProgress';
import { Navigate } from 'react-router-dom';

const AuthGuard = ({ children }) => {
  const { isAuthenticated, pendingAuth } = useAuth();

  // Show loading while validating then redirect when validation is complete
  if (pendingAuth)
    return (
      <CircularProgress
        size={36}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          mt: -4.5,
          ml: -4.5,
        }}
      />
    );

  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

export default AuthGuard;
