import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  lazy,
  Suspense,
} from 'react';
import styled from 'styled-components/macro';
import Checkbox from '@mui/material/Checkbox';
import {
  Chip as MuiChip,
  Typography,
  Stack,
  Button,
  MenuItem,
  OutlinedInput,
  Select,
  ListItemText,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import shadows from '@mui/material/styles/shadows';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Form, Field, Formik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { useAuth } from '../../services/contexts/AuthContext';
import api from '../../Middleware/api/api';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LogoLoader from '../../components/LogoLoader';
const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  margin-left: 7px;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : 'primary'].light};
  color: white !important;
`;

function calculatedates(days) {
  let date = new Date();
  let last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
  return (
    last.getFullYear() +
    '-' +
    last.toLocaleString('en-US', { month: '2-digit' }) +
    '-' +
    last.toLocaleString('en-US', { day: '2-digit' })
  );
}
const FailFeedback = lazy(() => import('../../components/DataTable/Feedback'));
function DownloadBillsCSV() {
  const { t, i18n } = useTranslation();
  const { token } = useAuth();
  const locationName = t('MenuLocation');
  const [stations, setStations] = useState([]);
  const [selected, setSelected] = useState([]);
  const [anchorLocation, setAnchorLocation] = useState(null);
  const fileFormat = 'CSV';
  const [startDate, setStartDate] = useState(calculatedates(7));
  const [endDate, setEndDate] = useState(calculatedates(0));
  const [error, setError] = useState(false);
  const [error204, setError204] = useState(false);
  const [error404, setError404] = useState(false);
  const isAllSelected =
    stations.length > 0 && selected.length === stations.length;
  const [loading, setLoading] = useState(false);
  const [ButtonDownloadBillIsSubmitting, setButtonDownloadBillIsSubmitting] =
    useState(false);
  const getStations = async () => {
    api.fetchLocationsForDownloadCenter(token).then((response) => {
      if (response.data.data == null) {
        setStations([]);
      } else {
        setStations(response.data.data.response);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getStations();
    return () => {
      setStations([]);
    };
  }, []);

  const initialValues = {
    startDate: startDate,
    endDate: endDate,
  };
  const validationSchema = useMemo(
    () =>
      Yup.object({
        startDate: Yup.date()
          .typeError(t('LBLInvalidDate'))
          .required(i18n.t('LBLReqCockpit')),
        endDate: Yup.date()
          .typeError(t('LBLInvalidDate'))
          .min(Yup.ref('startDate'), i18n.t('LBLENdgStart'))
          .required(i18n.t('LBLEndDateReq')),
      }),
    []
  );

  const locationButtonComponent = useRef(null);
  const [leftPosition, setLeftPosition] = useState('0');
  useEffect(() => {
    setLeftPosition(
      locationButtonComponent.current.getBoundingClientRect().left - 2
    );
  }, [locationButtonComponent]);

  const handleLocationClick = (event) => {
    setAnchorLocation(true);
  };

  const handleTestLocationChange = (event) => {
    if (event.target.value !== undefined) {
      if (
        event.target.value.includes('all') &&
        selected.length !== stations.length
      ) {
        setSelected(stations.map((station) => station.locationId));
      } else if (event.target.value.includes('all')) {
        setSelected([]);
      } else {
        setSelected(event.target.value);
      }
    } else {
      setAnchorLocation(false);
    }
  };

  /** @type {import("formik").FormikConfig<typeof initialValues>['onSubmit']} */
  const handleSubmit = (values, actions) => {
    setStartDate(values.startDate);
    setEndDate(values.endDate);
    setButtonDownloadBillIsSubmitting(true);
    setLoading(true);
    fetchBillsCSV(values.startDate, values.endDate);

    actions.setSubmitting(false);
    actions.setTouched({}, true);
    actions.setErrors({});
    actions.setFieldError({});
    actions.setFieldTouched({}, true, true);
  };

  const handleFromDatePostingChange = (newValue, setFieldValue) => {
    if (
      newValue instanceof Date &&
      (!isNaN(newValue.getTime()) || !isNaN(newValue.toDate()))
    ) {
      setStartDate(format(newValue, 'yyyy-MM-dd'));
      setFieldValue('startDate', format(newValue, 'yyyy-MM-dd'));
    } else if (newValue != null && !isNaN(newValue.toDate())) {
      setStartDate(format(newValue.toDate(), 'yyyy-MM-dd'));
      setFieldValue('startDate', format(newValue.toDate(), 'yyyy-MM-dd'));
    } else {
      setStartDate(newValue);
      setFieldValue('startDate', newValue);
      console.error('Invalid FromDatePosting input');
    }
  };

  const handleUntilDatePostingChange = (newValue, setFieldValue) => {
    if (
      newValue instanceof Date &&
      (!isNaN(newValue.getTime()) || !isNaN(newValue.toDate()))
    ) {
      setEndDate(format(newValue, 'yyyy-MM-dd'));
      setFieldValue('endDate', format(newValue, 'yyyy-MM-dd'));
    } else if (newValue != null && !isNaN(newValue.toDate())) {
      setEndDate(format(newValue.toDate(), 'yyyy-MM-dd'));
      setFieldValue('endDate', format(newValue.toDate(), 'yyyy-MM-dd'));
    } else {
      setEndDate(newValue);
      setFieldValue('endDate', newValue);
      console.error('Invalid UntilDatePosting input');
    }
  };

  const fetchBillsCSV = (startDateValue, endDateValue) => {
    api
      .fetchBillsCSV(
        startDateValue,
        endDateValue,
        fileFormat,
        selected.toString(),
        token
      )
      .then((response) => {
        setButtonDownloadBillIsSubmitting(false);
        setLoading(false);
        if (response.status == 404) {
          setError404(true);
        } else if (response.status == 200) {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              'StandardBills' +
                format(new Date(endDateValue), 'yyyyMMdd') +
                '.csv'
            );
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
        } else {
          setError(true);
        }
      })
      .catch((reason) => {
        setButtonDownloadBillIsSubmitting(false);
        setLoading(false);
        if (reason.response.status === 404) {
          setError404(true);
        } else {
          setError(true);
        }
      });
  };
  return (
    <>
      <LogoLoader loading={loading} />
      <div>
        <br />
        <Typography variant="h6" gutterBottom display="inline">
          {t('LBLDownloadInvoice')}
        </Typography>
        <br />
        <br />
      </div>

      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form noValidate>
              <Stack
                direction="row"
                spacing={12}
                alignItems="center"
                border={'none'}
                width="100%"
              >
                <div
                  style={{
                    width: '100%',
                    height: '70px',
                    textAlign: 'center',
                    marginTop: '30px',
                    margin: 'auto',
                  }}
                >
                  <div
                    style={{
                      width: '24%',
                      height: '50px',
                      textAlign: 'left',
                      float: 'left',
                    }}
                  >
                    <div style={{ backgroundColor: 'transparent' }}>
                      <div>
                        <Button
                          endIcon={<ArrowDropDownIcon fontSize="small" />}
                          variant="contained"
                          color="primary"
                          aria-haspopup="true"
                          onClick={handleLocationClick}
                          id="mutiple-select-label"
                          ml={8}
                          ref={locationButtonComponent}
                        >
                          {locationName} 
                          <Chip label={selected.length} size="small" />
                        </Button>
                        <Select
                          id="chart-location"
                          variant="filled"
                          size="small"
                          open={Boolean(anchorLocation)}
                          labelId="mutiple-select-label"
                          input={
                            <OutlinedInput
                              sx={{ fontSize: '2rem' }}
                              label="Tag"
                            />
                          }
                          multiple
                          value={selected}
                          onChange={handleTestLocationChange}
                          renderValue={(selected) => selected.length}
                          IconComponent={() => null}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                left: `${leftPosition}px !important`,
                                top: '300px !important',
                                maxHeight: '200px !important',
                                fontSize: '2 !important',
                              },
                            },
                            //getContentAnchorEl: null,
                          }}
                          onClose={handleTestLocationChange}
                          style={{ display: 'none' }}
                        >
                          <MenuItem value="all" divider>
                            <Checkbox
                              size="small"
                              checked={isAllSelected}
                              indeterminate={
                                selected.length > 0 &&
                                selected.length < stations.length
                              }
                            />
                            <ListItemText primary={t('LBLALLLocations')} />
                          </MenuItem>
                          {stations.map((station) => (
                            <MenuItem
                              size="small"
                              key={station.locationId}
                              value={station.locationId}
                            >
                              <Checkbox
                                size="small"
                                checked={
                                  selected.indexOf(station.locationId) > -1
                                }
                              />
                              <ListItemText
                                primaryTypographyProps={{ fontSize: '10px' }}
                              >
                                {station.locationId +
                                  ': ' +
                                  station.locationName}
                              </ListItemText>
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: '24%',
                      height: '50px',
                      textAlign: 'center',
                      float: 'left',
                    }}
                  >
                    <div style={{ backgroundColor: 'transparent' }}>
                      <div>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="de"
                        >
                          <DatePicker
                            label={t('LBLStartDate')}
                            PaperProps={{ sx: { boxShadow: shadows[3] } }}
                            value={values.startDate}
                            onChange={(newValue) => {
                              handleFromDatePostingChange(
                                newValue,
                                setFieldValue
                              );
                            }}
                            renderInput={(params) => (
                              <Field
                                component={TextField}
                                {...params}
                                required
                                name="startDate"
                                margin="none"
                                autoComplete="off"
                                helperText={errors.startDate}
                                error={errors.startDate}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: 'TT.MM.JJJJ',
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: '24%',
                      height: '50px',
                      textAlign: 'right',
                      float: 'left',
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: 'transparent',
                        marginLeft: '50px',
                      }}
                    >
                      <div>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="de"
                        >
                          <DatePicker
                            PaperProps={{ sx: { boxShadow: shadows[3] } }}
                            label={t('LBLEndDate')}
                            value={values.endDate}
                            onChange={(newValue) => {
                              handleUntilDatePostingChange(
                                newValue,
                                setFieldValue
                              );
                            }}
                            maxDate={dayjs()}
                            renderInput={(params) => (
                              <Field
                                component={TextField}
                                {...params}
                                name="endDate"
                                margin="none"
                                autoComplete="off"
                                helperText={touched.endDate && errors.endDate}
                                error={errors.endDate && touched.endDate}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: 'TT.MM.JJJJ',
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: '24%',
                      height: '50px',
                      textAlign: 'right',
                      float: 'left',
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: 'transparent',
                        marginLeft: '50px',
                        marginTop: '-15px',
                      }}
                    >
                      <div>
                        <TextField
                          select
                          fullWidth
                          id="historyformat"
                          label="Format"
                          value={fileFormat}
                          name="HistoryFormat"
                        >
                          <MenuItem value={'CSV'}>CSV</MenuItem>
                        </TextField>
                      </div>
                    </div>
                  </div>
                </div>
              </Stack>
              <div
                style={{
                  margin: 'auto',
                  textAlign: 'center',
                  marginTop: '100px',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={
                    ButtonDownloadBillIsSubmitting ||
                    selected.length == 0 ||
                    errors.startDate ||
                    errors.endDate
                  }
                >
                  {t('BTNDownload')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Suspense fallback={<div />}>
        <FailFeedback
          open={error204}
          title={t('noinfoFound')}
          severity="warning"
          message={
            <>
              <Typography align="center">{t('noDataForSelected')}</Typography>
            </>
          }
          handleClose={() => {
            setError(false);
            setError204(false);
          }}
        />

        <FailFeedback
          open={error404}
          title={t('title404')}
          severity="warning"
          message={
            <>
              <Typography align="center">{t('body404')}</Typography>
            </>
          }
          handleClose={() => {
            setError(false);
            setError204(false);
            setError404(false);
          }}
        />
        <FailFeedback
          open={error}
          title={t('mistake')}
          severity="warning"
          message={
            <>
              <Typography align="center">
                {t('LBLSomethingwentWrong')}
              </Typography>
            </>
          }
          handleClose={() => {
            setError(false);
            setError204(false);
            setError404(false);
          }}
        />
      </Suspense>
    </>
  );
}

export default DownloadBillsCSV;