/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from '@mui/material';
import api from '../../Middleware/api/api';
import { useAuth } from '../../services/contexts';
import { mapStatus } from '../../pages/Stations';
import { useTranslation } from 'react-i18next';
MultipleStatusEdit.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  cols: PropTypes.object.isRequired,
};

function MultipleStatusEdit({
  setShowFeedback,
  data,
  cols,
  isReadOnly,
  payload,
  setPayload,
  submit,
  checkStatusChange,
}) {
  const { token } = useAuth();
  const { status } = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    setPayload(
      data.map((value) => ({
        connectorId: value.connectorId,
        connectorStatus: value.connectorStatus,
        changed: false,
      }))
    );
  }, []);

  function handleChange(event) {
    setPayload((prevState) =>
      prevState.map((value, index) => {
        if (index.toString() === event.target.name.split('-')[1]) {
          return {
            ...value,
            changed: event.target.value !== data[index].connectorStatus,
            connectorStatus: event.target.value,
          };
        }
        return value;
      })
    );
  }
  
  useEffect(() => {
    if (submit > 0) {
      api
        .changeAvailability(token, {
          changeAvailabilityRequest: payload.map((row) => ({
            connectorId: row.connectorId,
            connectorStatus: row.connectorStatus,
          })),
        })
        // eslint-disable-next-line no-unused-vars
        .then((response) => setShowFeedback(true));
    }
  }, [submit]);

  return (
    <>
      <TableContainer component={Paper} variant="outlined">
        <Table
          aria-label="simple table"
          sx={({ palette, typography }) => ({
            '& .MuiTableHead-root .MuiTableCell-root': {
              fontWeight: 600,
              fontSize: typography.subtitle2.fontSize,
            },

            '& .MuiTableRow-root .MuiTableCell-root': {
              width: 140,
              borderLeft: `1px solid ${palette.divider}`,
              borderRight: `1px solid ${palette.divider}`,
            },
          })}
        >
          <TableHead>
            <TableRow>
              {cols?.map((column) => (
                <TableCell
                  key={column.name}
                  align={column.name === 'connectorStatus' ? 'center' : 'left'}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, rowIndex) => (
              <TableRow>
                {cols?.map((col, index) => {
                  if (col.type === 'select') {
                    if (isReadOnly) {
                      let statusRender = mapStatus(
                        payload[rowIndex]?.connectorStatus?.toLowerCase(),
                        ' ',
                        t
                      );
                      return (
                        <TableCell
                          sx={{ color: status[statusRender?.toLowerCase()] }}
                          align={'center'}
                        >
                          {statusRender}
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell align={'center'}>
                        <TextField
                          select
                          label={' '}
                          variant="standard"
                          margin="dense"
                          sx={{ textAlign: 'center' }}
                          id={`field-${index}`}
                          inputProps={{
                            'data-id': index,
                          }}
                          name={`field-${rowIndex}`}
                          value={payload[rowIndex]?.connectorStatus || ''}
                          error={
                            !payload[rowIndex]?.changed && checkStatusChange
                          }
                          helperText={
                            !payload[rowIndex]?.changed && checkStatusChange
                              ? 'Feld nicht geändert'
                              : ''
                          }
                          onChange={handleChange}
                        >
                          {col.selectConfig?.values.map((value, valIndex) => (
                            <MenuItem key={valIndex + 1} value={value.value}>
                              {value.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </TableCell>
                    );
                  }

                  if (col.name == 'parkingLot') {
                    return <TableCell>{row?.address[col.name]}</TableCell>;
                  } else {
                    return <TableCell>{row[col.name]}</TableCell>;
                  }
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default MultipleStatusEdit;