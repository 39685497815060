import React, { useMemo, useState, useEffect } from 'react';
import MuiPaper from '@mui/material/Paper';
import styled from 'styled-components/macro';
import { spacing } from '@mui/system';
import { formatDecimal } from '../../services/utils/format-numbers';
import { Typography, TableCell } from '@mui/material';
import DataTable from '../../components/DataTable';
import { useAuth } from '../../services/contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import TimePeriodLabel from '../../components/TimePeriodLabel';
import { PAGE_SIZE, MIN_PAGE_SIZE } from '../../services/utils/units-constants';
import api from '../../Middleware/api/api';
import LogoLoader from '../../components/LogoLoader';
const Paper = styled(MuiPaper)(spacing);
function EnhancedTable({
  startDate,
  endDate,
  filterParam,
  filterValue,
  changeFlag,
}) {
  const { t, i18n } = useTranslation();

  const { token } = useAuth();
  const [fetchingData, setFetchingData] = useState(false);
  const [chargingHistory, setChargingHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE[0]);
  const [loading, setLoading] = useState(false);
  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date);
  };
  const params = useMemo(() => {
    const urlParams = new URLSearchParams();
    if (
      (filterParam == 'transactionId' || filterParam == 'endCustomerId') &&
      filterValue != undefined &&
      filterValue != null &&
      filterValue != ''
    ) {
      urlParams.set(filterParam, filterValue);
    }
    if (isValidDate(startDate) && isValidDate(endDate)) {
      urlParams.set('startDate', startDate);
      urlParams.set('endDate', endDate);
    }
    return urlParams;
  }, [endDate, startDate, filterParam, filterValue]);

  const getCharginHistory = async (seletedPageSize, currentPageVal) => {
    setFetchingData(true);
    setLoading(true);
    api
      .fethcChargingHistory(token, params, currentPageVal, seletedPageSize)
      .then((response) => {
        if (response.data.data == null) {
          setChargingHistory([]);
          setFetchingData(false);
          setLoading(false);
        } else {
          setTotalRecords(response?.data?.data?.totalNumberOfRecords);
          setChargingHistory(
            response?.data?.data?.response?.sort((a, b) => {
              return (
                new Date(b.startDate).getTime() -
                new Date(a.startDate).getTime()
              );
            })
          );
          setFetchingData(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        setChargingHistory([]);
        setFetchingData(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (fetchingData != true) {
      getCharginHistory(pageSize, currentPage);
    }
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (changeFlag != null) {
      setCurrentPage(0);
      setPageSize(MIN_PAGE_SIZE);
      getCharginHistory(MIN_PAGE_SIZE, 0);
    }
  }, [changeFlag]);

  const columns = useMemo(
    () => [
      {
        name: 'transactionId',
        label: t('LBLTranId'),
        options: {
          filter: true,
          sort: true,
          customHeadRender: () => (
            <TableCell
              variant="head"
              sx={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('LBLTranId')}
            </TableCell>
          ),
        },
      },
      {
        name: 'endCustomerId',
        label: t('searchTypeEndCustomerId'),
        options: {
          filter: true,
          sort: true,
          customHeadRender: () => (
            <TableCell
              variant="head"
              sx={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('searchTypeEndCustomerId')}
            </TableCell>
          ),
        },
      },
      {
        name: 'customerFullName',
        label: t('LBLUser'),
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return <Typography align="left">{value || ''}</Typography>;
          },
          customHeadRender: () => (
            <TableCell
              variant="head"
              sx={{
                textAlign: 'left',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('LBLUser')}
            </TableCell>
          ),
        },
      },
      {
        name: 'parkingLotAndAddress',
        label: t('LBLAddress'),
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            let valuedataParking = value?.parkingLot?.toString();
            let valuedata = value?.locationAddress;
            let parkingData = t('LBLParkingLotNum') + ': ' + valuedataParking;
            let cof = valuedata?.careOf ? `c/o ${valuedata?.careOf}, ` : '';
            let finalAddress =
              cof +
              valuedata?.street +
              ', ' +
              valuedata?.zipCode +
              ' ' +
              valuedata?.city;
            if (!value?.parkingLot) {
              if (valuedata) {
                return (
                  <Typography align="left">{finalAddress || ''}</Typography>
                );
              } else {
                return null;
              }
            } else {
              return valuedata
                ? `${parkingData}, ${finalAddress || ''}`
                : parkingData;
            }
          },
          customHeadRender: () => (
            <TableCell
              variant="head"
              sx={{
                textAlign: 'left',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('LBLAddress')}
            </TableCell>
          ),
        },
      },
      {
        name: 'authTypeAndRfIdTag',
        label: t('LBLAuth'),
        options: {
          filter: true,
          sort: true,
          customBodyRender(value) {
            if (!value) return null;
            let authType = value?.authType;
            if (authType !== 'APP') {
              let rfidSerialId = value?.rfidSerialId?.toString();
              let rfidTag = value?.rfidTag?.toString();

              let rfidDisplay =
                rfidSerialId && rfidSerialId.trim() !== ''
                  ? rfidSerialId
                  : rfidTag;
              return (
                <Typography align="left">
                  {authType} ({rfidDisplay})
                </Typography>
              );
            } else {
              return <Typography align="left">{authType}</Typography>;
            }
          },
          customHeadRender: () => (
            <TableCell
              align="center"
              variant="head"
              sx={{
                textAlign: 'left',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('LBLAuth')}
            </TableCell>
          ),
        },
      },
      {
        name: 'chargingRequestStatusMapping',
        label: t('LBLChargingStatus'),
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            if (!value) return null;
            return (
              <Typography align="center" color={value.colourCode}>
                {t(value.enumName)}
              </Typography>
            );
          },
          customHeadRender: () => (
            <TableCell
              variant="head"
              sx={{
                textAlign: 'center',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('LBLChargingStatus')}
            </TableCell>
          ),
        },
      },

      {
        name: 'timePeriod',
        label: t('lblPeriod'),
        options: {
          filter: true,
          sort: true,
          // eslint-disable-next-line no-unused-vars
          customBodyRender: (value) => {
            return (
              <>
                <TimePeriodLabel
                  align="center"
                  startDate={value.startDate}
                  endDate={value.endDate}
                  lastUpdateTimestamp={value.lastUpdateTimestamp}
                  status={value.status.toLowerCase()}
                />
              </>
            );
          },
          customHeadRender: () => (
            <TableCell
              align="center"
              variant="head"
              width={'180px'}
              sx={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('lblPeriod')}
            </TableCell>
          ),
        },
      },
      {
        name: 'endDate',
        label: t('lblPeriod'),
        options: {
          filter: true,
          sort: true,
          display: false,
          customHeadRender: () => (
            <TableCell
              align="right"
              variant="head"
              width={'180px'}
              sx={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('LBLEndDate')}
            </TableCell>
          ),
        },
      },
      {
        name: 'energyCharged',
        label: t('LBLAmountenergyCharged'),
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value) => {
            let valueData = formatDecimal(value);
            if (i18n.language === 'en') {
              valueData = valueData.replace(/,/g, '.');
            }
            return (
              <Typography align="right" paddingRight={2}>
                {value !== null ? valueData + ' kWh' : ''}
              </Typography>
            );
          },
          customHeadRender: () => (
            <TableCell
              align="right"
              variant="head"
              sx={{ position: 'sticky', top: 0, backgroundColor: 'white' }}
            >
              {t('LBLAmountenergyCharged')}
            </TableCell>
          ),
        },
      },
      {
        name: 'rfidTag',
        label: t('lblPeriod'),
        options: {
          display: false,
          filter: true,
          sort: true,
          // eslint-disable-next-line no-unused-vars
          customBodyRender: (value) => {
            return (
              <>
                <Typography align="center">{value}</Typography>
              </>
            );
          },
          customHeadRender: () => (
            <TableCell
              align="center"
              variant="head"
              width={'180px'}
              sx={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('lblPeriod')}
            </TableCell>
          ),
        },
      },
      {
        name: 'locationAddress',
        label: 'locationAddress',
        options: {
          filter: true,
          display: false,
          sort: true,
          customBodyRender: (value) => {
            return <Typography align="left">{value}</Typography>;
          },
          customHeadRender: () => (
            <TableCell
              variant="head"
              sx={{
                textAlign: 'left',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {'locationAddress'}
            </TableCell>
          ),
        },
      },
    ],
    [filterParam]
  );

  const renderChargingHistoryData = useMemo(() => {
    const rawChargingHistory = chargingHistory;
    return rawChargingHistory
      ?.sort((entry1, entry2) => entry2.startDate - entry1.startDate)
      .map((entry) => {
        const { ...rawChargingHistory } = entry;
        const newEntry = {
          ...rawChargingHistory,
          timePeriod: {
            startDate: rawChargingHistory.startDate,
            endDate: rawChargingHistory.endDate,
            lastUpdateTimestamp: rawChargingHistory.lastUpdateTimestamp,
            status: rawChargingHistory.status.toLowerCase(),
          },
          parkingLotAndAddress: {
            parkingLot: rawChargingHistory.parkingLot,
            locationAddress: rawChargingHistory?.locationAddress || '',
          },
          authTypeAndRfIdTag: {
            authType: rawChargingHistory.authType,
            rfidTag: rawChargingHistory.rfidTag,
            rfidSerialId: rawChargingHistory.rfidSerialId,
          },
        };
        return newEntry;
      });
  }, [chargingHistory]);

  return (
    <Paper>
      <>
        <LogoLoader loading={loading} />
        <Paper
          variant="outlined"
          sx={{
            width: '100%',
            '& .MuiTableCell-head:not(:nth-of-type(2)) button': {
              mx: 0,
            },
          }}
        >
          <DataTable
            data={renderChargingHistoryData}
            columns={columns}
            options={{
              textLabels: {
                body: {
                  noMatch: fetchingData ? t('LBLLOading') : t('LBLNoData'),
                },
              },
              currentPage: currentPage,
              totalRecords: totalRecords,
            }}
            getData={(seletedPageSize, currentPageVal) => {
              setCurrentPage(currentPageVal);
              setPageSize(seletedPageSize);
            }}
          />
        </Paper>
      </>
    </Paper>
  );
}
export default EnhancedTable;