import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import React from 'react';
import { contractManagement } from '../../../../services/utils/axios';
import { useAuth } from '../../../../services/contexts/AuthContext';
import {
  formatCurrency,
  formatDecimal,
} from '../../../../services/utils/format-numbers';
import { useSWRConfig } from 'swr';
import { useTranslation } from 'react-i18next';
import { PER_KWH, PER_MONTH } from '../../../../services/utils/units-constants';
import { GERMAN_DATE_FORMAT } from '../../../../services/utils/date-format-constants';
import { formatDate } from '../../../../services/utils/helpers';

/**
 * @param {PropTypes.InferProps<NutzerAnlegen.propTypes>} props
 */

function ConfirmUserTariffEdit({
  payload,
  openFeedback,
  submit,
  setError,
  setSubmittingTariff,
  setSubmit,
}) {
  const { token } = useAuth();
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();

  useEffect(() => {
    if (submit > 0) {
      updateUser();
    }
    setSubmit(0);
  }, [submit]);
  if (!payload) return null;
  const renderData = {
    fixTariff: formatCurrency(payload?.fixTariff) + PER_MONTH,
    varTariff: formatCurrency(payload?.varTariff) + PER_KWH,
    Bereitstellungsgebühr: `${formatDecimal(payload?.onboardingFee)} €`,
    Ladevorgangsgebühr: `${formatDecimal(payload?.fixTariffPerChargingRequest)} €/Ladevorgang`,
    newTariffDate: formatDate(payload.newTariffDate, GERMAN_DATE_FORMAT),
    newTariffCancellationDate: formatDate(
      payload.newTariffCancellationDate,
      GERMAN_DATE_FORMAT
    ),
  };
  const updateUser = async () => {
    try {
      setSubmittingTariff(true);
      const newPayload = payload;
      await contractManagement.put('/change-contract-tarrif', newPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      openFeedback();
      mutate(['/contracts/operator', token, 'contractManagement']);
    } catch (error) {
      setError(true);
    }
  };
  const renderKey = (key) => {
    switch (key) {
      case 'fixTariff':
        return t('fixTariff');
      case 'varTariff':
        return t('varTariff');
      case 'newTariffDate':
        return t('newTariffDate');
      case 'Ladevorgangsgebühr':
        return t('fixTariffPerChargingRequest');
      case 'Bereitstellungsgebühr':
        return t('onboardingFee');
      case 'newTariffCancellationDate':
        return t('newTariffCancellationDate');
      default:
        return key;
    }
  };
  return (
    <>
      {Object.entries(renderData).map(([key, value]) => (
        <Stack
          key={key}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          my={4}
        >
          {value ? (
            <>
              <Typography variant="body1">{renderKey(key)}</Typography>
              <Typography variant="body1" align="right" id={key}>
                {value}
              </Typography>
            </>
          ) : null}
        </Stack>
      ))}
    </>
  );
}
ConfirmUserTariffEdit.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any).isRequired,
  openFeedback: PropTypes.func.isRequired,
};
export default ConfirmUserTariffEdit;
