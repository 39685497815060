import 'dayjs/locale/de';
import PropTypes from 'prop-types';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import shadows from '@mui/material/styles/shadows';
import * as Yup from 'yup';
import { InsertDriveFile } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Radio from '@mui/material/Radio';
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { fileSizeInMB } from '../../../../services/utils/pdf-byte';
import LightTooltip from './LightTooltip';
import dayjs from 'dayjs';
import FormControlLabel from '@mui/material/FormControlLabel';
import { RadioGroup } from 'formik-mui';
import { useTranslation } from 'react-i18next';
/**
 * @param {PropTypes.InferProps<UserCreationFormContract.propTypes>} props
 */
const UserCreationFormContract = ({
  payload,
  setPayload,
  confirmPayload,
  submit,
  setSubmit,
  setCanNext,
  editFlag,
  pdfFlag,
  cpoPaymentPreference,
}) => {
  const { t } = useTranslation();
  const contractUrl = `${window._env_.REACT_APP_CONTRACT_MANGEMENT_API}/pdf/${payload?.contractKey}?verificationId=${payload?.contractId}`;
  const isPdfWithBilling = payload?.contractInfo.contractPDF;
  const [contract, setContract] = useState(null);
  const authType = 'APP';
  const [tooltipOpen, setTooltipOpen] = useState('');
  const [minStartDate, setMinStartDate] = useState('');
  const [minEndDate, setMinEndDate] = useState('');
  const [billingType, setBillingType] = useState('');
  const openEndDateTooltip = (name) => () => setEndDateTooltipOpen(name);
  const closeEndDateTooltip = () => setEndDateTooltipOpen('');
  const [endDateTooltipOpen, setEndDateTooltipOpen] = useState('');
  const userCreationFormContractRef = useRef();

  useEffect(() => {
    let billingType = null;
    if (payload?.billingType) {
      billingType = payload?.billingType;
    } else {
      if (
        cpoPaymentPreference?.includes('BANK_TRANSFER') ||
        cpoPaymentPreference?.includes('SEPA') ||
        cpoPaymentPreference?.includes('STRIPE_SEPA')
      ) {
        billingType = 'WITH_BILLING';
      } else {
        billingType = 'NO_BILLING';
      }
    }
    setBillingType(billingType);
  }, [cpoPaymentPreference]);
  useEffect(() => {
    if (submit > 0) document.getElementById('subTwo').click();
    setSubmit(0);
    let today = new Date();
    let yyyy = today.getFullYear();
    if (editFlag == true) {
      let dbDate = new Date(payload?.contractInfo?.startDate);
      if (today > dbDate && payload?.contractStatus === 'ACTIVE') {
        setMinStartDate(
          yyyy +
            dbDate.toLocaleString('en-US', { month: '2-digit' }) +
            dbDate.toLocaleString('en-US', { day: '2-digit' })
        );
      } else {
        setMinStartDate(
          yyyy +
            today.toLocaleString('en-US', { month: '2-digit' }) +
            today.toLocaleString('en-US', { day: '2-digit' })
        );
      }
    } else {
      setMinStartDate(
        yyyy +
          today.toLocaleString('en-US', { month: '2-digit' }) +
          today.toLocaleString('en-US', { day: '2-digit' })
      );
    }
    const date = new Date();
    const endDate = new Date(date.setDate(date.getDate() + 1));
    setMinEndDate(
      yyyy +
        endDate.toLocaleString('en-US', { month: '2-digit' }) +
        endDate.toLocaleString('en-US', { day: '2-digit' })
    );
  }, [submit, minStartDate]);

  const setEndDate = (value) => {
    if (value) {
      const date = new Date(value);
      const endDate = new Date(date.setDate(date.getDate() + 1));
      const yyyy = endDate.getFullYear();
      setMinEndDate(
        yyyy +
          endDate.toLocaleString('en-US', { month: '2-digit' }) +
          endDate.toLocaleString('en-US', { day: '2-digit' })
      );
    }
  };

  const noBilling =
    editFlag == true
      ? {
          billingType: payload?.billingType || 'NO_BILLING',
          authType: 'APP',
          contractPDF: '',
          fixTariff: '',
          varTariff: '',
          fixTariffPerChargingRequest:
            payload?.contractInfo?.fixTariffPerChargingRequest || 0,
          onboardingFee: payload?.contractInfo?.onboardingFee || 0,
          startDate: payload?.contractInfo?.startDate || '',
          endDate: payload?.contractInfo?.endDate || '',
        }
      : {
          billingType: payload?.billingType || 'NO_BILLING',
          authType: 'APP',
          contractPDF: '',
          fixTariff: payload?.contractInfo?.fixTariff || '',
          varTariff: payload?.contractInfo?.varTariff || '',
          fixTariffPerChargingRequest:
            payload?.contractInfo?.fixTariffPerChargingRequest || '',
          onboardingFee: payload?.contractInfo?.onboardingFee,
          startDate: payload?.contractInfo?.startDate || '',
          endDate: payload?.contractInfo?.endDate || '',
        };
  const billing =
    editFlag == true
      ? {
          billingType: payload?.billingType || 'WITH_BILLING',
          authType: 'APP',
          contractPDF: '',
          fixTariff: payload?.contractInfo?.fixTariff || 0,
          varTariff: payload?.contractInfo?.varTariff || 0,
          fixTariffPerChargingRequest:
            payload?.contractInfo?.fixTariffPerChargingRequest || 0,
          onboardingFee: payload?.contractInfo?.onboardingFee || 0,
          startDate: payload?.contractInfo?.startDate || '',
          endDate: payload?.contractInfo?.endDate || '',
        }
      : {
          billingType: payload?.billingType || 'WITH_BILLING',
          authType: 'APP',
          contractPDF: '',
          fixTariff: payload?.contractInfo?.fixTariff || '',
          varTariff: payload?.contractInfo?.varTariff || '',
          fixTariffPerChargingRequest:
            payload?.contractInfo?.fixTariffPerChargingRequest || '',
          onboardingFee: payload?.contractInfo?.onboardingFee,
          startDate: payload?.contractInfo?.startDate || '',
          endDate: payload?.contractInfo?.endDate || '',
        };

  const initialValues = cpoPaymentPreference
    ? payload?.billingType == 'NO_BILLING'
      ? noBilling
      : billing
    : noBilling;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        billingType: Yup.string().label(t('billingType')),
        contractPDF: Yup.mixed().label(t('currentcontract')),
        fixTariffPerChargingRequest: Yup.mixed().label(
          t('fixTariffPerChargingRequest')
        ),
        onboardingFee: Yup.mixed().label(t('onboardingFee')),
        fixTariff:
          billingType === 'WITH_BILLING'
            ? payload?.contractStatus === 'ACTIVE'
              ? Yup.number().label(t('usagetarrif'))
              : Yup.number().required(t('usagereq')).label(t('usagetarrif'))
            : Yup.number().label(t('usagetarrif')),
        varTariff:
          billingType === 'WITH_BILLING'
            ? payload?.contractStatus === 'ACTIVE'
              ? Yup.number().label(t('chargintarif'))
              : Yup.number()
                  .required(t('electtarrifreq'))
                  .label(t('chargintarif'))
            : Yup.number().label(t('chargintarif')),

        startDate:
          payload?.contractStatus === 'ACTIVE'
            ? Yup.date().label(t('LBLStartDate'))
            : Yup.date().required(t('LBLReqCockpit')),
        endDate: Yup.date().when('startDate', {
          is: (startDate) => {
            return !startDate || startDate.length === 0;
          },
          then: () =>
            Yup.date()
              .min(Yup.ref('startDate'), t('LBLEDdgStart'))
              .label(t('LBLEndDate'))
              .nullable(),
          otherwise: () =>
            Yup.date()
              .min(Yup.ref('startDate'), t('LBLENdgStart'))
              .label(t('LBLEndDate'))
              .nullable(),
        }),
      }),
    [billingType, authType]
  );

  /**
   * @method
   * @typedef {import("formik").FormikProps<typeof initialValues>} Props
   * @param {Props['setFieldError']} setFieldError
   * @param {Props['setFieldValue']} setFieldValue
   * @returns {(event: React.ChangeEvent<HTMLInputElement>) => void}
   */
  const handleBillingTypeChange =
    (handleChange, setFieldError, setFieldValue) => (event) => {
      setBillingType(event.target.value);
      handleChange(event);
    };
  const handleChooseFile = (setFieldError, setFieldValue) => (event) => {
    const file = event.target.files[0];

    if (!file) return setFieldValue('contractPDF', '');

    if (file.type !== 'application/pdf') {
      return setFieldError('contractPDF', t('pdfOnly'));
    }

    if (fileSizeInMB(file.size) > 3) {
      return setFieldError('contractPDF', t('3mb'));
    }

    setContract(file);
    setFieldValue('contractPDF', file.name);
  };

  /**
   * @method
   * @param {Props['values']} values
   * @param {Props['handleChange']} handleChange
   * @returns {(event: React.ChangeEvent<HTMLInputElement>) => void}
   */

  const openTooltip = (name) => () => setTooltipOpen(name);
  const closeTooltip = () => setTooltipOpen('');

  // eslint-disable-next-line no-unused-vars
  const handleChooseFileBlur = (setFieldTouched) => (event) => {
    setFieldTouched('contractPDF', true, true);
  };

  /** @type {import("formik").FormikConfig<typeof initialValues>['onSubmit']} */
  const handleSubmit = (values, actions) => {
    const noBilling = billingType === 'NO_BILLING';

    const payload = {
      billingType:
        cpoPaymentPreference == 'NO_PAYMENT_METHOD'
          ? 'NO_BILLING'
          : values.billingType, // can be "WITH_BILLING" or "NO_BILLING"
      contractInfo: {
        contractPDF: contract || null,
        fixTariff: noBilling ? '' : values.fixTariff,
        varTariff: noBilling ? '' : values.varTariff,
        fixTariffPerChargingRequest: noBilling
          ? ''
          : values.fixTariffPerChargingRequest,
        onboardingFee: values.onboardingFee,
        startDate: values.startDate,
        endDate: values.endDate === '' ? '' : values.endDate,
      },
    };
    setPayload(payload);
    confirmPayload();
    actions.setSubmitting(false);
    actions.setTouched({}, false);
    actions.setErrors({});
    actions.setFieldError({});
    actions.setFieldTouched({}, false, false);
    actions.setFieldValue('contractPDF', values.contractPDF);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={handleSubmit}
      innerRef={userCreationFormContractRef}
    >
      {({
        errors,
        touched,
        values,
        setFieldValue,
        setFieldError,
        setFieldTouched,
        handleChange,
        isSubmitting,
        isValid,
      }) => (
        <Form noValidate>
          <>
            {setCanNext(touched && isValid)}

            <Stack mb={5} direction="row" spacing={8}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                width="100%"
              >
                <Field
                  margin="none"
                  sx={{ width: '100%' }}
                  component={RadioGroup}
                  aria-labelledby="abrechnungsart-label"
                  name="billingType"
                  value={billingType}
                  onChange={handleBillingTypeChange(
                    handleChange,
                    setFieldError,
                    setFieldValue
                  )}
                >
                  <Typography
                    fontWeight={700}
                    variant="subtitle1"
                    id="abrechnungsart-label"
                  >
                    {t('billingType')}
                  </Typography>
                  <FormControlLabel
                    value="WITH_BILLING"
                    sx={{ width: 'fit-content' }}
                    control={
                      <Radio
                        disabled={
                          isSubmitting ||
                          !cpoPaymentPreference ||
                          cpoPaymentPreference == 'NO_PAYMENT_METHOD' ||
                          payload?.contractStatus === 'ACTIVE'
                        }
                      />
                    }
                    label={t('chargable')}
                    disabled={
                      isSubmitting ||
                      !cpoPaymentPreference ||
                      cpoPaymentPreference == 'NO_PAYMENT_METHOD' ||
                      payload?.contractStatus === 'ACTIVE'
                    }
                  />
                  <FormControlLabel
                    value="NO_BILLING"
                    sx={{ width: 'fit-content' }}
                    control={
                      <Radio
                        disabled={
                          isSubmitting || payload?.contractStatus === 'ACTIVE'
                        }
                      />
                    }
                    label={t('free')}
                    disabled={
                      isSubmitting || payload?.contractStatus === 'ACTIVE'
                    }
                  />
                </Field>

                {editFlag == true ? (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="de"
                  >
                    <DatePicker
                      disabled={payload?.contractStatus === 'ACTIVE'}
                      label={t('LBLStartDate')}
                      minDate={dayjs(minStartDate)}
                      PaperProps={{ sx: { boxShadow: shadows[3] } }}
                      value={values.startDate}
                      id={'startDate'}
                      onChange={(newValue) => {
                        setEndDate(newValue);
                        setFieldValue(
                          'startDate',
                          newValue ? newValue.toDate() : ''
                        );
                      }}
                      renderInput={(params) => (
                        <Field
                          component={TextField}
                          {...params}
                          required={payload?.contractStatus != 'ACTIVE'}
                          name="startDate"
                          margin="none"
                          autoComplete="off"
                          helperText={touched.startDate && errors.startDate}
                          error={errors.startDate && touched.startDate}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: 'TT.MM.JJJJ',
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                ) : (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="de"
                  >
                    <DatePicker
                      disabled={payload?.contractStatus === 'ACTIVE'}
                      label={t('LBLStartDate')}
                      minDate={dayjs(minStartDate)}
                      PaperProps={{ sx: { boxShadow: shadows[3] } }}
                      value={values.startDate}
                      id={'startDate'}
                      onChange={(newValue) => {
                        setEndDate(newValue);
                        setFieldValue(
                          'startDate',
                          newValue ? newValue.toDate() : ''
                        );
                      }}
                      renderInput={(params) => (
                        <Field
                          component={TextField}
                          {...params}
                          required={payload?.contractStatus != 'ACTIVE'}
                          name="startDate"
                          margin="none"
                          autoComplete="off"
                          helperText={touched.startDate && errors.startDate}
                          error={errors.startDate && touched.startDate}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: 'TT.MM.JJJJ',
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                )}
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="de"
                >
                  <DatePicker
                    minDate={dayjs(minEndDate)}
                    PaperProps={{ sx: { boxShadow: shadows[3] } }}
                    label={t('LBLEndDate')}
                    value={values.endDate}
                    onChange={(newValue) => {
                      setFieldValue(
                        'endDate',
                        newValue ? newValue.toDate() : null
                      );
                    }}
                    renderInput={(params) => (
                      <Field
                        component={TextField}
                        {...params}
                        name="endDate"
                        margin="none"
                        autoComplete="off"
                        helperText={touched.endDate && errors.endDate}
                        error={errors.endDate && touched.endDate}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'TT.MM.JJJJ',
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
              <Stack
                direction="row"
                alignItems="flex-start"
                style={{ marginTop: '35px' }}
              >
                <LightTooltip
                  open={endDateTooltipOpen === 'endDateToolTip'}
                  onOpen={openEndDateTooltip('endDateToolTip')}
                  onClose={closeEndDateTooltip}
                  placement="top-start"
                  title={t('pleaseempty')}
                >
                  <InfoIcon
                    color="action"
                    fontSize="small"
                    sx={{
                      position: 'absolute',
                      display: 'flex',
                      ml: -6,
                      mt: 2,
                    }}
                  />
                </LightTooltip>
              </Stack>
            </Stack>
            <Fade
              in={
                payload?.billingType === 'WITH_BILLING' &&
                values?.billingType === 'WITH_BILLING'
              }
            >
              {pdfFlag == true ? (
                <Stack mb={2} direction="row" alignItems="center" spacing={5}>
                  <Typography
                    fullWidth
                    align="left"
                    color={isPdfWithBilling && 'blue'}
                    fontWeight={500}
                  >
                    {t('currentcontract')}
                  </Typography>
                  <Typography
                    fullWidth
                    align="left"
                    color={isPdfWithBilling && 'blue'}
                    fontWeight="fontWeightRegular"
                    label={t('currentcontract')}
                  >
                    {
                      <IconButton
                        disabled={payload?.contractStatus === 'ACTIVE'}
                        component={'a'}
                        target="_blank"
                        href={contractUrl}
                        color="primary"
                      >
                        <InsertDriveFile />
                      </IconButton>
                    }
                  </Typography>
                </Stack>
              ) : (
                <div></div>
              )}
            </Fade>
            <Stack direction="row" width="48%" spacing={8}>
              <Fade in={billingType === 'WITH_BILLING'}>
                <Box width="100%">
                  <LightTooltip
                    open={tooltipOpen === 'billingType'}
                    onOpen={openTooltip('billingType')}
                    onClose={closeTooltip}
                    placement="top-start"
                    title={t('3mb')}
                  >
                    <InfoIcon
                      color="action"
                      fontSize="small"
                      sx={{ position: 'absolute', ml: -6, mt: 2 }}
                    />
                  </LightTooltip>
                  <Field
                    disabled={payload?.contractStatus === 'ACTIVE'}
                    error={Boolean(touched.contractPDF && errors.contractPDF)}
                    helperText={touched.contractPDF && errors.contractPDF}
                    inputProps={{ accept: 'application/pdf' }}
                    onBlur={handleChooseFileBlur(setFieldTouched)}
                    onChange={handleChooseFile(setFieldError, setFieldValue)}
                    fullWidth
                    type="file"
                    margin="none"
                    component={TextField}
                    InputLabelProps={{ shrink: true }}
                    placeholder="Keine Datei ausgewählt"
                    id="nutzungs-und-ladestrom-vertrag"
                    name="contract"
                    label={t('currentcontract')}
                  />
                </Box>
              </Fade>
            </Stack>

            <Fade in={billingType === 'WITH_BILLING'}>
              <Stack mb={5} direction="row" spacing={10}>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 10,
                  }}
                >
                  {t('uploadconsent')}
                </Typography>
                <Box position="relative" width="70%">
                  {' '}
                </Box>
              </Stack>
            </Fade>

            <Collapse
              in={billingType === 'WITH_BILLING'}
              mountOnEnter
              unmountOnExit
            >
              <Stack mb={5} direction="row" spacing={8}>
                <Field
                  required={billingType === 'WITH_BILLING'}
                  fullWidth
                  component={TextField}
                  disabled={payload?.contractStatus === 'ACTIVE'}
                  id="nutzungstarif"
                  name="fixTariff"
                  margin="none"
                  type="number"
                  label={t('tarif')}
                  inputProps={{ min: 0, step: 0.01 }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€/Monat</InputAdornment>
                    ),
                  }}
                />
                <Field
                  required={billingType === 'WITH_BILLING'}
                  disabled={payload?.contractStatus === 'ACTIVE'}
                  fullWidth
                  component={TextField}
                  id="ladestromtarif"
                  name="varTariff"
                  type="number"
                  label={t('chargintarif')}
                  inputProps={{ min: 0, step: 0.01 }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€/kWh</InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <Stack mb={5} direction="row" spacing={8}>
                <Field
                  fullWidth
                  component={TextField}
                  disabled={payload?.contractStatus === 'ACTIVE'}
                  id="onboardingFee"
                  name="onboardingFee"
                  margin="none"
                  type="number"
                  label={t('onboardingFee')}
                  inputProps={{ min: 0, step: 0.01 }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">€</InputAdornment>
                    ),
                  }}
                />
                <Field
                  fullWidth
                  component={TextField}
                  disabled={payload?.contractStatus === 'ACTIVE'}
                  id="fixTariffPerChargingRequest"
                  name="fixTariffPerChargingRequest"
                  type="number"
                  label={t('fixTariffPerChargingRequest')}
                  inputProps={{ min: 0, step: 0.01 }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {t('charginrequestUnit')}
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Collapse>

            <Button sx={{ display: 'none' }} id="subTwo" type="submit"></Button>
          </>
        </Form>
      )}
    </Formik>
  );
};

UserCreationFormContract.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any),
  setPayload: PropTypes.func.isRequired,
  confirmPayload: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UserCreationFormContract;