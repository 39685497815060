import React from 'react';
import { useRoutes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { create } from 'jss';
import { ThemeProvider } from 'styled-components/macro';

import { StyledEngineProvider } from '@mui/styled-engine-sc';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import StylesProvider from '@mui/styles/StylesProvider';
import jssPreset from '@mui/styles/jssPreset';
import { SWRConfig } from 'swr';

import './i18n';
import createTheme from './theme';
import routes from './routes';

import useTheme from './services/hooks/useTheme';
import { store } from './redux/store';

import './App.css';
import UserProvider from './services/contexts/UserContext';
import AuthProvider from './services/contexts/AuthContext';
import { fetcher } from './services/utils/fetcher';
// import { AuthProvider } from "./contexts/JWTContext";
// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point'),
});

function App() {
  const content = useRoutes(routes);

  const { theme } = useTheme();

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="OLI Move Dashboard"
        defaultTitle="OLI Move Dashboard"
      />
      <Provider store={store}>
        <StylesProvider jss={jss}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={createTheme(theme)}>
                <ThemeProvider theme={createTheme(theme)}>
                  <SWRConfig
                    value={{
                      fetcher,
                      revalidateOnFocus: false,
                      revalidateIfStale: false,
                      onErrorRetry: (
                        error,
                        _key,
                        _config,
                        revalidate,
                        { retryCount }
                      ) => {
                        // Only retry if error is a system/network error.
                        if (error.status < 500) return;
                        if (retryCount >= 10) return;

                        setTimeout(() => revalidate({ retryCount }), 3000);
                      },
                    }}
                  >
                    <AuthProvider>
                      <UserProvider>{content}</UserProvider>
                    </AuthProvider>
                  </SWRConfig>
                </ThemeProvider>
              </MuiThemeProvider>
            </StyledEngineProvider>
          </LocalizationProvider>
        </StylesProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;