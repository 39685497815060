import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

const TimePeriodLabel = ({
  startDate,
  endDate,
  lastUpdateTimestamp,
  status,
  align,
}) => {
  const { t } = useTranslation();

  function getEndDateValue() {
    let outputText = endDate;
    let emptyStatuses = [
      'processing',
      'accepted',
      'connected',
      'denied',
      'denied_parking_timer',
      'terminated_not_started',
      'termination_requested',
      'terminated_rejected',
      'broken',
    ];
    let chargingStatuses = ['charging', 'paused', 'terminated_request'];
    if (emptyStatuses.includes(status?.toLowerCase())) {
      // here show blank end date
      outputText = format(new Date(startDate), 'dd.MM.yyyy, HH:mm') + ' Uhr';
    } else if (chargingStatuses.includes(status?.toLowerCase())) {
      // here show charging label as  end date
      outputText =
        format(new Date(startDate), 'dd.MM.yyyy, HH:mm') +
        ' - ' +
        t('statusCharging');
    } else {
      // return original date
      outputText =
        endDate != null
          ? format(new Date(startDate), 'dd.MM.yyyy, HH:mm') +
            ' - ' +
            format(new Date(endDate), 'dd.MM.yyyy, HH:mm') +
            ' Uhr'
          : format(new Date(startDate), 'dd.MM.yyyy, HH:mm') + ' Uhr';
    }

    return outputText;
  }
  if (!align) align = 'center';
  return <Typography align={align}>{getEndDateValue()}</Typography>;
};

TimePeriodLabel.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  lastUpdateTimestamp: PropTypes.string,
  status: PropTypes.string.isRequired,
};
export default TimePeriodLabel;
