export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

// convert base64ToArrayBuffer
export const base64ToArrayBuffer = (base64) => {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
};

// convert buffer to pdf file
export const arrayBufferToBlob = (buffer) => {
  return new Blob([buffer], { type: 'application/pdf' });
};

/**
 * @function
 * @param {number} number - file size in bytes
 * @returns {number} file size in megabytes
 */
export function fileSizeInMB(number) {
  return number / 1048576;
}
