import React, { useMemo, useState, useEffect } from 'react';
import MuiPaper from '@mui/material/Paper';
import styled from 'styled-components/macro';
import { spacing } from '@mui/system';
import {
  formatCurrency,
  formatDecimal,
} from '../../services/utils/format-numbers';
import { Typography, TableCell } from '@mui/material';
import DataTable from '../../components/DataTable';
import { useAuth } from '../../services/contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { PAGE_SIZE } from '../../services/utils/units-constants';
import api from '../../Middleware/api/api';
import LogoLoader from '../../components/LogoLoader';
import TimePeriodLabel from '../../components/TimePeriodLabel';
const Paper = styled(MuiPaper)(spacing);

function EnhancedTable({ startDate, endDate, contractKey, changeFlag }) {
  const { t, i18n } = useTranslation();
  const { token } = useAuth();

  const [fetchingData, setFetchingData] = useState(true);
  const [chargingHistory, setChargingHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE[0]);
  const [loading, setLoading] = useState(false);
  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date);
  };

  const params = useMemo(() => {
    const urlParams = new URLSearchParams();
    if (isValidDate(startDate) && isValidDate(endDate)) {
      urlParams.set('startDate', startDate);
      urlParams.set('endDate', endDate);
      urlParams.set('contractKey', contractKey);
    }
    return urlParams;
  }, [endDate, startDate]);

  const getCharginHistory = async () => {
    setFetchingData(true);
    setLoading(true);
    api
      .fethcChargingHistory(token, params, currentPage, pageSize)
      .then((response) => {
        if (response.data.data == null) {
          setChargingHistory([]);
          setLoading(false);
        } else {
          setTotalRecords(response?.data?.data?.totalNumberOfRecords);
          setChargingHistory(
            response.data?.data?.response?.sort((a, b) => {
              return (
                new Date(b.startDate).getTime() -
                new Date(a.startDate).getTime()
              );
            })
          );
          setFetchingData(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        setChargingHistory([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    getCharginHistory(pageSize, currentPage);
  }, [currentPage, pageSize, changeFlag]);

  const columns = useMemo(
    () => [
      {
        name: 'transactionId',
        label: t('LBLTranId'),
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'parkingLotAndAddress',
        label: t('LBLAddress'),
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            let valuedataParking = value?.parkingLot?.toString();
            let valuedata = value?.locationAddress;
            let parkingData = t('LBLParkingLotNum') + ': ' + valuedataParking;
            let cof = valuedata?.careOf ? `c/o ${valuedata?.careOf}, ` : '';
            let finalAddress =
              cof +
              valuedata?.street +
              ', ' +
              valuedata?.zipCode +
              ' ' +
              valuedata?.city;
            if (!value?.parkingLot) {
              if (valuedata) {
                return (
                  <Typography align="left">{finalAddress || ''}</Typography>
                );
              } else {
                return null;
              }
            } else {
              return valuedata
                ? `${parkingData}, ${finalAddress || ''}`
                : parkingData;
            }
          },
          customHeadRender: () => (
            <TableCell
              variant="head"
              sx={{
                textAlign: 'left',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('LBLAddress')}
            </TableCell>
          ),
        },
      },
      {
        name: 'authTypeAndRfIdTag',
        label: t('LBLAuth'),
        options: {
          filter: true,
          sort: true,
          customBodyRender(value) {
            if (!value) return null;
            let authType = value?.authType;
            if (authType !== 'APP') {
              let rfidSerialId = value?.rfidSerialId?.toString();
              let rfidTag = value?.rfidTag?.toString();

              let rfidDisplay =
                rfidSerialId && rfidSerialId.trim() !== ''
                  ? rfidSerialId
                  : rfidTag;

              return (
                <Typography align="left">
                  {authType} ({rfidDisplay})
                </Typography>
              );
            } else {
              return <Typography align="left">{authType}</Typography>;
            }
          },
          customHeadRender: () => (
            <TableCell
              align="center"
              variant="head"
              sx={{
                textAlign: 'left',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('LBLAuth')}
            </TableCell>
          ),
        },
      },

      {
        name: 'chargingRequestStatusMapping',
        label: t('LBLChargingStatus'),
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            if (!value) return null;
            return (
              <Typography align="center" color={value.colourCode}>
                {t(value.enumName)}
              </Typography>
            );
          },
          customHeadRender: () => (
            <TableCell
              variant="head"
              sx={{
                textAlign: 'center',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('LBLChargingStatus')}
            </TableCell>
          ),
        },
      },

      {
        name: 'timePeriod',
        label: t('lblPeriod'),
        options: {
          filter: true,
          sort: true,
          // eslint-disable-next-line no-unused-vars
          customBodyRender: (value) => {
            return (
              <>
                <TimePeriodLabel
                  align="center"
                  startDate={value.startDate}
                  endDate={value.endDate}
                  lastUpdateTimestamp={value.lastUpdateTimestamp}
                  status={value.status.toLowerCase()}
                />
              </>
            );
          },
          customHeadRender: () => (
            <TableCell
              align="center"
              variant="head"
              width={'180px'}
              sx={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('lblPeriod')}
            </TableCell>
          ),
        },
      },
      {
        name: 'endDate',
        label: t('lblPeriod'),
        options: {
          filter: true,
          sort: true,
          display: false,
        },
      },
      {
        name: 'energyCharged',
        label: t('LBLAmountCharged'),
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value) => {
            let valueData = formatDecimal(value);
            if (i18n.language === 'en') {
              valueData = valueData.replace(/,/g, '.');
            }
            return (
              <Typography align="right" paddingRight={2}>
                {value !== null ? valueData + ' kWh' : ''}
              </Typography>
            );
          },
          customHeadRender: () => (
            <TableCell
              align="right"
              variant="head"
              sx={{ position: 'sticky', top: 0, backgroundColor: 'white' }}
            >
              {t('LBLAmountCharged')}
            </TableCell>
          ),
        },
      },
      {
        name: 'sessionCost',
        label: t('LBLSales'),
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value) => {
            let valueData = formatCurrency(value);
            if (i18n.language === 'en') {
              valueData = valueData.replace(/,/g, '.');
            }
            return (
              <Typography align="right" paddingRight={2}>
                {' '}
                {value !== null ? valueData : ''}{' '}
              </Typography>
            );
          },
          customHeadRender: () => (
            <TableCell
              align="right"
              variant="head"
              sx={{
                textAlign: 'right',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('LBLSales')}
            </TableCell>
          ),
        },
      },
      {
        name: 'locationAddress',
        label: 'locationAddress',
        options: {
          filter: true,
          display: false,
          sort: true,
          customBodyRender: (value) => {
            return <Typography align="left">{value}</Typography>;
          },
          customHeadRender: () => (
            <TableCell
              variant="head"
              sx={{
                textAlign: 'left',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {'locationAddress'}
            </TableCell>
          ),
        },
      },
    ],
    []
  );

  const renderChargingHistoryData = useMemo(() => {
    const rawChargingHistory = chargingHistory;
    return rawChargingHistory
      ?.sort((entry1, entry2) => entry2.startDate - entry1.startDate)
      .map((entry) => {
        const { ...rawChargingHistory } = entry;
        const newEntry = {
          ...rawChargingHistory,
          timePeriod: {
            startDate: rawChargingHistory.startDate,
            endDate: rawChargingHistory.endDate,
            lastUpdateTimestamp: rawChargingHistory.lastUpdateTimestamp,
            status: rawChargingHistory.status.toLowerCase(),
          },
          parkingLotAndAddress: {
            parkingLot: rawChargingHistory.parkingLot,
            locationAddress: rawChargingHistory.locationAddress,
          },
          authTypeAndRfIdTag: {
            authType: rawChargingHistory.authType,
            rfidTag: rawChargingHistory.rfidTag,
            rfidSerialId: rawChargingHistory.rfidSerialId,
          },
        };
        return newEntry;
      });
  }, [chargingHistory]);

  return (
    <Paper>
      <>
        <LogoLoader loading={loading} />
        <DataTable
          title={t('LBLLoadingHostory')}
          data={renderChargingHistoryData}
          columns={columns}
          options={{
            textLabels: {
              body: {
                noMatch: fetchingData ? t('LBLLOading') : t('LBLNoData'),
              },
            },
            currentPage: currentPage,
            totalRecords: totalRecords,
          }}
          getData={(seletedPageSize, currentPageVal) => {
            setCurrentPage(currentPageVal);
            setPageSize(seletedPageSize);
          }}
        />
      </>
    </Paper>
  );
}

export default EnhancedTable;