import React, {
  useCallback,
  Suspense,
  lazy,
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  Grid,
  MenuItem,
  Select,
  Button,
  Stack,
  TextField,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../services/contexts';
import api from '../../Middleware/api/api';
import UserComponent from './UserComponent';
import LocationInfoComponent from './LocationInfoComponent';
import PaymentInfoComponent from './PaymentInfoComponent';
import ContractComponent from './ContractComponent';
import { EnhancedTable } from './Table';
import { useParams } from 'react-router-dom';
import DetailsWrapper from '../../layouts/DetailsWrapper';
import EditUserInformation from './EditUserInformation';
import EvStationIcon from '@mui/icons-material/EvStation';
import EuroIcon from '@mui/icons-material/Euro';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import shadows from '@mui/material/styles/shadows';
import dayjs from 'dayjs';
import Table from './TableCharging';
import moment from 'moment';
export default function NutzerDetail() {
  const { t, i18n } = useTranslation();
  function calculatedates(days) {
    let date = new Date();
    let last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
    return (
      last.getFullYear() +
      '-' +
      last.toLocaleString('en-US', { month: '2-digit' }) +
      '-' +
      last.toLocaleString('en-US', { day: '2-digit' })
    );
  }
  const validationSchema = useMemo(
    () =>
      Yup.object({
        startDate: Yup.date()
          .typeError(t('LBLInvalidDate'))
          .label(t('LBLStartDate'))
          .required(t('LBLReqCockpit')),
        endDate: Yup.date()
          .typeError(t('LBLInvalidDate'))
          .min(Yup.ref('startDate'), t('LBLENdgStart'))
          .label(t('LBLEndDate'))
          .required(t('LBLEndDateReq')),
      }),
    [t, i18n.language]
  );

  const [startDate, setStartDate] = useState(calculatedates(7));
  const [endDate, setEndDate] = useState(calculatedates(0));
  const [changeFlag, setChangeFlag] = useState(Date.now());
  const initialValues = {
    startDate: startDate,
    endDate: endDate,
  };
  const [isPreview, setIsPreview] = useState(null);
  const [error, setError] = useState(false);
  const [errorLabel, setErrorLabel] = useState(false);
  const [successLabel, setSuccessLabel] = useState(false);
  // added two labels as new state variables to handle success and error on api call
  const { customerId } = useParams();
  const { token } = useAuth();
  const [contract, setContract] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refreshTheData, setRefreshTheData] = useState(0);
  const maxOffsetForYear = 1;
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const [error204, setError204] = useState(false);
  const exportType = 'CSV';
  const listOfYears = [];
  for (let x = 0; x <= maxOffsetForYear; x++) {
    let yearToAdd = currentYear - x;
    let contractyear = parseInt(
      moment(contract?.contractInfo?.startDate)?.format('YYYY')
    );
    if (parseInt(yearToAdd) >= parseInt(contractyear)) {
      listOfYears.push(currentYear - x);
    }
  }
  const Feedback = lazy(() => import('../../components/Feedback'));
  const FailFeedback = lazy(
    () => import('../../components/DataTable/Feedback')
  );

  const handleStartDateChange = (newValue, setFieldValue) => {
    if (
      newValue instanceof Date &&
      (!isNaN(newValue.getTime()) || !isNaN(newValue.toDate()))
    ) {
      setStartDate(format(newValue, 'yyyy-MM-dd'));
      setFieldValue('startDate', format(newValue, 'yyyy-MM-dd'));
    } else if (newValue != null && !isNaN(newValue.toDate())) {
      setStartDate(format(newValue.toDate(), 'yyyy-MM-dd'));
      setFieldValue('startDate', format(newValue.toDate(), 'yyyy-MM-dd'));
    } else {
      setStartDate(newValue);
      setFieldValue('startDate', newValue);
      console.error('Invalid StartDate input');
    }
  };

  const handleEndDateChange = (newValue, setFieldValue) => {
    if (
      newValue instanceof Date &&
      (!isNaN(newValue.getTime()) || !isNaN(newValue.toDate()))
    ) {
      setEndDate(format(newValue, 'yyyy-MM-dd'));
      setFieldValue('endDate', format(newValue, 'yyyy-MM-dd'));
    } else if (newValue != null && !isNaN(newValue.toDate())) {
      setEndDate(format(newValue.toDate(), 'yyyy-MM-dd'));
      setFieldValue('endDate', format(newValue.toDate(), 'yyyy-MM-dd'));
    } else {
      setEndDate(newValue);
      setFieldValue('endDate', newValue);
      console.error('Invalid EndDate input');
    }
  };
  const closeFeedback = () => {
    setErrorLabel(false);
    setSuccessLabel(false);
  };
  const enablePreveiw = () => {
    setIsPreview(true);
  };
  const disablePreveiw = () => {
    setIsPreview(false);
  };
  const onChangeYear = (event) => {
    const selectedYear = event.target.value;
    setYear(selectedYear);
  };
  const getContractByCustomerId = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.fetchContractByCustomerId(token, customerId);
      if (response?.data.data) {
        setContract(response.data.data[0]);
        enablePreveiw();
      } else {
        setContract({});
      }
    } catch (exception) {
      setLoading(false);
      setError(true);
    }
    setLoading(false);
  }, [token, customerId]);

  useEffect(() => {
    getContractByCustomerId();
  }, [errorLabel, refreshTheData]);
  const handleSubmit = (values, actions) => {
    setStartDate(values.startDate);
    setEndDate(values.endDate);
    setChangeFlag(new Date());
    actions.setSubmitting(false);
  };
  //FETCHING chagrging history on the basis of contract key
  const fetchChargingHistoryCSV = (values) => {
    setLoading(true);
    api
      .fetchChargingHistoryCSV(
        values.startDate,
        values.endDate,
        contract?.contractKey.toString(),
        exportType,
        token
      )
      .then((response) => {
        if (response.status == 204) {
          setError204(true);
          setLoading(false);
        } else if (response.status == 200) {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              'Ladehistorie' + format(new Date(endDate), 'yyyyMMdd') + '.csv'
            );
            document.body.appendChild(link);
            link.click();
            link.remove();
            setLoading(false);
          }
        } else {
          setError(true);
          setLoading(false);
        }
      })
      .catch((reason) => {
        setLoading(false);
        if (reason.response.status === 404) {
          setError204(true);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  };

  return (
    <>
      <DetailsWrapper
        title={t('LBLUserMgmt')}
        loading={loading}
        helmet="invoices"
        backButtonConfig={{ label: t('lblBacktoview'), url: '/nutzer' }}
        contract={
          <EditUserInformation
            contract={contract}
            setRefreshTheData={setRefreshTheData}
          />
        }
      >
        <Grid container direction="row" columnSpacing={1}>
          <Grid item xs={6}>
            <UserComponent
              contract={contract}
              setErrorLabel={setErrorLabel}
              setSuccessLabel={setSuccessLabel}
            />
          </Grid>
          <Grid item xs={6}>
            <ContractComponent contract={contract} />
          </Grid>
          <Grid item xs={6}>
            <LocationInfoComponent
              contract={contract}
              setErrorLabel={setErrorLabel}
              setSuccessLabel={setSuccessLabel}
            />
          </Grid>
          {contract?.billingType === 'WITH_BILLING' ? (
            <Grid item xs={6}>
              <PaymentInfoComponent contract={contract} />
            </Grid>
          ) : null}
        </Grid>
        <div style={{ margin: '10px' }}>
          <Button
            style={{ width: '70px', height: '35px' }}
            variant="contained"
            color={
              isPreview === true ||
              isPreview === undefined ||
              isPreview === null
                ? 'primary'
                : 'inherit'
            }
            onClick={enablePreveiw}
          >
            <EvStationIcon />
          </Button>
          <Button
            style={{ width: '70px', height: '35px' }}
            variant="contained"
            color={
              isPreview === true ||
              isPreview === undefined ||
              isPreview === null
                ? 'inherit'
                : 'primary'
            }
            onClick={disablePreveiw}
          >
            <EuroIcon />
          </Button>
        </div>
        {isPreview === false ? (
          <div>
            <Grid
              item
              xs={12}
              xl={3}
              py={6}
              display="flex"
              justifyContent="flex-end"
            >
              <Select
                style={{ background: `rgba(135,168,79)`, color: 'white' }}
                onChange={onChangeYear}
                labelId="label"
                id="select"
                value={year}
              >
                {listOfYears.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                {isPreview === false ? (
                  <EnhancedTable
                    selectedYear={year}
                    billingType={contract?.billingType}
                    contractKey={contract?.contractKey}
                  />
                ) : null}
              </Grid>
            </Grid>
          </div>
        ) : isPreview === true ? (
          <div>
            <Grid
              item
              xs={12}
              xl={3}
              py={1}
              display="flex"
              justifyContent="flex-end"
            >
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnMount={true}
                onSubmit={handleSubmit}
              >
                {({ errors, touched, values, setFieldValue }) => (
                  <Form noValidate>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems="center"
                      width="100%"
                    >
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="de"
                      >
                        <DatePicker
                          label={t('LBLStartDate')}
                          PaperProps={{ sx: { boxShadow: shadows[3] } }}
                          value={values.startDate}
                          onChange={(newValue) =>
                            handleStartDateChange(newValue, setFieldValue)
                          }
                          renderInput={(params) => (
                            <Field
                              id="startDate"
                              component={TextField}
                              {...params}
                              required
                              name="startDate"
                              margin="none"
                              autoComplete="off"
                              helperText={errors.startDate}
                              error={errors.startDate}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: 'TT.MM.JJJJ',
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>

                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="de"
                      >
                        <DatePicker
                          PaperProps={{ sx: { boxShadow: shadows[3] } }}
                          label={t('LBLEndDate')}
                          value={values.endDate}
                          onChange={(newValue) => {
                            handleEndDateChange(newValue, setFieldValue);
                          }}
                          maxDate={dayjs()}
                          renderInput={(params) => (
                            <Field
                              component={TextField}
                              {...params}
                              name="endDate"
                              margin="none"
                              autoComplete="off"
                              helperText={errors.endDate}
                              error={errors.endDate}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: 'TT.MM.JJJJ',
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>

                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={errors.startDate || errors.endDate}
                      >
                        {t('BTNSubmit')}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => fetchChargingHistoryCSV(values)}
                        disabled={errors.startDate || errors.endDate}
                      >
                        {t('lblDnldAsCSV')}
                      </Button>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {/* // chargin history */}
                <Table
                  startDate={startDate}
                  endDate={endDate}
                  contractKey={contract?.contractKey}
                  changeFlag={changeFlag}
                />
              </Grid>
            </Grid>
          </div>
        ) : null}
      </DetailsWrapper>
      <Suspense fallback={<div />}>
        <Feedback
          open={successLabel}
          title={t('invitemailSent')}
          message={
            <>
              <Typography align="center">{t('lblMailsent')}</Typography>
            </>
          }
          handleClose={closeFeedback}
        />
        <FailFeedback
          open={errorLabel}
          title={'Fehler'}
          severity="warning"
          message={
            <>
              <Typography align="center">
                {t('LBLSomethingwentWrong')}
              </Typography>
            </>
          }
          handleClose={() => {
            closeFeedback();
          }}
        />

        <FailFeedback
          open={error}
          title={'Fehler'}
          severity="warning"
          message={
            <>
              <Typography align="center">
                {t('LBLSomethingwentWrong')}
              </Typography>
            </>
          }
          handleClose={() => {
            setError(false);
            setError204(false);
          }}
        />
        <FailFeedback
          open={error204}
          title={t('noinfoFound')}
          severity="warning"
          message={
            <>
              <Typography align="center">{t('LBLNoDataDC')}</Typography>
            </>
          }
          handleClose={() => {
            setError(false);
            setError204(false);
          }}
        />
      </Suspense>
    </>
  );
}