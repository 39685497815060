import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { WarningRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

/**
 * @param {PropTypes.InferProps<Feedback.propTypes>} props
 */
const Feedback = ({ open, title, message, severity, handleClose }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { py: 5, px: 8 } }}
    >
      {title && (
        <DialogTitle
          sx={{
            textAlign: 'center',
            fontWeight: '600',
            fontSize: 'x-large',
            pb: 8,
          }}
        >
          {title}
        </DialogTitle>
      )}
      {severity === 'warning' && (
        <WarningRounded
          color={severity}
          sx={{ display: 'flex', mx: 'auto', fontSize: 'xxx-large' }}
        />
      )}
      <DialogContent sx={{ textAlign: 'center', py: 8 }}>
        {message}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
        }}
      >
        <Button variant="contained" sx={{ px: 12 }} onClick={handleClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Feedback.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.node.isRequired,
  severity: PropTypes.oneOf(['error', 'info', 'warning', 'success']),
  handleClose: PropTypes.func.isRequired,
};

Feedback.defaultProps = {
  severity: 'info',
};

export default Feedback;