import React, { useState, useEffect } from 'react';
import LogoLoader from '../../components/LogoLoader';
import { Button, Typography, Menu, MenuItem } from '@mui/material';
import shadows from '@mui/material/styles/shadows';
import StepperPopup from '../../components/StepperPopup';
import { ArrowForward } from '@mui/icons-material';
import ConfirmOperatorRechnung from './operatorRenchnung/ConfirmOperatorRechnung';
import UpdateOperatorRechnung from './operatorRenchnung/UpdateOperatorRechnung';
import Feedback from '../../components/Feedback';
import Feedback_Error from '../../components/DataTable/Feedback';
import OperatorForm from '../Operators/components/OperatorForm';
import ConfirmAddressUpdate from './operatorAddress/ConfirmAddressUpdate';
import { useTranslation } from 'react-i18next';

function EditOperatorInformation({ operatorInfo, setRefreshTheData }) {
  const { t } = useTranslation();
  const [updateBetreiberForm, setUpdateBetreiberForm] = useState(false);
  const [updateRechnungForm, setUpdateRechnungForm] = useState(false);
  const [payload, setPayload] = useState(operatorInfo);
  const [step, setStep] = useState(0);
  const [canNext, setCanNext] = useState(false);
  const [submit, setSubmit] = useState(0);
  const openFeedback = () => setCompleted(true);
  const [confirmed, setConfirmed] = useState(0);
  const [error, setError] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [completedERP, setCompletedERP] = useState(false);
  const handleNext = () => setStep(step + 1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const closeFeedback = () => {
    setCompleted(false);
    setCompletedERP(false);
    setPayload(null);
    setRefreshTheData(true);
    setError(false);
    setUpdateRechnungForm(false);
  };
  const handleSetPayload = (input) => {
    setPayload(input);
  };
  const editRechnung = () => {
    setUpdateRechnungForm(true);
    setUpdateBetreiberForm(false);
    setAnchorEl(null);
  };

  const editInformation = () => {
    setUpdateRechnungForm(false);
    setUpdateBetreiberForm(true);
    setAnchorEl(null);
  };

  useEffect(() => {
    setLoading(false);
  }, [completed, completedERP, error]);

  return (
    <>
      <LogoLoader loading={loading} />
      <Button
        variant="contained"
        onClick={handleClick}
        id="edit-selected"
        ml={4}
        sx={{
          position: 'absolute',
          boxShadow: shadows[3],
          top: ({ spacing }) => spacing(10),
          right: ({ spacing }) => spacing(10),
          zIndex: ({ zIndex }) => zIndex.appBar,
          justifyContent: 'center',
          width: '160.8px',
        }}
      >
        {t('factions')}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'edit-selected',
        }}
        sx={{
          textAlign: 'center',
        }}
      >
        <MenuItem onClick={editInformation}>
          {' '}
          <Typography align="left" sx={{ width: '100%' }}>
            {t('editOpinfo')}
          </Typography>{' '}
        </MenuItem>
        <MenuItem onClick={editRechnung}>
          {' '}
          <Typography align="left" sx={{ width: '100%' }}>
            {t('editInvoicetemp')}
          </Typography>{' '}
        </MenuItem>
      </Menu>
      <StepperPopup
        widthInput="1000"
        title={t('editOpinfo')}
        isOpen={updateBetreiberForm}
        setIsOpen={setUpdateBetreiberForm}
        steps={[
          {
            label: t('editOpinfo'),
            component: (
              <OperatorForm
                payload={operatorInfo}
                setPayload={handleSetPayload}
                initialPayload={operatorInfo}
                confirmPayload={handleNext}
                setCanNext={setCanNext}
                submit={submit}
                setSubmit={setSubmit}
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmit((prevState) => prevState + 1);
              },
              canNext: canNext,
            },
          },

          {
            label: t('confirmInfo'),
            component: (
              <ConfirmAddressUpdate
                payload={payload}
                submit={confirmed}
                setError={setError}
                setSubmit={setConfirmed}
                openFeedback={openFeedback}
              />
            ),
            nextAction: {
              label: t('LBLSave'),
              action: () => {
                setConfirmed((prevState) => prevState + 1);
                setUpdateBetreiberForm(false);
                setLoading(true);
                setSubmit(0);
              },
            },
          },
        ]}
      />
      <StepperPopup
        widthInput="1000"
        title={t('editInvoicetemp')}
        isOpen={updateRechnungForm}
        setIsOpen={setUpdateRechnungForm}
        steps={[
          {
            label: t('EnterINfo'),
            component: (
              <UpdateOperatorRechnung
                operatorInfo={operatorInfo}
                setPayload={handleSetPayload}
                confirmPayload={handleNext}
                setCanNext={setCanNext}
                submit={submit}
                setSubmit={setSubmit}
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmit((prevState) => prevState + 1);
              },
              canNext: canNext,
            },
          },

          {
            label: t('confirmInfo'),
            component: (
              <ConfirmOperatorRechnung
                payload={payload}
                submit={confirmed}
                setError={setError}
                setSubmit={setConfirmed}
                openFeedback={openFeedback}
              />
            ),
            nextAction: {
              label: t('LBLSave'),
              action: () => {
                setConfirmed((prevState) => prevState + 1);
                setUpdateRechnungForm(false);
                setLoading(true);
                setSubmit(0);
              },
            },
          },
        ]}
      />
      <Feedback
        open={completed}
        title={t('invoiceupdated')}
        message={
          <>
            <Typography align="center">{t('invoideupdatedsu')}</Typography>
          </>
        }
        handleClose={closeFeedback}
      />
      <Feedback
        open={completedERP}
        title={t('erpUpdated')}
        message={
          <>
            <Typography align="center">{t('erpUpdatedsu')}</Typography>
          </>
        }
        handleClose={closeFeedback}
      />
      <Feedback_Error
        open={error}
        title={'Fehler'}
        message={
          <>
            <Typography align="center">{t('LBLSomethingwentWrong')}</Typography>
          </>
        }
        severity="warning"
        handleClose={() => {
          setError(false);
          setPayload(null);
        }}
      />

      <Feedback
        open={completed}
        title={t('opinfoUpdated')}
        message={
          <>
            <Typography align="center">{t('opinfosuUpd')}</Typography>
          </>
        }
        handleClose={closeFeedback}
      />
    </>
  );
}

export default EditOperatorInformation;