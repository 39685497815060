import React from 'react';
// Layouts
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';

// Guards
import AuthGuard from './components/guards/AuthGuard';

// Views
import Help from './pages/Help';

// Protected routes
import Locations from './pages/Locations';
import Settings from './pages/Settings';
import DownloadCenter from './pages/DownloadCenter';

import Cockpit from './pages/Dashbord/Cockpit';
import Users from './pages/Users';
import Stations from './pages/Stations';

// Auth components
import SignIn from './pages/auth/SignIn';
import ForgetPassword from './pages/auth/ForgetPassword';
import StandortDetails from './pages/LocationDetails';
import OperatorDetails from './pages/OperatorDetails';
import CustomerDetail from './pages/Customers/components/CustomerDetail';
import NutzerDetail from './pages/UserDetail';

import RouteNotFound from './pages/RouteNotFound';
import Operators from './pages/Operators';
import StationDetails from './pages/StationDetails';
import OperatorBills from './pages/AllOperatorBills';
import RFIDS from './pages/RFIDs';
import Customers from './pages/Customers';
import ReconfigureStripeSepaSuccessUrl from './pages/StripeSepa/ReconfigureStripeSepaSuccessUrl';
import ConfigureStripeSepaSuccessUrl from './pages/StripeSepa/ConfigureStripeSepaSuccessUrl';
const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <Cockpit />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: 'cockpit',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <Cockpit />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: 'standorte',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <Locations />
          </AuthGuard>
        ),
      },
      {
        path: ':details',
        element: (
          <AuthGuard>
            <StandortDetails />
          </AuthGuard>
        ),
      },
      {
        path: ':ladestationen',
        element: (
          <AuthGuard>
            <Stations />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: 'stationen',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <Stations />
          </AuthGuard>
        ),
      },
      {
        path: ':stationId',
        element: (
          <AuthGuard>
            <StationDetails />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: 'betreiber',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <Operators />
          </AuthGuard>
        ),
      },
      {
        path: ':details',
        element: (
          <AuthGuard>
            <OperatorDetails />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: 'rechnungen',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <OperatorBills />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: 'rfid',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <RFIDS />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: 'customers',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <Customers />
          </AuthGuard>
        ),
      },
      {
        path: ':customerId',
        element: (
          <AuthGuard>
            <CustomerDetail />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: 'nutzer',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <Users />
          </AuthGuard>
        ),
      },
      {
        path: ':customerId',
        element: (
          <AuthGuard>
            <NutzerDetail />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: 'downloadcenter',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <DownloadCenter />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: 'einstellungen',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <Settings />
          </AuthGuard>
        ),
      },
    ],
  },

  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        path: 'login',
        element: <SignIn />,
      },
      {
        path: 'einloggen',
        element: <SignIn />,
      },
      {
        path: 'forgot-password',
        element: <ForgetPassword />,
      },
      {
        path: 'passwort-vergessen',
        element: <ForgetPassword />,
      },
      {
        path: 'stripesepa/configure/success/:CHECKOUT_SESSION_ID',
        element: <ConfigureStripeSepaSuccessUrl />,
      },
      {
        path: 'stripesepa/reconfigure/success/:CHECKOUT_SESSION_ID',
        element: <ReconfigureStripeSepaSuccessUrl />,
      },
    ],
  },
  /*{
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },*/
  {
    path: '*',
    element: (
      <AuthLayout>
        <RouteNotFound />
      </AuthLayout>
    ),
    children: [],
  },
  {
    path: 'support',
    element: <DashboardLayout />,
    children: [
      {
        path: '',
        element: (
          <AuthGuard>
            <Help />
          </AuthGuard>
        ),
      },
    ],
  },
];

export default routes;