import React, { useEffect, useState, lazy, Suspense, useRef } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { Add as AddIcon, ArrowForward } from '@mui/icons-material';
import StepperPopup from '../../../../components/StepperPopup';
import StationForm from './StationForm';
import StationConfirmation from './StationConfirmation';
import LogoLoader from '../../../../components/LogoLoader';
import Typography from '@mui/material/Typography';
import { useTranslation, Trans } from 'react-i18next';
import api from '../../../../Middleware/api/api';
import { useAuth } from '../../../../services/contexts';
import { useTokenUtils } from '../../../../services/utils/tokenUtils';

const Feedback = lazy(() => import('../../../../components/Feedback'));
const FailFeedback = lazy(
  () => import('../../../../components/DataTable/Feedback')
);

function CreateStation(props) {
  const { t } = useTranslation();
  const separator = window._env_.SEPARATOR_CONNECTOR_FILEUPLOAD;
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    props.sendData();
  };
  const [submit, setSubmit] = useState(0);
  const [payload, setPayload] = useState(null);
  const [canNext, setCanNext] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const { token } = useAuth();
  const { isAdminRole } = useTokenUtils();
  const [csvUploadError, setCsvUploadError] = useState(false);
  const [csvUploadErrorCodeResponse, setCsvUploadErrorCodeResponse] =
    useState('');

  useEffect(() => {
    setLoading(false);
  }, [error, response]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleManualCreation = () => {
    openModal();
    handleClose();
  };

  const handleCSVCreation = () => {
    handleClose();

    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  useEffect(() => {
    selectedFile && handleFileUpload();
  }, [selectedFile]);

  const handleFileUpload = () => {
    if (!selectedFile) {
      alert('No file selected. Please choose a file first.');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    api.createConnectorViaCSV(token, formData, separator).then((response) => {
      if (response?.data) {
        setResponse(response);
      } else {
        setCsvUploadError(response?.error);
        setCsvUploadErrorCodeResponse(response?.error?.errorCodeResponse);
      }
    });
    fileInputRef.current.value = null;
  };

  return (
    <>
      <LogoLoader loading={loading} />
      {!isAdminRole ? (
        <Button variant="contained" color="primary" onClick={openModal}>
          <AddIcon />
          {t('LBLCreateCharginStations')}
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={handleClick}
          ml={4}
          sx={{
            justifyContent: 'space-between',
            width: '200px',
            paddingRight: '30px',
          }}
        >
          <>
            <AddIcon />
            {t('LBLCreateCharginStations')}
          </>
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          sx: {
            minWidth: '200px', // Ensure the dropdown matches button width
          },
        }}
      >
        <MenuItem onClick={() => handleManualCreation()}>
          {t('LBLCreateChargingStationsForm')}
        </MenuItem>
        {isAdminRole ? (
          <MenuItem onClick={() => handleCSVCreation()}>
            {t('LBLCreateChargingStationsCSV')}
          </MenuItem>
        ) : null}
      </Menu>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={(e) => handleFileChange(e)}
      />
      <StepperPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={t('LBLCreateCharginStations')}
        steps={[
          {
            label: t('EnterINfo'),
            component: (
              <StationForm
                locationIdInput={null}
                setSubmit={setSubmit}
                submit={submit}
                payload={payload}
                setPayload={setPayload}
                setCanNext={setCanNext}
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmit((prev) => prev + 1);
              },
              canNext: canNext,
            },
          },
          {
            label: t('confirmInfo'),
            component: (
              <StationConfirmation
                payload={payload}
                submit={confirmed}
                setError={setError}
                setResponse={setResponse}
              />
            ),
            nextAction: {
              label: t('LBLSave'),
              icon: <></>,
              action: () => {
                setConfirmed(true);
                setIsOpen(false);
                setLoading(true);
              },
            },
          },
        ]}
      />
      <Suspense fallback={<div />}>
        <Feedback
          open={!!response}
          title={t('newCsDone')}
          message={
            <>
              <Typography align="center">{t('thankforInput')}</Typography>
              <Typography align="center">
                {t('newCssite')} <b>{response?.data?.data}</b> {t('beensetup')}.
                {t('teamwillbeintouch')}
              </Typography>
            </>
          }
          handleClose={() => {
            setResponse(null);
            setPayload(null);
            setConfirmed(false);
            onClose();
          }}
        />
        <FailFeedback
          open={!!error}
          title={'Fehler'}
          severity="warning"
          message={
            <>
              <Typography align="center">
                {t('LBLSomethingwentWrong')}
              </Typography>
            </>
          }
          handleClose={() => {
            setError(null);
            setPayload(null);
            setConfirmed(false);
          }}
        />
        <FailFeedback
          open={!!csvUploadError}
          title={'Fehler'}
          severity="warning"
          message={
            <>
              {csvUploadErrorCodeResponse === 'CSV_SEPARATOR_NOT_SUPPORTED' ? (
                <Typography align="center">
                  <Trans
                    i18nKey="LBL_CSV_SEPARATOR_NOT_SUPPORTED_Error"
                    values={{ separator: csvUploadError?.errorDetail }}
                    components={({ strong: <strong /> }, { br: <br /> })}
                  />
                </Typography>
              ) : csvUploadErrorCodeResponse === 'ERROR_PARSING_CSV_FILE' ? (
                <Typography align="center">
                  <Trans
                    i18nKey="LBL_ERROR_PARSING_CSV_FILE_Error"
                    components={({ strong: <strong /> }, { br: <br /> })}
                  />
                </Typography>
              ) : csvUploadErrorCodeResponse === 'ERROR_PARSING_XLS_FILE' ? (
                <Typography align="center">
                  <Trans
                    i18nKey="LBL_ERROR_PARSING_XLS_FILE_Error"
                    components={({ strong: <strong /> }, { br: <br /> })}
                  />
                </Typography>
              ) : csvUploadErrorCodeResponse === 'ERROR_PARSING_XLSX_FILE' ? (
                <Typography align="center">
                  <Trans
                    i18nKey="LBL_ERROR_PARSING_XLSX_FILE_Error"
                    components={({ strong: <strong /> }, { br: <br /> })}
                  />
                </Typography>
              ) : csvUploadErrorCodeResponse === 'UNSUPPORTED_FILE_TYPE' ? (
                <Typography align="center">
                  <Trans
                    i18nKey="LBL_UNSUPPORTED_FILE_TYPE_Error"
                    components={({ strong: <strong /> }, { br: <br /> })}
                  />
                </Typography>
              ) : csvUploadErrorCodeResponse === 'PARKINGLOT_EXISTS' ? (
                <Typography align="center">
                  <Trans
                    i18nKey="LBL_PARKINGLOT_EXISTS_Error"
                    values={{ parkingLot: csvUploadError?.errorDetail }}
                    components={({ strong: <strong /> }, { br: <br /> })}
                  />
                </Typography>
              ) : csvUploadErrorCodeResponse === 'FILE_INVALID_LOCATION' ? (
                <Typography align="center">
                  <Trans
                    i18nKey="LBL_FILE_INVALID_LOCATION_Error"
                    values={{ locations: csvUploadError?.errorDetail }}
                    components={({ strong: <strong /> }, { br: <br /> })}
                  />
                </Typography>
              ) : csvUploadErrorCodeResponse ===
                'FILE_DUPLICATED_PARKINGLOT' ? (
                <Typography align="center">
                  {t('LBL_FILE_DUPLICATED_PARKINGLOT_Error')}{' '}
                  {csvUploadError?.errorDetail &&
                    csvUploadError.errorDetail
                      .map(
                        (detail, index) =>
                          `{${t('LBLLocationId')}: ${detail.locationId} ${t('LBLParkingLot')}: ${detail.parkingLot}}`
                      )
                      .join(', ')}
                </Typography>
              ) : csvUploadErrorCodeResponse === 'FILE_INVALID_ABBREVIATION' ? (
                <Typography align="center">
                  <Trans
                    i18nKey="LBL_FILE_INVALID_ABBREVIATION_Error"
                    values={{ abbr: csvUploadError?.errorDetail }}
                    components={({ strong: <strong /> }, { br: <br /> })}
                  />
                </Typography>
              ) : csvUploadErrorCodeResponse === 'FILE_DUPLICATED_CPIDS' ? (
                <Typography align="center">
                  <Trans
                    i18nKey="LBL_FILE_DUPLICATED_CPIDS_Error"
                    values={{ cpids: csvUploadError?.errorDetail }}
                    components={({ strong: <strong /> }, { br: <br /> })}
                  />
                </Typography>
              ) : csvUploadErrorCodeResponse ===
                'FILE_INVALID_LENGTH_PARKINGLOT' ? (
                <Typography align="center">
                  <Trans
                    i18nKey="LBL_FILE_INVALID_LENGTH_PARKINGLOT_Error"
                    values={{ parkingLots: csvUploadError?.errorDetail }}
                    components={({ strong: <strong /> }, { br: <br /> })}
                  />
                </Typography>
              ) : csvUploadErrorCodeResponse ===
                'FILE_INVALID_EMPTY_LOCATION_NOT_EMPTY_PARKINGlOT' ? (
                <Typography align="center">
                  <Trans
                    i18nKey="LBL_FILE_INVALID_EMPTY_LOCATION_NOT_EMPTY_PARKINGlOT_Error"
                    components={({ strong: <strong /> }, { br: <br /> })}
                  />
                </Typography>
              ) : csvUploadErrorCodeResponse ===
                'WALLBOX_INVALID_NUMBER_CONNECTORS' ? (
                <Typography align="center">
                  <Trans
                    i18nKey="LBL_WALLBOX_INVALID_NUMBER_CONNECTORS_Error"
                    values={{ wallboxes: csvUploadError?.errorDetail }}
                    components={({ strong: <strong /> }, { br: <br /> })}
                  />
                </Typography>
              ) : (
                <Typography align="center">
                  <Trans
                    i18nKey="LBL_CONNECTOR_FILE_UPLOAD_GENERAL_ERROR"
                    values={{ errorcode: csvUploadErrorCodeResponse }}
                    components={({ strong: <strong /> }, { br: <br /> })}
                  />
                </Typography>
              )}
            </>
          }
          handleClose={() => {
            setCsvUploadError(null);
            setPayload(null);
            setCsvUploadError(false);
            setSelectedFile(null);
          }}
        />
      </Suspense>
    </>
  );
}

export default CreateStation;
