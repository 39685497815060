/* eslint-disable no-unused-vars */
import React, { lazy, Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ArrowForward } from '@mui/icons-material';
import {
  IconButton,
  Typography,
  useTheme,
  ThemeProvider,
  Menu,
  MenuItem,
  Paper,
  TableCell,
  Grid,
} from '@mui/material';
import { Stack, TextField, Button } from '@mui/material';
import { useParams, NavLink } from 'react-router-dom';
import DataTable, { statusMappingBackendToFrontend } from '../DataTable';
import { createTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { mapStatus } from '../../pages/Stations';
import EditIcon from '@mui/icons-material/Edit';
import StepperPopup from '../StepperPopup';
import MultipleParkingLotEdit from '../multipleEditDialog/MultipleParkingLotEdit';
import MultipleStatusEdit from '../multipleEditDialog/MultipleStatusEdit';
import { formatDecimal } from '../../services/utils/format-numbers';
import { PAGE_SIZE, MIN_PAGE_SIZE } from '../../services/utils/units-constants';
import { useAuth } from '../../services/contexts/AuthContext';
import api from '../../Middleware/api/api';
const Feedback = lazy(() => import('../auth/Feedback'));

// eslint-disable-next-line no-unused-vars
const dataTableTheme = (appTheme) =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            marginLeft: 0,
            paddingLeft: 8,
            '&[data-colindex="4"]': {
              '& > *': {
                justifyContent: 'center',
                textAlign: 'center',
              },
              '& .MuiButton-root': {
                margin: '0px',
              },
            },
            '&[data-colindex="3"], &[data-colindex="1"], &[data-colindex="2"]':
              {
                '& > *': {
                  justifyContent: 'left',
                },
                '& .MuiButton-root': {
                  marginLeft: '0',
                },
              },
            '&[data-colindex="6"], &[data-colindex="5"]': {
              '& > *': {
                justifyContent: 'right',
              },
            },
          },
        },
      },
    },
  });

const Charginstations = ({ locationId, successUpdate }) => {
  const { token } = useAuth();
  const { t, i18n } = useTranslation();
  const { status } = useTheme();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editMode, setEditMode] = useState([]);
  const [error, setError] = useState('');
  const [payload, setPayload] = useState([]);
  const [submit, setSubmit] = useState(0);
  const [isStatusEditModalOpen, setIsStatusEditModalOpen] = useState(false);
  const [statusDialogData, setStatusDialogData] = useState([]);
  const [parkingDialogData, setParkingDialogData] = useState([]);
  const [showFeedbackParking, setShowFeedbackParking] = useState(false);
  const [showFeedbackStatus, setShowFeedbackStatus] = useState(false);
  const [checkStatusChange, setCheckStatusChange] = useState(false);
  const [showErrorEmptyParkingEdit, setShowErrorEmptyParkingEdit] =
    useState(false);
  const [checkParkingLotChange, setcheckParkingLotChange] = useState(false);
  const [errorTitle, setErrorTitle] = useState('');
  const [showDuplicateModel, setShowDuplicateModel] = useState(false);
  const [showDuplicateModelData, setShowDuplicateModelData] = useState('');
  const [showDuplicateModelTitle, setShowDuplicateModelTitle] = useState('');

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE[0]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [chargingStations, setChargingStations] = useState([]);

  const fetchCharginStationByLocationId = async () => {
    try {
      if (locationId != undefined && locationId != null && locationId != '') {
        api
          .fetchCharginStationByLocationId(
            token,
            locationId,
            currentPage,
            pageSize
          )
          .then(
            (response) => {
              if (response.data.data == null) {
                setChargingStations([]);
              } else {
                setTotalRecords(response?.data?.data?.totalNumberOfRecords);
                setChargingStations(response?.data?.data?.response);
              }
            },
            () => {
              setChargingStations([]);
            }
          );
      }
    } catch (exception) {
      setChargingStations([]);
    }
  };

  useEffect(() => {
    fetchCharginStationByLocationId();
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (error) setEditMode([]);
  }, [error]);

  const closeErrorFeedback = () => setError('');

  /**
   * @method
   * @param {React.MouseEvent<HTMLInputElement>} event
   */
  const [openElem, setOpenElem] = React.useState(null);
  const [anchorIconButton, setAnchorIconButton] = useState(null);

  const handleClickIconMenu = (elem) => (event) => {
    setAnchorIconButton(event.currentTarget);
    setOpenElem(elem);
  };
  const handleCloseIconMenu = () => {
    setOpenElem(null);
    setAnchorIconButton(null);
  };
  useEffect(() => {
    const hasInactive = parkingDialogData.some(
      (row) => row.connectorStatus === 'INACTIVE'
    );
    if (parkingDialogData.length > 0 && !hasInactive) {
      setIsEditModalOpen(true);
    } else if (hasInactive) {
      setParkingDialogData([]);
      setErrorTitle(t('LBLWrongSelection'));
      setError(
        <>
          {t('cswithstatus')}{' '}
          <span style={{ color: status.inaktiv }}>{t('LBLInactive')}</span>{' '}
          {t('cantedit')}
        </>
      );
    }
  }, [parkingDialogData]);
  useEffect(() => {
    if (statusDialogData.length > 0) {
      const hasOccupied = statusDialogData.some(
        (row) =>
          row.connectorStatus === 'OCCUPIED_UNCHARGED' ||
          row.connectorStatus === 'OCCUPIED_CHARGING'
      );
      const hasInvalid = statusDialogData.some(
        (row) =>
          !(
            row.connectorStatus === 'IDLE' ||
            (row.connectorStatus === 'CONNECTED_UNAVAILABLE' &&
              !!row.parkingLot) ||
            row.connectorStatus === 'OCCUPIED_UNCHARGED' ||
            row.connectorStatus === 'OCCUPIED_CHARGING'
          )
      );
      switch (true) {
        case !hasOccupied && !hasInvalid:
          setIsStatusEditModalOpen(true);
          break;
        case hasInvalid:
          setErrorTitle(t('LBLWrongSelection'));
          setError(
            <>
              {t('cscanbeused')}{' '}
              <span style={{ color: status.standby }}>Standby</span> oder{' '}
              <span style={{ color: status.aktiv }}>{t('LBLActive')}</span>{' '}
              {t('tobeedited')}
            </>
          );
          setStatusDialogData([]);
          break;
        case hasOccupied:
          setErrorTitle(t('csInoperation'));
          setError(
            <>
              {t('LBLChastationInuse')} : <br />{' '}
              <Paper sx={{ display: 'flex', justifyContent: 'center' }}>
                <ul>
                  {statusDialogData
                    .filter(
                      (row) =>
                        row.connectorStatus === 'OCCUPIED_UNCHARGED' ||
                        row.connectorStatus === 'OCCUPIED_CHARGING'
                    )
                    .map((item) => (
                      <li key={item.cpId}>{item.cpId}</li>
                    ))}
                </ul>
              </Paper>
              {t('LBLCantchangestatus')}
            </>
          );
          setStatusDialogData([]);
          break;
        default:
          break;
      }
    }
  }, [statusDialogData]);

  const cols = [
    {
      name: 'locationName',
      label: t('LBLLocation'),
    },
    {
      name: 'cpId',
      label: 'Station ID',
    },
    {
      name: 'parkingLot',
      label: t('LBLParkingLotNum'),
    },
    {
      name: 'connectorStatus',
      label: t('LBLCHarginStationStatus'),
      type: 'select',
      selectConfig: {
        label: t('LBLCHarginStationStatus'),
        values: [
          {
            value: 'IDLE',
            label:
              i18n.language === 'de'
                ? statusMappingBackendToFrontend['idle']
                : 'Active',
          },
          {
            value: 'CONNECTED_UNAVAILABLE',
            label: mapStatus('connected_unavailable', ' ', t),
          },
        ],
      },
    },
  ];

  const columns = [
    {
      name: 'cpId',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'cpId',
      label: 'Station-ID',
      options: {
        filter: false,
        customBodyRender: (value) => {
          return <Typography align="left">{value}</Typography>;
        },
      },
    },
    {
      name: 'connectorId',
      label: t('connectorId'),
      options: {
        align: 'left',
        filter: false,
        customBodyRender: (value) => {
          return (
            <>
              <Typography align="center">{value?.split('_')?.pop()}</Typography>
            </>
          );
        },
        customHeadRender: () => (
          <TableCell
            variant="head"
            sx={{
              textAlign: 'left',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            {t('connectorId')}
          </TableCell>
        ),
      },
    },
    {
      name: 'address',
      label: t('LBLParkingLot'),
      options: {
        filter: false,
        customBodyRender: (address) => {
          return <Typography align="left">{address?.parkingLot}</Typography>;
        },
        customHeadRender: () => (
          <TableCell
            align="center"
            variant="head"
            sx={{
              textAlign: 'left',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            {t('LBLParkingLot')}
          </TableCell>
        ),
      },
    },
    {
      name: 'wallboxModel',
      label: t('LBLModelOfCharginStation'),
      options: {
        filter: false,
        customBodyRender: (value) => {
          return value;
        },
        customHeadRender: () => (
          <TableCell
            align="center"
            variant="head"
            sx={{
              textAlign: 'left',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            {t('LBLModelOfCharginStation')}
          </TableCell>
        ),
      },
    },
    {
      name: 'connectorStatusMapping',
      label: t('LBLCHarginStationStatus'),
      options: {
        filter: false,
        customBodyRender: (value) => {
          if (!value) return null;
          return (
            <Typography align="center" color={value.colourCode}>
              {t(value.enumName)}
            </Typography>
          );
        },
        customHeadRender: () => (
          <TableCell
            align="center"
            variant="head"
            sx={{ position: 'sticky', top: 0, backgroundColor: 'white' }}
          >
            {t('LBLCHarginStationStatus')}
          </TableCell>
        ),
      },
    },
    {
      name: 'edit',
      label: ' ',
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, index) => {
          let idValue = 'charginId' + index?.rowIndex;
          return (
            <>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <IconButton onClick={handleClickIconMenu(value)}>
                    <EditIcon id={idValue} />
                  </IconButton>
                  <Menu
                    anchorEl={anchorIconButton}
                    key={value}
                    open={openElem === value}
                    onClose={handleCloseIconMenu}
                    MenuListProps={{
                      'aria-labelledby': 'edit-selected',
                    }}
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <MenuItem
                      key={value}
                      onClick={() => {
                        setParkingDialogData(
                          chargingStations
                            .filter((row) => value === row.connectorId)
                            .map((row) => ({
                              ...row,
                              parkingLot: row.address.parkingLot,
                            }))
                        );
                        handleCloseIconMenu();
                      }}
                    >
                      <Typography align="center" sx={{ width: '100%' }}>
                        {t('LBLParkplatzhinterlegen')}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setStatusDialogData(
                          chargingStations
                            .filter((row) => value === row.connectorId)
                            .map((row) => ({
                              ...row,
                              parkingLot: row.address.parkingLot,
                            }))
                        );
                        handleCloseIconMenu();
                      }}
                    >
                      {t('ChangechargingStatus')}
                    </MenuItem>
                  </Menu>
                </Grid>
                <Grid item xs={8}>
                  <Button
                    component={NavLink}
                    to={`/stationen/${value}/`}
                    variant="text"
                  >
                    {t('LBLDetails')}
                  </Button>
                </Grid>
              </Grid>
            </>
          );
        },
      },
    },
  ];

  const handleChangeError = (input) => {
    setShowDuplicateModelData(input.detail);
    setShowDuplicateModelTitle(input.title);
    setShowDuplicateModel(true);
  };
  const closeDuplicateError = () => {
    setShowDuplicateModel(false);
    setSubmit(0);
  };
  return (
    <>
      <ThemeProvider
        theme={(appTheme) => ({
          ...appTheme,
          components: {
            ...appTheme.components,
            ...dataTableTheme(appTheme).components,
          },
        })}
      >
        <DataTable
          data={chargingStations?.map((row) => ({
            ...row,
            edit: row.connectorId,
          }))}
          columns={columns}
          options={{
            textLabels: {
              body: {
                noMatch: t('nocharginstcreated'),
              },
            },
            totalRecords: totalRecords,
            currentPage: currentPage,
          }}
          getData={(seletedPageSize, currentPageVal) => {
            setCurrentPage(currentPageVal);
            setPageSize(seletedPageSize);
          }}
          title={t('LBLCharginStations')}
        ></DataTable>
      </ThemeProvider>

      <Suspense fallback={<div />}>
        <StepperPopup
          isOpen={Boolean(isEditModalOpen)}
          setIsOpen={setIsEditModalOpen}
          title={t('LBLParkplatzhinterlegen')}
          steps={[
            {
              label: t('EnterINfo'),

              component: (
                <MultipleParkingLotEdit
                  data={parkingDialogData}
                  payload={payload}
                  setPayload={setPayload}
                  isReadOnly={false}
                  showEmptyFieldError={showErrorEmptyParkingEdit}
                  checkParkingLotChange={checkParkingLotChange}
                  setShowFeedbackError={handleChangeError}
                />
              ),
              nextAction: {
                label: t('LBLFurther'),
                icon: <ArrowForward />,
                action: () => {
                  setShowErrorEmptyParkingEdit(true);
                  setcheckParkingLotChange(true);
                },
                canNext:
                  payload.filter(
                    (row) =>
                      !row.parkingLotNumber ||
                      row.parkingLotNumber === '' ||
                      !row.changed
                  ).length <= 0,
              },
            },
            {
              label: t('confirmInfo'),

              component: (
                <MultipleParkingLotEdit
                  data={parkingDialogData}
                  payload={payload}
                  setPayload={setPayload}
                  isReadOnly={true}
                  submit={submit}
                  setShowFeedback={setShowFeedbackParking}
                  setShowFeedbackError={handleChangeError}
                />
              ),
              nextAction: {
                label: t('LBLSave'),
                icon: <></>,
                action: () => {
                  setSubmit((prevState) => prevState + 1);
                  setIsEditModalOpen(false);
                  setShowErrorEmptyParkingEdit(false);
                },
                canNext: false,
              },
            },
          ]}
        />
        <StepperPopup
          isOpen={Boolean(isStatusEditModalOpen)}
          setIsOpen={setIsStatusEditModalOpen}
          title={t('ChangechargingStatus')}
          steps={[
            {
              label: t('LBLChangeEnter'),
              component: (
                <MultipleStatusEdit
                  data={statusDialogData}
                  cols={cols}
                  payload={payload}
                  setPayload={setPayload}
                  isReadOnly={false}
                  checkStatusChange={checkStatusChange}
                />
              ),
              nextAction: {
                label: t('LBLFurther'),
                icon: <ArrowForward />,
                action: () => {
                  setCheckStatusChange(true);
                },
                canNext:
                  !payload.filter((value) => value?.changed === false).length >
                  0,
              },
            },
            {
              label: t('LBLConfirmChanges'),
              component: (
                <MultipleStatusEdit
                  data={statusDialogData}
                  cols={cols}
                  payload={payload}
                  setPayload={setPayload}
                  submit={submit}
                  isReadOnly={true}
                  setShowFeedback={setShowFeedbackStatus}
                />
              ),
              nextAction: {
                label: t('LBLSave'),
                icon: <></>,
                action: () => {
                  setSubmit((prev) => prev + 1);
                  setIsStatusEditModalOpen(false);
                },
                canNext: false,
              },
            },
          ]}
        />
        <Feedback
          open={!!error}
          title={errorTitle}
          message={
            <Typography variant="subtitle1" sx={{ width: 400, mx: 10 }}>
              {error}
            </Typography>
          }
          severity="warning"
          handleClose={closeErrorFeedback}
        />
        <Feedback
          open={showDuplicateModel}
          title={showDuplicateModelTitle}
          message={
            <Typography variant="subtitle1" sx={{ width: 400, mx: 10 }}>
              {showDuplicateModelData}
            </Typography>
          }
          severity="warning"
          handleClose={closeDuplicateError}
        />
        <Feedback
          open={showFeedbackParking}
          handleClose={() => {
            successUpdate((prev) => prev + 1);
            setShowFeedbackParking(false);
            setSubmit(0);
          }}
          message={t('ParkinglotUpdated')}
          title={t('lotNumberUpdated')}
        />
        <Feedback
          open={showFeedbackStatus}
          handleClose={() => {
            setShowFeedbackStatus(false);
            setSubmit(0);
          }}
          message={t('ParaCHarginst')}
          title={t('statussubmitted')}
        />
      </Suspense>
    </>
  );
};

Charginstations.propTypes = {
  locationId: PropTypes.string.isRequired,
  setRows: PropTypes.func.isRequired,
};

Charginstations.defaultProps = {
  locationId: '',
};

export default Charginstations;