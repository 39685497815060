import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import shadows from '@mui/material/styles/shadows';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: 0,
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: theme.palette.common.white,
    boxShadow: shadows[1],
    border: `1px solid ${theme.palette.divider}`,
  },
}));

export default LightTooltip;
