import axios from 'axios';

class AxiosInstanceGroup {
  #userService;
  #backendForFrontend;
  #contractManagement;
  #bookingService;

  /**
   * @method
   * @param {string} baseURL - base url for instance
   */
  #createInstance(baseURL) {
    return axios.create({ baseURL });
  }

  /**
   * Get user service API for user authentication
   * @method
   * @returns {import("axios").AxiosInstance}
   */
  get userService() {
    return this.#userService;
  }

  /**
   * @method
   * @param {string} baseURL - base url for instance
   * @returns {import("axios").AxiosInstance}
   */
  set userService(baseURL) {
    this.#userService = this.#createInstance(baseURL);
  }

  /**
   * Get backend for frontend API for table data
   * @method
   * @returns {import("axios").AxiosInstance}
   */
  get backendForFrontend() {
    return this.#backendForFrontend;
  }

  /**
   * @method
   * @param {string} baseURL - base url for instance
   */
  set backendForFrontend(baseURL) {
    this.#backendForFrontend = this.#createInstance(baseURL);
  }

  /**
   * Get backend for frontend API for table data
   * @method
   * @returns {import("axios").AxiosInstance}
   */
  get contractManagement() {
    return this.#contractManagement;
  }

  /**
   * @method
   * @param {string} baseURL - base url for instance
   */
  set contractManagement(baseURL) {
    this.#contractManagement = this.#createInstance(baseURL);
  }

  /**
   * Get backend for frontend API for table data
   * @method
   * @returns {import("axios").AxiosInstance}
   */
  get bookingService() {
    return this.#bookingService;
  }

  /**
   * @method
   * @param {string} baseURL - base url for instance
   */
  set bookingService(baseURL) {
    this.#bookingService = this.#createInstance(baseURL);
  }
}

export const axiosInstance = new AxiosInstanceGroup();
axiosInstance.userService = window._env_.REACT_APP_USER_SERVICE;
axiosInstance.backendForFrontend =
  window._env_.REACT_APP_BACKEND_FOR_FRONTEND_API;
axiosInstance.contractManagement =
  window._env_.REACT_APP_CONTRACT_MANGEMENT_API;
axiosInstance.bookingService = window._env_.REACT_APP_BOOKING_SERVICE_API;

export const userService = axiosInstance.userService;
export const backendForFrontend = axiosInstance.backendForFrontend;
export const contractManagement = axiosInstance.contractManagement;
export const bookingService = axiosInstance.bookingService;

userService.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);

backendForFrontend.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);

contractManagement.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);

bookingService.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);

function removeEmptyStringsAndNull(obj) {
  if (obj === null || obj === undefined) return null;
  
  if (typeof obj !== 'object') return obj;

  if (Array.isArray(obj)) {
    const cleanedArray = obj
      .map(removeEmptyStringsAndNull)
      .filter(value => value !== null && value !== undefined);

    return cleanedArray.length ? cleanedArray : null;
  }

  const cleanedObj = Object.fromEntries(
    Object.entries(obj)
      .map(([key, value]) => [key, removeEmptyStringsAndNull(value)])
      .filter(([_, cleanedValue]) => cleanedValue !== null && cleanedValue !== undefined && cleanedValue !== '')
  );

  return Object.keys(cleanedObj).length ? cleanedObj : null;
}

userService.interceptors.request.use((config) => {
  if (config.data && typeof config.data === 'object') {
    config.data = removeEmptyStringsAndNull(config.data);
  }
  return config;
});

contractManagement.interceptors.request.use((config) => {
  if (config.data && typeof config.data === 'object') {
    config.data = removeEmptyStringsAndNull(config.data);
  }
  return config;
});

backendForFrontend.interceptors.request.use((config) => {
  if (config.data && typeof config.data === 'object') {
    config.data = removeEmptyStringsAndNull(config.data);
  }
  console.log(config.data);
  return config;
});

bookingService.interceptors.request.use((config) => {
  if (config.data && typeof config.data === 'object') {
    config.data = removeEmptyStringsAndNull(config.data);
  }
  return config;
});

export default axiosInstance;