/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import * as React from 'react';
import LogoLoader from '../../components/LogoLoader';
import UserCreationForm from '../Users/components/CreateUser/UserCreationForm';
import UserCreationFormLocation from '../Users/components/CreateUser/UserCreationFormLocation';
import UserCreationFormContract from '../Users/components/CreateUser/UserCreationFormContract';
import UserCreationFormContractEdit from '../Users/components/CreateUser/UserCreationFormContractEdit';
import UserCreationFormERPInitial from '../Users/components/CreateUser/UserCreationFormERPInitial';
import UserCreationFormERP from '../Users/components/CreateUser/UserCreationFormERP';
import ConfirmUser from '../Users/components/CreateUser/ConfirmUser';
import ConfirmUserTariffEdit from '../Users/components/CreateUser/ConfirmUserTariffEdit';
import { Trans } from 'react-i18next';
import ConfirmationBox from './components/ConfirmationBox';
import { useNavigate } from 'react-router-dom';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  MenuItem,
  Menu,
  Typography,
} from '@mui/material';
import shadows from '@mui/material/styles/shadows';
import StepperPopup from '../../components/StepperPopup';
import { ArrowForward } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import Feedback from '../../components/Feedback';
import Popup from './Popup';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../../services/contexts';
import Feedback_Error from '../../components/DataTable/Feedback';
import { contractManagement } from '../../services/utils/axios';
import FailFeedback from '../../components/DataTable/Feedback';
import api from '../../Middleware/api/api';
import { useTokenUtils } from '../../services/utils/tokenUtils';

export default function EditUserInformation({ contract, setRefreshTheData }) {
  const { t } = useTranslation();
  const { token } = useAuth();
  const { isOperatorRole, isAdminRole } = useTokenUtils();
  const [editUserDetailsForm, setEditUserDetailsForm] = useState(false);
  const [editUserTarifForm, setEditTarifForm] = useState(false);
  let initDataRedoStripeSepaErrorMessageOpenFalse = {
    open: false,
    title: '',
    severity: '',
    txtMessage: '',
  };
  const [payload, setPayload] = useState(contract);
  const [payloadOne, setPayloadOne] = useState(contract);
  const [payloadTwo, setPayloadTwo] = useState(contract);
  const [payloadTariff, setPayloadTariff] = useState(contract);
  const [emailError, setemailError] = useState(false);
  const [endCustomerId, setEndCustomerId] = useState(null);
  const [payloadERP, setPayloadERP] = useState(null);
  const [contractStatus, setContractStatus] = useState(null);
  const [step, setStep] = useState(0);
  const [canNext, setCanNext] = useState(false);
  const [canNextOne, setCanNextOne] = useState(false);
  const [canNextTwo, setCanNextTwo] = useState(false);
  const [canNextTariff, setCanNextTariff] = useState(false);
  const [canNextERP, setCanNextERP] = useState(false);
  const [submit, setSubmit] = useState(0);
  const [submitOne, setSubmitOne] = useState(0);
  const [submitTwo, setSubmitTwo] = useState(0);
  const [submitTariff, setSubmitTariff] = useState(0);
  const [submitERP, setSubmitERP] = useState(0);
  const openFeedback = () => setCompleted(true);
  const [confirmed, setConfirmed] = useState(0);
  const [rfidError, setrfidError] = useState(null);
  const [rfidSError, setrfidSError] = useState(null);
  const [error, setError] = useState(false);
  const [redoStripeSepaError, setRedoStripeSepaError] = useState(
    initDataRedoStripeSepaErrorMessageOpenFalse
  );
  const navigate = useNavigate();

  const [completed, setCompleted] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [redoStripeSepaOpenPopup, setRedoStripeSepaOpenPopup] = useState(false);
  const [isRedoStripeSepa, setIsRedoStripeSepa] = useState(null);
  const [successFeedback, setSuccessFeedback] = useState(false);
  const [redoStripeSepaSuccessFeeback, setRedoStripeSepaSuccessFeeback] =
    useState(false);
  const { typography } = useTheme();
  const handleNext = () => setStep(step + 1);
  const handlePrev = () => setStep(step - 1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [userDeleteSuccessFeedback, setUserDeleteSuccessFeedback] =
    useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const RATE_LIMIT_INTERVAL =
    window._env_.REDO_STRIPE_SEPA_FOR_USER_RATE_LIMIT_INTERVAL;
  // Changing status of contract from ACTIVE to EXPIRED
  useEffect(() => {
    if (isRedoStripeSepa) {
      redoStripeSepa();
    }
  }, [isRedoStripeSepa]);
  useEffect(() => {
    if (contractStatus) {
      updateContractStatus();
    }
  }, [contractStatus]);

  const getStripeSepaRateLimiterKeys = (contractKey) => {
    return {
      lastRequestTimeKey: `redoStripeSepaLastRequestTime_${contractKey}`,
      expirationTimeKey: `redoStripeSepaExpirationTime_${contractKey}`,
    };
  };

  const removeRedoStripeSepaRateLimiterDataFromLocalStorage = (contractKey) => {
    const { lastRequestTimeKey, expirationTimeKey } =
      getStripeSepaRateLimiterKeys(contractKey);
    localStorage.removeItem(lastRequestTimeKey);
    localStorage.removeItem(expirationTimeKey);
  };

  const makeRedoStripeSepaRequestBasedOnRateLimiter = (contractKey) => {
    const { lastRequestTimeKey, expirationTimeKey } =
      getStripeSepaRateLimiterKeys(contractKey);
    const lastRequestTime = localStorage.getItem(lastRequestTimeKey);
    const expirationTime = localStorage.getItem(expirationTimeKey);
    const currentTime = Date.now();

    if (!lastRequestTime || currentTime > expirationTime) {
      // If no last request time is stored or expiration time has passed, reset and allow the request
      localStorage.setItem(lastRequestTimeKey, currentTime);
      localStorage.setItem(
        expirationTimeKey,
        currentTime + RATE_LIMIT_INTERVAL
      );

      setTimeout(() => {
        removeRedoStripeSepaRateLimiterDataFromLocalStorage(contractKey);
      }, RATE_LIMIT_INTERVAL); // Remove the record after the interval

      return true;
    }

    return false;
  };

  const redoStripeSepa = () => {
    if (token) {
      const contractKey = contract?.contractKey;
      try {
        api.redoStripeSepa(token, contractKey).then(
          (response) => {
            setLoading(false);
            setRedoStripeSepaSuccessFeeback(true);
            setIsRedoStripeSepa(false);
          },
          (error) => {
            setLoading(false);
            setIsRedoStripeSepa(false);
            if (error?.response?.status === 429) {
              let DataRedoStripeSepaErrorMessage = {
                open: true,
                title: t('TitleRedoStripeSepaError429TooManyRequests'),
                severity: 'warning',
                txtMessage: t('LBLRedoStripeSepaError429TooManyRequests'),
              };
              setRedoStripeSepaError(DataRedoStripeSepaErrorMessage);
            } else if (
              error?.response?.data?.error?.errorCodeResponse ===
                'PAYMENT_METHOD_DATA_INCONSISTENCY' ||
              error?.response?.data?.error?.errorCodeResponse ===
                'CONTRACT_STATUS_DATA_INCONSISTENCY' ||
              error?.response?.data?.error?.errorCodeResponse ===
                'STRIPE_SEPA_NOT_CONFIGURED'
            ) {
              let DataRedoStripeSepaErrorMessage = {
                open: true,
                title: t('TitleRedoStripeSepaErrorValidationError'),
                severity: 'warning',
                txtMessage: t('LBLRedoStripeSepaErrorValidationError'),
              };
              removeRedoStripeSepaRateLimiterDataFromLocalStorage(contractKey);
              setRedoStripeSepaError(DataRedoStripeSepaErrorMessage);
            } else {
              let DataRedoStripeSepaErrorMessage = {
                open: true,
                title: t('mistake'),
                severity: 'warning',
                txtMessage: t('LBLRedoStripeSepaErrorGeneralError'),
              };
              removeRedoStripeSepaRateLimiterDataFromLocalStorage(contractKey);
              setRedoStripeSepaError(DataRedoStripeSepaErrorMessage);
            }
          }
        );
      } catch (exception) {
        setLoading(false);
        setIsRedoStripeSepa(false);
        let DataRedoStripeSepaErrorMessage = {
          open: true,
          title: t('mistake'),
          severity: 'warning',
          txtMessage: t('LBLRedoStripeSepaErrorGeneralError'),
        };
        removeRedoStripeSepaRateLimiterDataFromLocalStorage(contractKey);
        setRedoStripeSepaError(DataRedoStripeSepaErrorMessage);
      }
    }
  };

  const updateContractStatus = async () => {
    try {
      const filteredPayload = {
        contractKey: contract?.contractKey,
        contractStatus: 'EXPIRED',
      };
      await contractManagement.put('/update-contract', filteredPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSuccessFeedback(true);
    } catch (error) {
      setError(true);
    }
  };

  /** @type {import("@mui/material").DialogProps['onClose']} */
  const onClose = (_event, reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
      setStep(0);
      setPayload(null);
    }
  };
  const closeModalsRedoStripeSepa = () => {
    setRedoStripeSepaError(initDataRedoStripeSepaErrorMessageOpenFalse);
    setError(false);
    setRefreshTheData((prevState) => prevState + 1);
    setIsRedoStripeSepa(false);
    setRedoStripeSepaOpenPopup(false);
    setRedoStripeSepaSuccessFeeback(false);
    setOpenPopup(false);
  };
  const closeFeedback = () => {
    setCompleted(false);
    setPayload(null);
    setRefreshTheData((prevState) => prevState + 1);
    setOpenPopup(false);
    setSuccessFeedback(false);
    setAnchorEl(null);
    setRedoStripeSepaOpenPopup(false);
    setRedoStripeSepaSuccessFeeback(false);
  };
  const handleSetPayload = (input) => {
    setPayload(input);
  };
  const handleSetPayloadOne = (input) => {
    setPayloadOne(input);
  };
  const handleNextevent = (input) => {
    handleNext();
  };
  const handleSetPayloadTwo = (input) => {
    setPayloadTwo(input);
  };
  const handleSetPayloadTariff = (input) => {
    setPayloadTariff(input);
  };
  const handleSetPayloadERP = (input) => {
    setPayloadERP(input);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopupAction = () => {
    setContractStatus(true);
    setOpenPopup(false);
  };
  const handlePopupActionRedoStripeSepa = () => {
    let makeRequest = makeRedoStripeSepaRequestBasedOnRateLimiter(
      contract?.contractKey
    );
    if (!makeRequest) {
      setLoading(false);
      setIsRedoStripeSepa(false);
      setRedoStripeSepaOpenPopup(false);
      let DataRedoStripeSepaErrorMessage = {
        open: true,
        title: t('TitleRedoStripeSepaError429TooManyRequests'),
        severity: 'warning',
        txtMessage: t('LBLRedoStripeSepaError429TooManyRequests'),
      };
      setRedoStripeSepaError(DataRedoStripeSepaErrorMessage);
    } else {
      setLoading(true);
      setIsRedoStripeSepa(true);
      setRedoStripeSepaOpenPopup(false);
    }
  };
  const handleOpenFeedbackTariff = () => {
    openFeedback();
    setEditTarifForm(false);
  };
  const setSubmittingTariff = () => {
    setEditTarifForm(false);
  };
  /** @type {import("@mui/material").DialogProps['onClose']} */
  const onCloseOne = (_event, reason) => {
    if (reason !== 'backdropClick') {
      setPayloadOne(null);

      setStep(1);
    }
  };
  /** @type {import("@mui/material").DialogProps['onClose']} */
  const onCloseTwo = (_event, reason) => {
    if (reason !== 'backdropClick') {
      setPayloadTwo(null);

      setStep(2);
    }
  };
  /** @type {import("@mui/material").DialogProps['onClose']} */
  const onCloseERP = (_event, reason) => {
    if (reason !== 'backdropClick') {
      setPayloadERP(null);
      setStep(3);
    }
  };
  useEffect(() => {
    setLoading(false);
  }, [completed, error, rfidError, rfidSError, emailError]);

  const deleteUserContract = async () => {
    setLoading(true);
    setAnchorEl(null);
    setShowConfirmationModal(false);
    const res = await api.deleteUserContract(token, contract?.contractKey);
    if (res?.status === 200) {
      setShowConfirmationModal(false);
      setUserDeleteSuccessFeedback(true);
      setLoading(false);
    } else {
      setLoading(false);
      setError(true);
    }
  };
  return (
    <>
      <LogoLoader loading={loading} />
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant="contained"
        sx={{
          position: 'absolute',
          boxShadow: shadows[3],
          top: ({ spacing }) => spacing(5),
          right: ({ spacing }) => spacing(10),
          zIndex: ({ zIndex }) => zIndex.appBar,
          display:
            contract?.contractStatus === 'EXPIRED' ||
            contract?.contractStatus === 'UNSIGNED_EXPIRED' ||
            (isOperatorRole && contract?.userType === 'GLOBAL_ADMIN')
              ? 'none'
              : 'flex',
          justifyContent: 'center',
          width: '150px',
        }}
      >
        {t('factions')}
      </Button>
      {/*changes for drop down list for update user and changing contractStatus*/}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'edit-selected',
        }}
        sx={{
          textAlign: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            setEditUserDetailsForm(true);
            setAnchorEl(null);
          }}
        >
          <Typography align="center" style={{ width: 220 }}>
            {t('edit')}
          </Typography>
        </MenuItem>
        <MenuItem
          sx={{
            display:
              contract?.contractStatus === 'ACTIVE' &&
              contract?.contractChangeTariff == null &&
              contract?.billingType === 'WITH_BILLING'
                ? true
                : 'none',
          }}
          onClick={() => {
            setEditTarifForm(true);
            setAnchorEl(null);
          }}
        >
          <Typography align="center" style={{ width: 220 }}>
            {t('editTariff')}
          </Typography>
        </MenuItem>

        <MenuItem
          sx={{
            display: contract?.contractStatus !== 'ACTIVE' ? 'none' : true,
          }}
          onClick={() => {
            setOpenPopup(true);
          }}
        >
          <Typography align="center" style={{ width: 220 }}>
            {t('terminateUsers')}
          </Typography>
        </MenuItem>

        {isAdminRole ? (
          <MenuItem
            onClick={() => {
              setShowConfirmationModal(true);
            }}
          >
            <Typography align="center" style={{ width: 220 }}>
              {t('deleteUserContract')}
            </Typography>
          </MenuItem>
        ) : null}

        <MenuItem
          sx={{
            display:
              contract?.contractPaymentDetails?.paymentMethod ===
                'STRIPE_SEPA' &&
              contract?.stripeSepaPaymentDetailsAreConfigured === true &&
              (contract?.contractStatus == 'CREATED_UNSIGNED' ||
                contract?.contractStatus == 'CREATED_SIGNED' ||
                contract?.contractStatus == 'ACTIVE' ||
                contract?.contractStatus == 'BLOCKED')
                ? true
                : 'none',
          }}
          onClick={() => {
            setRedoStripeSepaOpenPopup(true);
            setAnchorEl(null);
          }}
        >
          <Typography align="center" style={{ width: 220 }}>
            {t('redoStripeSepaPaymentDetails')}
          </Typography>
        </MenuItem>
      </Menu>
      <Popup
        open={openPopup}
        message={
          <>
            <Typography
              align="center"
              fontWeight={700}
              fontSize={typography.h5.fontSize}
              pt={8}
            >
              {t('youwanttoterminate')}
            </Typography>
          </>
        }
        handleClose={closeFeedback}
        closeButtonBgColour="grey"
        closeButtonTxtColour="black"
        handleAction={handlePopupAction}
      />

      <Popup
        open={redoStripeSepaOpenPopup}
        message={
          <>
            <Typography
              align="center"
              fontWeight={700}
              fontSize={typography.h5.fontSize}
              pt={8}
            >
              {t('youWantToRedoStripeSepaPaymentDetailsForUser')}
            </Typography>
          </>
        }
        handleClose={closeModalsRedoStripeSepa}
        closeButtonBgColour="grey"
        closeButtonTxtColour="black"
        handleAction={handlePopupActionRedoStripeSepa}
      />

      <Feedback
        open={successFeedback}
        title={t('userTerminated')}
        message={
          <>
            <Typography align="center">
              {t('userterminatednoaccess')}
            </Typography>
          </>
        }
        handleClose={closeFeedback}
      />

      <Feedback
        open={userDeleteSuccessFeedback}
        title={t('LBLUerDeleted')}
        message={
          <>
            <Typography align="center">{t('userdeletednoaccess')}.</Typography>
          </>
        }
        handleClose={() => {
          setUserDeleteSuccessFeedback(false);
          navigate('/nutzer');
        }}
      />

      <Feedback
        open={redoStripeSepaSuccessFeeback}
        title={t('TitleNewStripeSepaPaymentRequestDone')}
        message={
          <>
            <Typography align="center">
              {t('MsgNewStripeSepaPaymentRequestDone')}.
            </Typography>
          </>
        }
        handleClose={closeFeedback}
      />

      <StepperPopup
        widthInput="1000"
        title={t('editUser')}
        isOpen={editUserDetailsForm}
        setIsOpen={setEditUserDetailsForm}
        steps={[
          {
            label: t('enterUserinfo'),
            component: (
              <UserCreationForm
                setPayload={handleSetPayload}
                handleClose={onClose}
                confirmPayload={handleNext}
                payload={contract}
                setSubmit={setSubmit}
                setCanNext={setCanNext}
                submit={submit}
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmit((prevState) => prevState + 1);
              },
              canNext: canNext,
            },
          },
          {
            label: t('authInfo'),
            component: (
              <UserCreationFormLocation
                setPayloadOne={handleSetPayloadOne}
                handleClose={onCloseOne}
                confirmPayload={handleNextevent}
                payload={contract}
                setSubmit={setSubmitOne}
                setCanNext={setCanNextOne}
                submit={submitOne}
                editFlag={true}
                CreateUpdate="update"
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmitOne((prevState) => prevState + 1);
              },
              canNext: canNextOne,
            },
          },
          {
            label: t('contractterms'),
            component: (
              <UserCreationFormContract
                setPayload={handleSetPayloadTwo}
                handleClose={onCloseERP}
                confirmPayload={handleNext}
                payload={contract}
                setSubmit={setSubmitTwo}
                setCanNext={setCanNextTwo}
                submit={submitTwo}
                editFlag={true}
                pdfFlag={contract?.pdfAvailable}
                cpoPaymentPreference={payloadOne?.cpoPaymentPreference}
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmitTwo((prevState) => prevState + 1);
              },
              canNext: canNextTwo,
            },
          },
          {
            label: t('otherinfo'),
            component:
              payloadTwo?.billingType != null ? (
                <UserCreationFormERP
                  setPayload={handleSetPayloadERP}
                  handleClose={onCloseTwo}
                  confirmPayload={handleNext}
                  payload={contract}
                  billingTypeValue={payloadTwo?.billingType}
                  setSubmit={setSubmitERP}
                  setCanNext={setCanNextERP}
                  submit={submitERP}
                  cpoPaymentPreference={payloadOne?.cpoPaymentPreference}
                  editFlag={true}
                />
              ) : (
                <UserCreationFormERPInitial />
              ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmitERP((prevState) => prevState + 1);
              },
              canNext: canNextERP,
            },
          },
          {
            label: t('confirmInfo'),
            component: (
              <ConfirmUser
                payload={{
                  firstName: payload?.firstName,
                  lastName: payload?.lastName,
                  email: payload?.email,
                  contractAddress: payload?.contractAddress,
                  locationId: payloadOne?.locationId,
                  locationName: payloadOne?.locationName,
                  parkingLotNumbers: payloadOne?.parkingLotNumbers,
                  cpoId: payloadOne?.cpoId,
                  authType: payloadOne?.authType, // can be "APP" or "RFID"
                  billingType: payloadTwo?.billingType, // can be "WITH_BILLING" or "NO_BILLING"
                  rfidTags: payloadOne?.rfidTags,
                  rfidSTags: payloadOne?.rfidSTags,
                  contractInfo: payloadTwo?.contractInfo,
                  contractPaymentDetails: payloadERP?.contractPaymentDetails,
                  erpSystemParameters: payloadERP?.erpSystemParameters,
                  paymentMethod: payloadERP?.paymentMethod,
                  userType: payloadOne?.userType,
                }}
                handleClose={onClose}
                openFeedback={openFeedback}
                backToForm={handlePrev}
                setError={setError}
                setrfidError={setrfidError}
                setrfidSError={setrfidSError}
                setemailError={setemailError}
                setEndCustomerId={setEndCustomerId}
                submit={confirmed}
                setSubmit={setConfirmed}
                editFlag={true}
                contract={contract}
              />
            ),
            nextAction: {
              label: t('LBLSave'),
              action: () => {
                setConfirmed((prevState) => prevState + 1);
                setEditUserDetailsForm(false);
                setLoading(true);
              },
            },
          },
        ]}
      />
      <StepperPopup
        title={t('editTariffTitle')}
        isOpen={editUserTarifForm}
        setIsOpen={setEditTarifForm}
        steps={[
          {
            label: t('editTariffTitle'),
            component: (
              <UserCreationFormContractEdit
                setPayload={handleSetPayloadTariff}
                handleClose={onCloseERP}
                confirmPayload={handleNext}
                payload={contract}
                setSubmit={setSubmitTariff}
                setCanNext={setCanNextTariff}
                submit={submitTariff}
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmitTariff((prevState) => prevState + 1);
              },
              canNext: canNextTariff,
            },
          },
          {
            label: t('confirmTariff'),
            component: (
              <ConfirmUserTariffEdit
                payload={{
                  contractKey: contract?.contractKey,
                  fixTariff: payloadTariff?.fixTariff.toString(),
                  varTariff: payloadTariff?.varTariff.toString(),
                  fixTariffPerChargingRequest:
                    payloadTariff?.fixTariffPerChargingRequest.toString(),
                  onboardingFee: payloadTariff?.onboardingFee.toString(),
                  newTariffDate: payloadTariff?.newTariffDate,
                  newTariffCancellationDate:
                    payloadTariff?.newTariffCancellationDate,
                }}
                handleClose={onClose}
                openFeedback={handleOpenFeedbackTariff}
                backToForm={handlePrev}
                setError={setError}
                setSubmittingTariff={setSubmittingTariff}
                setemailError={setemailError}
                submit={confirmed}
                setSubmit={setConfirmed}
                editFlag={true}
                contract={contract}
              />
            ),
            nextAction: {
              label: t('LBLSave'),
              action: () => {
                setConfirmed((prevState) => prevState + 1);
                setEditUserDetailsForm(false);
                setLoading(true);
              },
            },
          },
        ]}
      />
      <Feedback
        open={completed}
        title={t('userInfoUpdated')}
        message={
          <>
            <Typography align="center">{t('userinfoHasbeenUp')}</Typography>
          </>
        }
        handleClose={closeFeedback}
      />
      <Feedback_Error
        open={error}
        title={t('mistake')}
        message={
          <>
            <Typography align="center">{t('LBLSomethingwentWrong')}</Typography>
          </>
        }
        severity="warning"
        handleClose={() => {
          setError(false);
          setPayload(null);
        }}
      />

      <Feedback_Error
        open={redoStripeSepaError.open}
        title={redoStripeSepaError.title}
        message={
          <>
            <Typography align="center">
              {redoStripeSepaError.txtMessage}
            </Typography>
          </>
        }
        severity={redoStripeSepaError.severity}
        handleClose={() => {
          setRedoStripeSepaError(initDataRedoStripeSepaErrorMessageOpenFalse);
          setError(false);
          setRefreshTheData((prevState) => prevState + 1);
          setIsRedoStripeSepa(false);
        }}
      />

      <Feedback
        open={rfidError}
        title={t('rfidInuse')}
        message={
          <>
            <Typography align="center">
              {t('rfiduserconnected')}:
              <Typography marginTop={3}>
                <TableContainer component={Paper} variant="outlined">
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('LBLCustomerNum')}</TableCell>
                        <TableCell>
                          {rfidError &&
                          rfidError.some(
                            (val) =>
                              Array.isArray(val.serialId) &&
                              val.serialId[0]?.trim()
                          )
                            ? 'Serial Number'
                            : 'RFID Tags'}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rfidError?.map((val, key) => {
                        const serialId =
                          Array.isArray(val.serialId) &&
                          val.serialId[0]?.trim();
                        const rfIdTag =
                          Array.isArray(val.rfIdTag) && val.rfIdTag[0];
                        const displayValue = serialId || rfIdTag;
                        return (
                          <TableRow key={key}>
                            <TableCell>{val.endCustomerId}</TableCell>
                            <TableCell>{displayValue}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Typography>
            </Typography>
          </>
        }
        handleClose={() => {
          setError(false);
          setPayload(null);
          setrfidError(null);
          setrfidSError(null);
          setemailError(false);
        }}
      />

      <FailFeedback
        open={rfidSError}
        title={t('rfidSInuse')}
        severity="warning"
        message={
          <>
            <Typography align="center">
              <Trans
                i18nKey="rfidSInuseBody"
                values={{ errorDetails: rfidSError?.join(', ') }}
                components={{ strong: <strong /> }}
              />
            </Typography>
          </>
        }
        handleClose={() => {
          setError(false);
          setPayload(null);
          setrfidError(null);
          setrfidSError(null);
          setemailError(false);
        }}
      />
      <FailFeedback
        open={emailError}
        title={t('emailinuse')}
        severity="warning"
        message={
          <>
            <Typography align="center">
              {t('emailinuser', { endCustomerId: endCustomerId })}
            </Typography>
          </>
        }
        handleClose={() => {
          setemailError(false);
          setPayload(null);
          setPayloadOne(null);
          setPayloadTwo(null);
          setPayloadERP(null);
          setLoading(false);
        }}
      />
      <ConfirmationBox
        open={showConfirmationModal}
        title={t('ConfirmationTitle')}
        message={t('confirmationUserDeleteMsg')}
        handleSubmit={() => deleteUserContract()}
        handleClose={() => setShowConfirmationModal(false)}
      />
    </>
  );
}