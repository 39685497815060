import React from 'react';
import Logo from '../../vendor/oli-sharEnergy.png';
import { Container, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

import ForgetPasswordComponent from '../../components/auth/ForgetPassword';

const ForgetPassword = () => {
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(i18n.language);
  const [urlVal, setUrlVal] = useState(window.location.href);

  const [showTranslator, setShowTranslator] = useState(false);
  const changeLanguageHandler = (languageValue) => {
    i18n.changeLanguage(languageValue);
    setCurrentLang(languageValue);
  };

  useEffect(() => {
    setUrlVal(window.location.href);
  }, []);

  useEffect(() => {
    if (
      window.location.href.includes('localhost') ||
      window.location.href.includes('dev') ||
      window.location.href.includes('int')
    ) {
      setShowTranslator(true);
    }
  }, [urlVal]);

  return (
    <>
      {showTranslator == true ? (
        <div
          style={{
            float: 'right',
            width: '100%',
            textAlign: 'right',
            position: 'fixed',
            top: 0,
            marginRight: '10px',
            backgroundColor: 'transparent',
          }}
        >
          <div
            onClick={() => changeLanguageHandler('en')}
            style={{
              float: 'right',
              cursor: 'pointer',
              margin: '5px',
              color: currentLang == 'en' ? '#7faa40' : 'grey',
            }}
          >
            En
          </div>
          <div
            onClick={() => changeLanguageHandler('de')}
            style={{
              float: 'right',
              cursor: 'pointer',
              margin: '5px',
              color: currentLang != 'en' ? '#7faa40' : 'grey',
            }}
          >
            De
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <Helmet title="Forget Password" />
      <img src={Logo} alt="logo" width={200} height={189} />
      <Container maxWidth="md">
        <Typography
          component="h1"
          variant="h3"
          align="center"
          sx={{ mt: 8, mb: 6 }}
        >
          {t('BTNforgotPassword')}
        </Typography>
        <Typography
          component="h2"
          variant="body1"
          align="center"
          sx={{ maxWidth: 720, mx: 'auto' }}
          gutterBottom
        >
          {t('LBLForgetPassword')}
        </Typography>
        <ForgetPasswordComponent />
      </Container>
    </>
  );
};

export default ForgetPassword;