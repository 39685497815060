import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import LightTooltip from '../Users/components/CreateUser/LightTooltip';
import { useTranslation } from 'react-i18next';

function UpdateLocationInformationForm({
  locationInfo,
  confirmPayload,
  setPayload,
  submit,
  setCanNext,
  setSubmit,
}) {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const [operators, setOperators] = useState([locationInfo?.operatorDetail]);
  const openCpoIdTooltip = (name) => () => setCpoIdTooltipOpen(name);
  const closeEndDateTooltip = () => setCpoIdTooltipOpen('');
  const [cpoIdTooltipOpen, setCpoIdTooltipOpen] = useState('');
  useEffect(() => {
    if (submit > 0) document.getElementById('sub').click();
    setSubmit(0);
  }, [submit]);

  const initialValues = {
    locationId: locationInfo?.locationId || '',
    locationName: locationInfo?.locationName || '',
    careOf: locationInfo?.locationAddress.careOf || '',
    city: locationInfo?.locationAddress.city || '',
    street: locationInfo?.locationAddress.street || '',
    zipCode: locationInfo?.locationAddress.zipCode || '',
    cpoId: locationInfo?.operatorDetail?.cpoId || '',
    cpoName: locationInfo?.operatorDetail?.cpoName || '',
  };

  const validationSchema = useMemo(
    () =>
      yup.object({
        locationName: yup.string().required(t('LNameReq')),
        careOf: yup.string(),
        city: yup.string().required(t('LBLLocReq')),
        street: yup.string().required(t('LBLNameReq')),
        zipCode: yup.string().required(t('ZipcodeReq')),
      }),
    [locationInfo]
  );

  /** @type {import("formik").FormikConfig<typeof initialValues>['onSubmit']} */
  const handleSubmit = (values, actions) => {
    const payload = {
      locationId: locationInfo?.locationId,
      locationName: values.locationName,
      locationAddress: {
        careOf: values.careOf,
        city: values.city,
        street: values.street,
        zipCode: values.zipCode,
      },
      cpoId: values.cpoId,
      cpoName: locationInfo?.operatorDetail?.cpoName,
    };

    setPayload(payload);
    confirmPayload();
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, values, handleChange, isValid, touched }) => (
        <Form noValidate>
          <>
            {setCanNext(isValid)}
            <Stack direction="column">
              <Grid container>
                <Grid container md={12} spacing={2}>
                  <Grid item xs>
                    <TextField
                      margin="dense"
                      id="standortName"
                      label={t('Lname')}
                      name="locationName"
                      variant="outlined"
                      value={values.locationName}
                      onChange={handleChange}
                      error={
                        touched.locationName && Boolean(errors.locationName)
                      }
                      helperText={touched.locationName && errors.locationName}
                      required
                      fullWidth
                      my={2}
                    />
                  </Grid>
                  <Grid item xs></Grid>
                </Grid>
                <Grid container md={12} spacing={2}>
                  <Grid item xs>
                    <TextField
                      margin="dense"
                      id="locationStreetAndNumber"
                      label={t('lblStName')}
                      value={values.street}
                      onChange={handleChange}
                      error={touched.street && Boolean(errors.street)}
                      helperText={touched.street && errors.street}
                      variant="outlined"
                      required
                      fullWidth
                      my={2}
                      name="street"
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      margin="dense"
                      id="flatNr"
                      value={values.careOf}
                      onChange={handleChange}
                      error={touched.careOf && Boolean(errors.careOf)}
                      helperText={touched.careOf && errors.careOf}
                      label="c/o"
                      variant="outlined"
                      fullWidth
                      my={2}
                      name="careOf"
                    />
                  </Grid>
                </Grid>
                <Grid container md={12} spacing={2}>
                  <Grid item xs>
                    <TextField
                      margin="dense"
                      id="zipCode"
                      value={values.zipCode}
                      onChange={handleChange}
                      error={touched.zipCode && Boolean(errors.zipCode)}
                      helperText={touched.zipCode && errors.zipCode}
                      label={t('PostalCode')}
                      variant="outlined"
                      required
                      fullWidth
                      my={2}
                      name="zipCode"
                    />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      margin="dense"
                      id="city"
                      value={values.city}
                      onChange={handleChange}
                      error={touched.city && Boolean(errors.city)}
                      helperText={touched.city && errors.city}
                      label={t('city')}
                      variant="outlined"
                      required
                      fullWidth
                      my={2}
                      name="city"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12} spacing={2} sx={{ pt: 2 }}>
                  <Typography variant="h4">{t('MenuOp')}</Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Stack
                    direction="row"
                    alignItems="flex-start"
                    style={{ marginTop: '32px' }}
                  >
                    <LightTooltip
                      open={cpoIdTooltipOpen === 'endDateToolTip'}
                      onOpen={openCpoIdTooltip('endDateToolTip')}
                      onClose={closeEndDateTooltip}
                      placement="top-start"
                      title={t('opidcantchange')}
                    >
                      <InfoIcon
                        color="action"
                        fontSize="small"
                        sx={{ position: 'absolute', display: 'flex', ml: -3 }}
                      />
                    </LightTooltip>
                  </Stack>
                  <Grid item xs>
                    <TextField
                      select
                      disabled
                      fullWidth
                      label={t('OpIdName')}
                      value={values.cpoId}
                      onChange={handleChange}
                      error={touched.cpoId && Boolean(errors.cpoId)}
                      helperText={
                        (touched.cpoId && errors.cpoId) ||
                        operators.length === 0
                          ? t('plcreateOp')
                          : ''
                      }
                      name="cpoId"
                    >
                      {operators.map((operator, index) => (
                        <MenuItem key={index} value={operator.cpoId}>
                          {operator.cpoId + ': ' + operator.cpoName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs></Grid>
                </Grid>
              </Grid>
            </Stack>
            <Button sx={{ display: 'none' }} id="sub" type="submit"></Button>
          </>
        </Form>
      )}
    </Formik>
  );
}

UpdateLocationInformationForm.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any),
  setPayload: PropTypes.func.isRequired,
  confirmPayload: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UpdateLocationInformationForm;
