import React, { lazy, Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import OuterCompanyDetails from './OuterCompanyDetails';
import OuterUserDetails from './OuterUserDetails';

import OuterCutomerConfirm from './OuterCutomerConfirm';
import StepperPopup from '../../../../components/StepperPopup';
import { ArrowForward } from '@mui/icons-material';
import LogoLoader from '../../../../components/LogoLoader';
import { useTranslation } from 'react-i18next';

const Feedback = lazy(() => import('../../../../components/Feedback'));
const FailFeedback = lazy(
  () => import('../../../../components/DataTable/Feedback')
);
/**
 * @param {PropTypes.InferProps<FirstCustomerCreate.propTypes>} props
 */
const FirstCustomerCreate = ({ open, handleClose, setOpen }) => {
  const { t } = useTranslation();
  const [payload, setPayload] = useState(null);
  const [payloadOne, setPayloadOne] = useState(null);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  // const [isFormUnmounted, setIsFormUnmounted] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [submit, setSubmit] = useState(0);
  const [submitOne, setSubmitOne] = useState(0);
  const [canNext, setCanNext] = useState(false);
  const [canNextOne, setCanNextOne] = useState(false);
  const [confirmed, setConfirmed] = useState(0);

  const [error, setError] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorId, setErrorId] = useState(false);
  const handleNext = () => setStep(step + 1);
  const handlePrev = () => setStep(step - 1);

  const openFeedback = () => {
    setPayload(null);
    setPayloadOne(null);
    setCompleted(true);
  };
  const closeFeedback = () => {
    setPayload(null);
    setPayloadOne(null);
    setCompleted(false);
    handleClose();
  };
  /** @type {import("@mui/material").DialogProps['onClose']} */
  const onClose = (_event, reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
      setPayload(null);
      setPayloadOne(null);
      setStep(0);
    }
  };

  /** @type {import("@mui/material").DialogProps['onCloseOne']} */
  const onCloseOne = (_event, reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
      setPayloadOne(null);
      setStep(1);
    }
  };

  const handleSetPayload = (input) => {
    setPayload(input);
  };
  const handleSetPayloadOne = (input) => {
    setPayloadOne(input);
  };

  useEffect(() => {
    setLoading(false);
  }, [completed, payload, payloadOne, error]);
  return (
    <>
      <LogoLoader loading={loading} />
      <StepperPopup
        widthInput="1000"
        title={t('custCreate')}
        isOpen={open}
        setIsOpen={setOpen}
        steps={[
          {
            label: t('companyInfo'),
            component: (
              <OuterCompanyDetails
                setPayload={handleSetPayload}
                handleClose={onClose}
                confirmPayload={handleNext}
                payload={payload}
                setSubmit={setSubmit}
                setCanNext={setCanNext}
                submit={submit}
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmit((prevState) => prevState + 1);
              },
              canNext: canNext,
            },
          },

          {
            label: t('userInfo'),
            component: (
              <OuterUserDetails
                setPayloadOne={handleSetPayloadOne}
                handleClose={onCloseOne}
                confirmPayload={handleNext}
                payload={payloadOne}
                setSubmit={setSubmitOne}
                setCanNext={setCanNextOne}
                submit={submitOne}
                editFlag={false}
                CreateUpdate="create"
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmitOne((prevState) => prevState + 1);
              },
              canNext: canNextOne,
            },
          },
          {
            label: t('confirmInfo'),
            component: (
              <OuterCutomerConfirm
                payload={{
                  companyName: payload?.companyName,
                  customerId: payload?.customerId,
                  customerType: payload?.customerType,
                  address: payload?.address,
                  firstName: payloadOne?.firstName,
                  lastName: payloadOne?.lastName,
                  role: payloadOne?.role,
                  email: payloadOne?.email,
                  productPackage: payloadOne?.productPackage,
                }}
                handleClose={onClose}
                openFeedback={openFeedback}
                backToForm={handlePrev}
                setError={setError}
                setemailError={setErrorEmail}
                setIdError={setErrorId}
                submit={confirmed}
                setSubmit={setConfirmed}
                editFlag={false}
                contract={null}
              />
            ),
            nextAction: {
              label: t('LBLSave'),
              action: () => {
                setConfirmed((prevState) => prevState + 1);
                setOpen(false);
                setLoading(true);
              },
            },
          },
        ]}
      />
      <Suspense fallback={<div />}>
        <Feedback
          open={completed}
          title={t('newusercreated')}
          message={
            <>
              <Typography align="center">{t('thankforInput')}</Typography>

              <Typography align="center">
                {<>{t('succMsgNewCust')}</>}
              </Typography>
            </>
          }
          handleClose={closeFeedback}
        />
        <FailFeedback
          open={error}
          title={t('mistake')}
          severity="warning"
          message={
            <>
              <Typography align="center">
                {t('LBLSomethingwentWrong')}
              </Typography>
            </>
          }
          handleClose={() => {
            setError(false);
            setPayload(null);
            setPayloadOne(null);
          }}
        />
        <FailFeedback
          open={errorEmail}
          title={t('mistake')}
          severity="warning"
          message={
            <>
              <Typography align="center">{t('emailinuse')}</Typography>
            </>
          }
          handleClose={() => {
            setError(false);
            setErrorEmail(false);
            setErrorId(false);
            setPayload(null);
            setPayloadOne(null);
          }}
        />

        <FailFeedback
          open={errorId}
          title={t('mistake')}
          severity="warning"
          message={
            <>
              <Typography align="center">{t('Idinuse')}</Typography>
            </>
          }
          handleClose={() => {
            setError(false);
            setErrorEmail(false);
            setErrorId(false);
            setPayload(null);
            setPayloadOne(null);
          }}
        />
      </Suspense>
    </>
  );
};
FirstCustomerCreate.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default FirstCustomerCreate;
