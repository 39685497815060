import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import api from '../../../../Middleware/api/api';
import { useAuth } from '../../../../services/contexts';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

function StationForm({
  locationIdInput,
  setPayload,
  setCanNext,
  submit,
  setSubmit,
}) {
  const { t } = useTranslation();
  const validationSchema = yup.object().shape({
    chargingStationModel: yup.string().required(t('LBLReqMSG')),
    location: yup.string().required(t('locReq')),
    numOfChargingStations: yup
      .number()
      .min(1, t('csmorezero'))
      .required(t('csmorezero')),
  });
  const [locations, setLocations] = useState([]);
  const [wallboxInfo, setWallboxInfo] = useState([]);

  const { token } = useAuth();
  useEffect(() => {
    if (submit > 0) document.getElementById('sub').click();
    setSubmit(0);
  }, [submit]);
  const formik = useFormik({
    initialValues: {
      chargingStationModel: '',
      location: '',
      locationIdInput: null,
      numOfChargingStations: 0,
    },
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setPayload(values);
    },
  });
  useEffect(() => {
    api
      .fetchLocations(token, 0, 10000)
      .then((response) => setLocations(response?.data?.data.response));
    api.fetchWallboxTypes(token).then((response) => {
      if (response.data.data == null) {
        setWallboxInfo([]);
      } else {
        setWallboxInfo(response.data.data);
      }
    });
  }, []);

  if (locationIdInput != null && locations.length > 0) {
    locations.map((singleLocation) => {
      if (singleLocation.locationId == locationIdInput) {
        formik.values.location =
          singleLocation.locationId + ': ' + singleLocation.locationName;
        formik.values.locationIdInput = locationIdInput;
      }
    });
  }
  useEffect(() => {
    setCanNext(formik.isValid);
  }, [formik.isValid]);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container sx={{ width: '100%' }} spacing={2}>
          <Grid item xs>
            <TextField
              select
              disabled={formik.values.locationIdInput != null}
              label={t('LBLLocation')}
              fullWidth
              name="location"
              onChange={formik.handleChange}
              value={formik.values.location}
              error={
                !locations ||
                locations.length === 0 ||
                (formik.touched.location && Boolean(formik.errors.location))
              }
              helperText={
                (!locations || locations.length === 0 ? t('noLoc') : null) ||
                (formik.touched.location && formik.errors.location)
              }
            >
              {locations.map((location, index) => (
                <MenuItem
                  key={index}
                  value={location.locationId + ': ' + location.locationName}
                >
                  {location.locationId + ': ' + location.locationName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid xs />
        </Grid>
        <Grid container sx={{ width: '100%' }}>
          <Grid item xs paddingRight={2}>
            <TextField
              label={t('LBLNumberOfCharginStations')}
              type="number"
              fullWidth
              name="numOfChargingStations"
              value={formik.values.numOfChargingStations}
              onChange={formik.handleChange}
              error={
                formik.touched.numOfChargingStations &&
                Boolean(formik.errors.numOfChargingStations)
              }
              helperText={
                formik.touched.numOfChargingStations &&
                formik.errors.numOfChargingStations
              }
            ></TextField>
          </Grid>
          <Grid xs>
            <TextField
              select
              label={t('csModel')}
              type="number"
              fullWidth
              name="chargingStationModel"
              onChange={formik.handleChange}
              value={formik.values.chargingStationModel}
              error={
                formik.touched.chargingStationModel &&
                Boolean(formik.errors.chargingStationModel)
              }
              helperText={
                formik.touched.chargingStationModel &&
                formik.errors.chargingStationModel
              }
            >
              {wallboxInfo.map((model, index) => (
                <MenuItem key={index} value={model}>
                  {model}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Button sx={{ display: 'none' }} id="sub" type="submit"></Button>
      </form>
    </>
  );
}

export default StationForm;