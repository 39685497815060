import React, { lazy, Suspense, useEffect, useState } from 'react';
import LogoLoader from '../../components/LogoLoader';
import { Typography, Menu, MenuItem, Button } from '@mui/material';
import shadows from '@mui/material/styles/shadows';
import StepperPopup from '../../components/StepperPopup';
import { ArrowForward } from '@mui/icons-material';
import ConfirmLocatoinInformation from './ConfirmLocatoinInformation';
import UpdateLocationInformationForm from './UpdateLocationInformationForm';
import Feedback from '../../components/Feedback';
import Feedback_Error from '../../components/DataTable/Feedback';
import { useTranslation } from 'react-i18next';
import StationForm from '../Stations/components/StationForm/StationForm';
import StationConfirmation from '../Stations/components/StationForm/StationConfirmation';
function EditLocationInformation({
  locationInfo,
  locationId,
  setRefreshTheData,
}) {
  const { t } = useTranslation();
  const [updateLocationInformationForm, setUpdateLocationInformationForm] =
    useState(false);
  const [payload, setPayload] = useState(locationInfo);
  const [payloadStation, setPayloadStation] = useState(null);
  const [step, setStep] = useState(0);
  const [canNext, setCanNext] = useState(false);
  const [canNextStation, setCanNextStation] = useState(false);
  const [submit, setSubmit] = useState(0);
  const [submitStation, setSubmitStation] = useState(0);
  const openFeedback = () => setCompleted(true);
  const [confirmed, setConfirmed] = useState(0);
  const [confirmedStation, setConfirmedStation] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(false);
  const [errorStation, setErrorStation] = useState(null);
  const [completed, setCompleted] = useState(false);

  const handleNext = () => setStep(step + 1);
  // eslint-disable-next-line no-unused-vars
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElStation, setAnchorElStation] = React.useState(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(false);
  const openStation = Boolean(anchorElStation);
  const [isOpenStation, setIsOpenStation] = useState(false);
  const closeFeedback = () => {
    setCompleted(false);
    setPayload(null);
    setRefreshTheData((prevState) => prevState + 1);
    setError(false);
  };
  const handleSetPayload = (input) => {
    setPayload(input);
  };

  const setResponseStation = (input) => {
    setResponse(input);
    if (input?.data?.data) {
      setRefreshTheData(1);
    }
  };

  useEffect(() => {
    setLoading(false);
  }, [completed, error, response, errorStation]);

  const handleClick = (event) => {
    setAnchorElStation(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElStation(null);
  };
  const onClose = () => {
    setAnchorElStation(null);
  };

  const FailFeedback = lazy(
    () => import('../../components/DataTable/Feedback')
  );
  return (
    <>
      <LogoLoader loading={loading} />
      <Button
        id="demo-positioned-button"
        aria-controls={openStation ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openStation ? 'true' : undefined}
        onClick={handleClick}
        variant="contained"
        sx={{
          position: 'absolute',
          boxShadow: shadows[3],
          top: ({ spacing }) => spacing(10),
          right: ({ spacing }) => spacing(10),
          zIndex: ({ zIndex }) => zIndex.appBar,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {t('factions')}
      </Button>
      <Menu
        anchorEl={anchorElStation}
        open={openStation}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'edit-selected',
        }}
        sx={{
          textAlign: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            setUpdateLocationInformationForm(true);
            handleClose();
          }}
        >
          <Typography align="center" sx={{ width: '100%' }}>
            {t('editLocation')}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsOpenStation(true);
            handleClose();
          }}
        >
          {t('LBLCreateCharginStations')}
        </MenuItem>
      </Menu>

      <StepperPopup
        isOpen={isOpenStation}
        setIsOpen={setIsOpenStation}
        title={t('LBLCreateCharginStations')}
        steps={[
          {
            label: t('EnterINfo'),
            component: (
              <StationForm
                locationIdInput={locationId}
                setSubmit={setSubmitStation}
                submit={submitStation}
                payload={payloadStation}
                setPayload={setPayloadStation}
                setCanNext={setCanNextStation}
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmitStation((prev) => prev + 1);
              },
              canNext: canNextStation,
            },
          },
          {
            label: t('confirmInfo'),
            component: (
              <StationConfirmation
                payload={payloadStation}
                submit={confirmedStation}
                setError={setErrorStation}
                setResponse={setResponseStation}
              />
            ),
            nextAction: {
              label: t('LBLSave'),
              icon: <></>,
              action: () => {
                setConfirmedStation(true);
                setIsOpenStation(false);
                setLoading(true);
              },
            },
          },
        ]}
      />
      <Suspense fallback={<div />}>
        <Feedback
          open={!!response}
          title={t('newCsDone')}
          message={
            <>
              <Typography align="center">{t('thankforInput')}</Typography>
              <Typography align="center">
                {t('newCssite')} <b>{response?.data?.data}</b> {t('beensetup')}.
                {t('teamwillbeintouch')}
              </Typography>
            </>
          }
          handleClose={() => {
            setResponse(null);
            setPayloadStation(null);
            setConfirmedStation(false);
            onClose();
          }}
        />
        <FailFeedback
          open={!!error}
          title={'Fehler'}
          severity="warning"
          message={
            <>
              <Typography align="center">
                {t('LBLSomethingwentWrong')}
              </Typography>
            </>
          }
          handleClose={() => {
            setError(null);
            setPayload(null);
            setConfirmed(false);
          }}
        />
      </Suspense>
      <StepperPopup
        widthInput="1000"
        title={t('editLocation')}
        isOpen={updateLocationInformationForm}
        setIsOpen={setUpdateLocationInformationForm}
        steps={[
          {
            label: t('EnterLocation'),
            component: (
              <UpdateLocationInformationForm
                locationInfo={locationInfo}
                setPayload={handleSetPayload}
                confirmPayload={handleNext}
                setCanNext={setCanNext}
                submit={submit}
                setSubmit={setSubmit}
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmit((prevState) => prevState + 1);
              },
              canNext: canNext,
            },
          },

          {
            label: t('confirmInfo'),
            component: (
              <ConfirmLocatoinInformation
                payload={payload}
                submit={confirmed}
                setError={setError}
                setSubmit={setConfirmed}
                openFeedback={openFeedback}
              />
            ),
            nextAction: {
              label: t('LBLSave'),
              action: () => {
                setConfirmed((prevState) => prevState + 1);
                setUpdateLocationInformationForm(false);
                setLoading(true);
                setSubmit(0);
              },
            },
          },
        ]}
      />

      <Feedback
        open={completed}
        title={t('Locationupdate')}
        message={
          <>
            <Typography align="center">{t('locationInfohasbeenUp')}</Typography>
          </>
        }
        handleClose={closeFeedback}
      />
      <Feedback_Error
        open={error}
        title={'Fehler'}
        message={
          <>
            <Typography align="center">{t('LBLSomethingwentWrong')}</Typography>
          </>
        }
        severity="warning"
        handleClose={() => {
          setError(false);
          setPayload(null);
        }}
      />
    </>
  );
}

export default EditLocationInformation;