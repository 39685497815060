import React, { lazy, Suspense, useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import Loader from '../../vendor/logoOli.svg';
import Backdrop from '@mui/material/Backdrop';
import api from '../../Middleware/api/api';
import { useUser, useAuth } from '../../services/contexts';
import { base64ToArrayBuffer, getBase64 } from '../../services/utils/pdf-byte';
import { useTranslation } from 'react-i18next';
import LightTooltip from '../Users/components/CreateUser/LightTooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';

const Feedback = lazy(() => import('../../components/Feedback'));
const FailFeedback = lazy(() => import('../../components/DataTable/Feedback'));

const Help = () => {
  const { token } = useAuth();
  const { user } = useUser();
  const { t } = useTranslation();
  const ACCESS_TOKEN = token;
  const USER_NAME = user?.name;
  const USER_EMAIL = user?.email;

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState([]);
  const [error, setError] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState('');

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    // Add your file type validation here if needed
    if (
      file &&
      (file.type === 'application/pdf' || file.type.startsWith('image/'))
    ) {
      let fileInByteArrayForm;

      const _base64 = await getBase64(file);
      const [, base64] = _base64.split(',');

      fileInByteArrayForm = Array.from(
        new Uint8Array(base64ToArrayBuffer(base64))
      );

      setFormData((prevData) => ({
        ...prevData,
        attachments: [fileInByteArrayForm],
      }));
    } else {
      alert('Please select a valid PDF or image file.');
    }
  };

  const closeFeedback = () => {
    setCompleted(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const openTooltip = (name) => () => setTooltipOpen(name);
  const closeTooltip = () => setTooltipOpen('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { subject } = formData;
    const payload = {
      ...formData,
      ['subject']: `${subject}`,
      senderEmailAddress: USER_EMAIL,
      senderFullName: USER_NAME,
    };

    setLoading(true);

    try {
      await api.postFeedback(ACCESS_TOKEN, payload);
      setCompleted(true);
      e.target.reset(); // reset form after submit
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {loading && (
        <Backdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <img src={Loader} alt="loader" className="loader" />
        </Backdrop>
      )}

      <div className="hilfe">
        <Grid container direction="column">
          <Grid item style={{ textAlign: 'center' }}>
            <h1 style={{ paddingBottom: 30 }}>{t('LBLSupportTitle')}</h1>
            <p style={{ paddingBottom: 5, fontSize: 14 }}>
              {t('LBLSupportSubHeading')}
            </p>
          </Grid>
        </Grid>

        <form onSubmit={handleSubmit} onChange={handleChange}>
          <Grid container spacing={6} direction="column" alignItems="center">
            <Grid item>
              <TextField
                required
                id="subject"
                type="text"
                name="subject"
                margin="dense"
                label={t('LBLSubject')}
                sx={{
                  width: 400,
                  background: '#ffffff',
                }}
                onInvalid={(e) =>
                  e.target.setCustomValidity('Bitte füllen Sie das Feld aus')
                }
                onInput={(e) => e.target.setCustomValidity('')}
              ></TextField>
            </Grid>

            <Grid item>
              <TextField
                required
                multiline
                type="text"
                minRows="12"
                maxRows="20"
                id="body"
                margin="dense"
                name="body"
                label={t('LBLSupportMessage')}
                sx={{
                  width: 400,
                  background: '#ffffff',
                }}
                onInvalid={(e) =>
                  e.target.setCustomValidity('Bitte füllen Sie das Feld aus')
                }
                onInput={(e) => e.target.setCustomValidity('')}
              ></TextField>
            </Grid>

            <Grid item>
              <LightTooltip
                open={tooltipOpen === 'attachments'}
                onOpen={openTooltip('attachments')}
                onClose={closeTooltip}
                placement="top-start"
                title={t('supportFileFormat')}
              >
                <InfoIcon
                  color="action"
                  fontSize="small"
                  sx={{ position: 'absolute', ml: -6, mt: 5 }}
                />
              </LightTooltip>
              <TextField
                type="file"
                id="attachments"
                margin="dense"
                name="attachments"
                label={t('LBLUploadFile')}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  width: 400,
                  background: '#ffffff',
                }}
                inputProps={{ accept: '.pdf, .png, .jpg, .jpeg, .heic' }}
                onChange={handleFileChange}
              ></TextField>
            </Grid>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: 40 }}
            >
              {t('supportSubmit')}
            </Button>
          </Grid>
        </form>
      </div>
      <Suspense fallback={<div />}>
        <Feedback
          open={completed}
          title={t('LBLRequestSent')}
          message={
            <>
              <Typography align="center">
                {t('supportSuccessFeedback')}
              </Typography>
            </>
          }
          handleClose={closeFeedback}
        />
        <FailFeedback
          open={error}
          title={t('mistake')}
          severity="warning"
          message={
            <>
              <Typography align="center">
                {t('LBLSomethingwentWrong')}
              </Typography>
            </>
          }
          handleClose={() => {
            setError(false);
          }}
        />
      </Suspense>
    </React.Fragment>
  );
};

export default Help;
